import {
    Component,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges, OnInit, OnDestroy, ViewChild
} from '@angular/core';
import { PartyModel } from '../../../../../core/models/party/party.model';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators
} from '@angular/forms';
import { NavService } from '../../../../../shared/services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AttributeRelationModel } from '../../../../../core/models/attribute/attribute-relation.model';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { AttributeModel } from '../../../../../core/models/attribute/attribute.model';
import { FapModalComponent } from '../../../../../shared/partials/components/fap-modal/fap-modal.component';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { AttributeEntityEnum } from '../../../../../core/enums/attribute/attribute-entity.enum';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';

@Component({
    selector: 'fap-farm-edit-layout',
    templateUrl: './fap_farm-edit-layout.component.html',
    styleUrls: ['./fap_farm-edit-layout.component.scss']
})
export class FapFarmEditLayoutComponent implements OnChanges, OnInit, OnDestroy {

    @Input()
    public farmId: number = null;

    @Input()
    public isSubmitDisabled = false;

    @Input()
    public farmName = '';

    @Input()
    public farmParty: number = null;

    @Input()
    public isEditMode = false;

    @Input()
    public parties: Array<PartyModel> = [];

    @Output()
    public submitFarm: EventEmitter<{ name: string, party: number }> = new EventEmitter();

    @Output()
    public deleteFarmEvent: EventEmitter<any> = new EventEmitter();

    @ViewChild('addEditAttributeModal')
    public addEditAttributeModal: FapModalComponent;

    public entityRelation:AttributeEntityEnum = AttributeEntityEnum.Farm;
    public farmForm: UntypedFormGroup;
    public selectedAttribute: AttributeModel = null;
    public addRelations = false;
    public attributeRelations: Array<AttributeRelationModel> = [];
    public currentRelation: AttributeRelationModel;
    public createRelations = false;
    public entityRelations = AttributeEntityEnum.Farm;
    public editAttributes = false;
    public entityInfo: any;
    public formType;
    public formFields;
    constructor(public formBuilder: UntypedFormBuilder,
                public activatedRoute: ActivatedRoute,
                public globalRegistry: GlobalRegistryService,
                public attributeService: AttributeService,
                public mapService: MapService,
                public router: Router,
                public navService: NavService, public typesService: TypesService, public cacheService: CacheResolverService, private widgetService: WidgetsService) {
        this.farmForm = this.formBuilder.group({
            farmName: ['', Validators.compose([Validators.required])],
            farmParty: [-1]
        });
    }

    public ngOnInit(): void {
        // const filters: {} = {
        //     relation__model: 'farm',
        // };
        // this.attributeService.attributeRelations.subscribe(() => {
        //     this.attributeService.getAttributeRelations(filters).subscribe((response: ResponseModel<AttributeRelationModel[]>): void => {
        //         this.attributeRelations = response.model;
        //     });
        // });
        // this.attributeService.getAttributeRelations(filters).subscribe((response: ResponseModel<AttributeRelationModel[]>): void => {
        //     this.attributeRelations = response.model;
        // });
        this.navService.editFarm.next(true);
        this.navService.submitFarm.next(false);
        this.navService.emitSubmit.subscribe((value: boolean) => {
            if (value) {
                this.formSubmit();
                this.updateAttributes();
            }
        });

        this.navService.emitDelete.subscribe((value: boolean) => {
            value && this.deleteFarm();
        });

        this.navService.emitCancel.subscribe((value: boolean) => {
            if (value) {
                this.router.navigate(['/pages/lots']);
                this.navService.emitCancel.next(false);
            }
        });
        this.getFormTypes();
        this.widgetService.setSize(6);
    }

    public updateAttributes() {
        if(this.formType && this.formType.id) {
            this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
                console.log(data);
            })
        }
    }

    public deleteFarm() {
        this.deleteFarmEvent.emit(this.farmId);
    }

    public getFormTypes() {
        const url = this.typesService.getUrl('form/');
        this.cacheService.delete(url+'entity=farm');
        const params = {entity: 'farm'}
        this.typesService.getFormTypes(params).subscribe(data => {
          console.log(data);
          this.entityInfo = data.body.results[0];
        })
      }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'farmParty') || Object.prototype.hasOwnProperty.call(changes, 'farmName')) {
            this.farmForm = this.formBuilder.group({
                farmName: [this.farmName, Validators.compose([Validators.required])],
                farmParty: [this.farmParty !== null && this.farmParty !== undefined ? this.farmParty : -1]
            });
        }
        this.checkSubmit();
    }

    public checkName(): void {
        if (this.farmForm.invalid) {
            this.navService.invalidForm.next(false);
        } else {
            this.navService.invalidForm.next(true);
        }
    }

    public checkSubmit(): void {
        if (this.farmForm.invalid || this.isSubmitDisabled) {
            this.navService.submitFarm.next(false);
        } else {
            this.navService.submitFarm.next(true);
        }
    }

    public formSubmit(): void {
        this.submitFarm.emit({
            name: this.farmForm.controls['farmName'].value,
            party: this.farmForm.controls['farmParty'].value === -1 ? null : this.farmForm.controls['farmParty'].value
        });
    }

    public closeRelations($event: boolean): void {
        this.addRelations = $event;
    }

    public ngOnDestroy(): void {
        this.navService.editFarm.next(false);
    }

    public closeModals(): void {
        this.addEditAttributeModal.hideModal();
    }
}
