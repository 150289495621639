<div class="post_card">
    <div class="post_box custom_box" [ngStyle]="{ background: bgColor }">
        <mat-accordion class="sgroups" multi openAll>
            <mat-expansion-panel [expanded]="expanded" hideToggle class="group">
                <mat-expansion-panel-header #panelH (click)="panelH._toggle()">
                    <mat-panel-title (click)="panelH._toggle()">
            <div class="box">
            <div class="post_left">
                <div class="top_row">
                    <div class="title">
                        <h5>{{ post?.name_t ? getTranslation(post?.name_t) : post?.name }}</h5>
                        <p class="date">{{ post?.timestamp | date: "MMM dd yyyy, HH:mm"}}</p>
                    </div>
                    <span class="labl">{{ getTranslation(post?.label) }}</span>
                </div>
                <div class="bottom_row">
                    <div class="date_row clearfix">
                        <p><b>
                            <ng-container *ngFor="let plan of globalRegistry?.systemData?.plans">
                                <span *ngIf="plan?.id === post?.plan">{{ plan?.nameT ? (plan?.nameT | getTranslation : globalRegistry?.systemData?.translationsMap : langString) : plan?.name }}</span>
                            </ng-container>
                            <!-- <ng-container *ngIf="post?.crop">
                                <ng-container *ngFor="let type of crops">
                                    <span *ngIf="type?.id === post?.crop">
                                            <span *ngIf="type?.cropType?.nameT">
                                                <span *ngIf="getTranslation(type?.cropType?.nameT) === '' || getTranslation(type?.cropType?.nameT) == null || getTranslation(type?.cropType?.nameT) == undefined ">{{type?.cropType?.name}}</span>
                                                <span *ngIf="getTranslation(type?.cropType?.nameT) !== ''">{{getTranslation(type?.cropType?.nameT)}}</span>
                                            </span>
                                            <span *ngIf="!type?.cropType?.nameT">{{type?.cropType?.name}}</span></span>
                                    <ng-container *ngIf="type?.id !== post?.crop">
                                        <ng-container *ngFor="let childUnit of type?.children">
                                            <span *ngIf="childUnit?.id === post?.crop">
                                                <span *ngIf="childUnit?.cropType?.nameT">
                                                    <span *ngIf="getTranslation(childUnit?.cropType?.nameT) === '' || getTranslation(childUnit?.cropType?.nameT) == null || getTranslation(childUnit?.cropType?.nameT) == undefined ">{{childUnit?.cropType?.name}}</span>
                                                    <span *ngIf="getTranslation(childUnit?.cropType?.nameT) !== ''">{{getTranslation(childUnit?.cropType?.nameT)}}</span>
                                                </span>
                                                <span *ngIf="!childUnit?.cropType?.nameT">{{childUnit?.cropType?.name}}</span>
                                                </span> 
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container> -->
                        </b>
                            <!-- <ng-container *ngIf="!post?.crop"> -->
                            <span *ngIf="post?.lots.length">
                                {{ [globalRegistry.systemData.lots, post?.lots] | getLotNames }}
                            </span>
                            <!-- </ng-container> -->
                        </p>
                    </div>
                    <div class="info_row clearfix">
                        <div class="type">
                            <b style="text-transform: uppercase;"><span>
                                <ng-container *ngFor="let type of genericTypes">
                                    <span *ngIf="type?.id === post?.type" [ngStyle]="{ background: type?.color }" style="width: 15px; height:15px; display: inline-block; margin-right: 6px; vertical-align: text-bottom;"></span>
                                    <ng-container *ngIf="type?.id !== post?.type">
                                        <ng-container *ngFor="let childUnit of type?.children">
                                            <span *ngIf="childUnit?.id === post?.type" [ngStyle]="{ background: type?.color }" style="width: 15px; height:15px; display: inline-block; margin-right: 6px; vertical-align: text-bottom;"></span> 
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </span> {{ post?.post_type }}</b>
                        </div>
                        <div class="g_type">
                            <ng-container *ngFor="let type of genericTypes">
                                <span *ngIf="type?.id === post?.type">
                                    <span *ngIf="type?.nameT">
                                        <span *ngIf="getTranslation(type?.nameT) === '' || getTranslation(type?.nameT) == null || getTranslation(type?.nameT) == undefined ">{{type?.name}}</span>
                                        <span *ngIf="getTranslation(type?.nameT) !== ''">{{getTranslation(type?.nameT)}}</span>
                                    </span>
                                    <span *ngIf="!type?.nameT">{{type?.name}}</span>
                                </span>
                                <ng-container *ngIf="type?.id !== post?.type">
                                    <ng-container *ngFor="let childUnit of type?.children">
                                        <span *ngIf="childUnit?.id === post?.type">
                                            <span *ngIf="getTranslation(childUnit?.nameT) === '' || getTranslation(childUnit?.nameT) == null || getTranslation(childUnit?.nameT) == undefined ">{{childUnit?.name}}</span>
                                            <span *ngIf="getTranslation(childUnit?.nameT) !== ''">{{getTranslation(childUnit?.nameT)}}</span>
                                            <span *ngIf="!childUnit?.nameT">{{childUnit?.name}}</span>
                                        </span>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="post_right">
                <div class="img">
                    <ng-container *ngFor="let person of persons ">
                        <div class="user_im img_place_bg" *ngIf="person?.id === post?.created_by">
                            <img
                                src="../../../../../../assets/images/placeholder.gif"
                                alt="assigned to"
                                *ngIf="person?.picture == null"
                                class="ph"
                            />
                            <img
                                [src]="mediaUrl + person?.picture"
                                alt="assigned to"
                                *ngIf="person?.picture != null"
                            />
                        </div>
                    </ng-container>
                    <div class="user_im img_place_bg" *ngIf="post?.created_by == null">
                        <img class="ph" src="../../../../../../assets/images/placeholder.gif" alt="">
                    </div>
                </div>
                <div class="delt_btn">
                    <span (click)="deletePost.emit(post?.id)">
                        <i class="fa fa-trash align-self-center ml-2 f-20"></i>
                    </span>
                </div>
            </div>
        </div>
    </mat-panel-title>
</mat-expansion-panel-header>
    <mat-panel-description class="info_h">
        <div class="info">
            <p [innerText]="post?.info_t ? getTranslation(post?.info_t) : post?.info"></p>
        </div>
        <div class="images d-flex" *ngIf="post?.images.length">
            <div *ngFor="let imageId of post?.images">
                <div>
                    <ng-container *ngFor="let image of images">
                            <ng-container *ngIf="image.id == imageId">
                                <!-- <img *ngIf="image" [src]="mediaUrl + image?.file" alt=""> -->
                                <fap-image [src]="mediaUrl + image?.file" [containerHeight]="100" [isDeleteable]="false"></fap-image>
                            </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </mat-panel-description>
    </mat-expansion-panel>
    </mat-accordion>
    </div>
</div>
