import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';

@Component({
  selector: 'fap-table-column',
  templateUrl: './fap-table-column.component.html',
  styleUrls: ['./fap-table-column.component.scss']
})
export class FapTableColumnComponent implements OnChanges {

  @Input() public type: string = 'entities';
  @Input() public data: any;
  @Input() public showHeader: boolean = true;
  @Input() public entityTypesMap: any;
  @Input() public allEntityColumnsMap: any;
  @Input() public langString: string = 'en';

  @Output() public selectItem: EventEmitter<any> = new EventEmitter();

  public fields:any[] = [];

  constructor(public globalRegistry: GlobalRegistryService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('data') && this.data) {
      this.fields = this.data.fields.sort((a, b) => a.column - b.column);
    }
  }

  getFieldValue(field, index) {
    if(field.column === index && this.entityTypesMap) {
      return this.getFieldData(field);
    }
  }

  getFieldData(field) {
    let fieldType = this.allEntityColumnsMap.get(field?.type);
    let value = field.value.input;
    return this.handleValueAndInputType(fieldType, value);
  }

  handleValueAndInputType(fieldType, value) {
    if(!fieldType) {
      return
    }
    if(fieldType.value_type === 'string') {
      if(fieldType.input_type === 'single') {
        return value ? this.globalRegistry.systemData.translationsMap.get(value)?.[this.langString] : '-'
      } else {
        let values: any[] = [];
        value.forEach((val: any) => {
          values.push(val ? this.globalRegistry.systemData.translationsMap.get(val)?.[this.langString] : '-')
        });
        return values.toString();
      }
    }

    if(fieldType.value_type === 'number') {
      if(fieldType.input_type === 'single') {
        return value
      }
    }
  }

}
