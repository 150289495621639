import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { StockService } from '../../../../core/services/api/stock/stock.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { DocumentModel } from '../../../../core/models/stock/document.model';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { StateService } from '../../../services/state.service';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { Router } from '@angular/router';

export const DATE_TIME_FORMAT = {
  parse: {
    dateInput: 'l, LTS',
  },
  display: {
    dateInput: 'MMM DD YYYY',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
}

@Component({
  selector: 'documents-modal',
  templateUrl: './documents-modal.component.html',
  styleUrls: ['./documents-modal.component.scss'],
  providers: [{provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT}, {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DocumentsModalComponent),
    multi: true
  }]
})
export class DocumentsModalComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public show: boolean = false;
  @Input() isSelectable: boolean = true;

  @Output() selectedDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  public docSearchForm: FormGroup;
  public nextToken: { limit: number, offset: number; search?: string, type?: string; from?: any, to?:any, farm?: number } = null;
  public subscriptions: Array<Subscription> = [];
  public docs: Array<DocumentModel> = [];
  public limit = 20;
  public getMore = true;
  public status = false;
  public minDate;
  public maxDate;
  public pdfDoc;
  public excelDoc;
  

  public documentTypes = [
    {key: 'invoice_in', value: 'Invoice in'},
    {key: 'invoice_out', value: 'Invoice out'},
    {key: 'internal_note', value: 'Internal note'},
    {key: 'production_note', value: 'Production note'},
    {key: 'estimation_note', value: 'Estimation note'},
  ];

  @ViewChild('documentTable') documentTable: ElementRef;

  constructor(private formBuilder: FormBuilder, private stockService: StockService, public cacheService: CacheResolverService, public globalRegistry: GlobalRegistryService, private stateService: StateService, private router: Router) {}

  ngOnInit(): void {
    this.initDocSearchForm();
  }

  public clearDocInputs() {
    this.docSearchForm.reset();
    this.docSearchForm.clearValidators();
    this.docSearchForm.markAsUntouched();
    this.initDocSearchForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('show') && this.show === true) {
      this.filterDocs();
    }
  }

  public initDocSearchForm() {
    const oneYearFromNow = new Date();
      oneYearFromNow.setMonth(oneYearFromNow.getMonth() - 12);
    const formBody = {
      search: [''],
      type: [''],
      from: [oneYearFromNow, Validators.required],
      to: [new Date(), Validators.required],
      farm: [null]
    };
      this.docSearchForm = this.formBuilder.group(formBody);
  }

  public ifControlHasError(controlName: string, validationType: string): boolean {
    const control: any = this.docSearchForm.controls[controlName];
    if (!control) {
        return false;
    }

    const result: boolean =
        control.hasError(validationType) &&
        (control.dirty || control.touched);
    return result;
  }

  public filterDocs() {
    const url = this.stockService.getUrl('docs/');
    const params = {
      search: this.docSearchForm.get('search').value ? this.docSearchForm.get('search').value : '',
      from: moment.utc(this.docSearchForm.get('from').value).format('YYYY-MM-DD'),
      to: moment.utc(this.docSearchForm.get('to').value).format('YYYY-MM-DD')
    }
    if(this.docSearchForm.get('type').value) { Object.assign(params, {type : this.docSearchForm.get('type').value}) }
    if(this.docSearchForm.get('farm').value) { Object.assign(params, {farm : this.docSearchForm.get('farm').value}) }
    this.subscriptions.push(
    this.stockService.getDocs(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search+'&from='+params.from+'&to='+params.to);
        this.docs = data.model;
        this.getDocScrollPosition();
        this.nextToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.nextToken) this.getMore = true;
      }))
  }

  scrolledDown() {
    const url =  this.stockService.getUrl('docs/');
    console.log('scrolled');
    if(this.getMore) {
    this.nextToken &&
      this.stockService.getDocs(this.nextToken).subscribe((data) => {
        this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset);
        console.log(data);
        if(this.docs) {
          this.docs = [...this.docs, ...data.model];
        } else {
          this.docs = data.model;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset']};
          if(params['search']) this.nextToken.search = params['search'];
          if(params['from']) this.nextToken.from = params['from'];
          if(params['to']) this.nextToken.to = params['to'];
          if(params['type']) this.nextToken.type = params['type'];
          if(params['farm']) this.nextToken.type = params['farm'];
          } else {
            return
          }
        }
      });
    } else {
      return
    }
    this.getDocScrollPosition();
  }

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

  public goToBill(billId, billType) {
    this.closeModal.emit();
    this.router.navigate(['/pages/stock/bill/'+billId], {queryParams: {type: billType, new_doc: 'false'}});
  }

  onDivScroll(div, event: Event) {
    const divElement = event.target as HTMLElement;
    const scrollTop = divElement.scrollTop;
    this.stateService.scrollPositions[div] = scrollTop;
  }

  public toggleDocFilter() {
    this.status = !this.status;
  }

  getDocScrollPosition() {
    setTimeout(() => {
      this.documentTable.nativeElement.scrollTop = this.stateService.scrollPositions['documentTable'] || 0;
      const divHeight = this.documentTable.nativeElement.clientHeight;
      console.log(divHeight);
      console.log(this.stateService.scrollPositions['documentTable']);
      console.log(this.documentTable.nativeElement.scrollTop);
      // if(this.stateService.scrollPositions['documentTable'] && divHeight > this.stateService.scrollPositions['documentTable']) {
      //   this.scrolledDown();
      // }
    }, 1000)
  }

  public minValueChanged(ev) {
    this.minDate = ev.target.value;
  }

  public maxValueChanged(ev) {
      this.maxDate = ev.target.value;
  }

  ngOnDestroy(): void {
    
  }

}
