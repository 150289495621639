<fap-header>
    <div class="d-flex align-items-center">
        <div class="tree_combo mr-3">
            <fap-tree-combo></fap-tree-combo>
          </div>
        <div class="add_btn mr-3">
            <fap-round-button [backgroundColor]="'tertiary'" (clicked)="showModal()"></fap-round-button>
        </div>
    </div>
</fap-header>
<div class="cover" id="parent90">
    <div class="reports_table">
        <table>
            <thead>
                <tr>
                    <th style="width: 40px;">
                        <!-- <input type="checkbox" name="checkAll" (change)="CheckAllOptions()"><span class="checkbx"></span> -->
                    </th>
                    <th>Date</th>
                    
                    <th>Type</th>
                    <th>Farm & Lot</th>
                    <th>Period</th>
                    <th>Requested By</th>
                    <th style="width: 35px;"><span class="dicon">
                        <!-- <img src="../../../../../../assets/images/item.png" alt="item"> -->
                    </span></th>
                    <th style="width: 50px; padding-right: 15px;">
                        <!-- <span style="display: block; z-index: 2; position: relative; cursor: pointer;"
                            ><i
                                class="fa fa-trash align-self-center ml-2 f-20"
                                (click)="deleteMarkedRecords()"
                            ></i
                        ></span> -->
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let report of reports">
                    <td><input type="checkbox" name="check" [(ngModel)]="report.checked" [value]="report.id" (change)="selectEntry($event, report)"><span class="checkbx"></span></td>
                    <td>{{report?.created_at | date: "MMM dd yyyy, HH:mm"}}</td>
                    
                    <td>
                        <ng-container *ngFor="let type of templates">
                            <span *ngIf="type.id === report?.type">{{type.name}}</span>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let farm of globalRegistry?.systemData?.farms">
                            <span style="display: inline-block;" *ngIf="farm.id === report?.farm">{{farm.name}}</span>
                        </ng-container>
                        <ng-container *ngFor="let lot of globalRegistry?.systemData?.lots">
                            <span style="display: inline-block; padding-left: 3px;" *ngIf="lot.id === report?.lot">{{' / ' + lot.name}}</span>
                        </ng-container>
                    </td>
                    <td>
                        <span>{{report?.start | date: "MMM dd yyyy"}} - {{report?.end | date: "MMM dd yyyy"}}</span>
                    </td>
                    <td>
                        <ng-container *ngFor="let person of globalRegistry?.systemData?.persons">
                            <span *ngIf="person?.id === report?.created_by">{{person?.display}}</span>
                        </ng-container>
                    </td>
                    <td>
                        <span class="dicon" *ngIf="report.file != null">
                            <a [href]="mediaUrl+report.file" download style="display: block;">
                                <img src="../../../../../../assets/images/item.png" alt="item">
                            </a>
                        </span></td>
                    <td>
                        <span style="display: block; z-index: 2; position: relative; cursor: pointer;"
                            ><i
                                class="fa fa-trash align-self-center ml-2 f-20"
                                (click)="deleteReport(report?.id)"
                            ></i
                        ></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<fap-modal #requestReportModal [showFooter]="false"
inputTitle="Request Report">
    <div>
        <div class="add_report">
                <form [formGroup]="reportForm" class="row"> 

                        <div class="form-group w-100 d-flex mb-0 flex-wrap">
                            <div class="col-12">
                                <div class="input-group w-100">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{ 'stock.type' | translate }}</mat-label>
                                        <mat-select formControlName="type" required>
                                            <mat-form-field appearance="fill" class="w-100">
                                                <input matInput #personFilter placeholder="Search">
                                            </mat-form-field>
                                            <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                            <mat-option *ngFor="let type of templates | filter : personFilter.value" [value]="type.id">
                                                {{type?.name}}
                                            </mat-option>
                                            </div>
                                        </mat-select>
                                        <mat-error *ngIf="ifControlHasError('type')">
                                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                            </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-6">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100">
                                    <mat-label class="col-form-label">
                                        {{ 'stock.farm' | translate }}</mat-label>
                                    <mat-select required formControlName="farm" (selectionChange)="filterLots($event)">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #farmFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let farm of globalRegistry.systemData.farms | filter : farmFilter.value" [value]="farm.id">
                                            {{ farm.name }}
                                        </mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="col-6">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100">
                                    <mat-label class="col-form-label">
                                        {{ 'notes.lot' | translate }}</mat-label>
                                    <mat-select required formControlName="lot">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #lotFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let lot of filteredLots | filter : lotFilter.value" [value]="lot.id">
                                            {{ lot?.name }}
                                        </mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="col-6">
                                <mat-form-field (click)="fromPicker.open()" class="w-100">
                                    <mat-label class="col-form-label">From</mat-label>
                                    <input matInput readonly [matDatepicker]="fromPicker" placeholder="From" [disabled]="false" formControlName="start"/>
                                    <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #fromPicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field (click)="untilPicker.open()" class="w-100">
                                    <mat-label class="col-form-label">To</mat-label>
                                    <input matInput readonly [matDatepicker]="untilPicker" placeholder="To" [disabled]="false" formControlName="end"/>
                                    <mat-datepicker-toggle matSuffix [for]="untilPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #untilPicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-12 mt-3 btns d-flex justify-content-end">
                                <button class="btn mr-2"(click)="addReport()">{{ 'translations.save' | translate }}</button>
                                <button class="btn" (click)="requestReportModal.hideModal()">{{ 'translations.cancel' | translate }}</button>
                            </div>
                        </div>
                    
                </form>
            </div>
      </div>
</fap-modal>
