import { Component, Input } from '@angular/core';

@Component({
  selector: 'fap-table-header',
  templateUrl: './fap-table-header.component.html',
  styleUrls: ['./fap-table-header.component.scss']
})
export class FapTableHeaderComponent {

  @Input() public columns:any[] = [];
  @Input() public itemList:any[] = [];
  @Input() public tableHeaderLabels:any[] = [];
  @Input() public columnWidths: number[] = [];
  @Input() public virtualItemList:any[] = [];

  constructor() {}

  onResize(width, columnId: string) {
    this.columnWidths[this.columns.findIndex(col => col.id === columnId)] = width;
  }

  onResizeEnd(columnId: string) {
    const width = this.columnWidths[this.columns.findIndex(col => col.id === columnId)];
    console.log(`Resizing of column ${columnId} ended with final width: ${width}px`);

    console.log(this.columns);
    console.log(this.itemList);
    this.itemList.forEach(item => {
      if(item.sensor_ref === columnId) {
        Object.assign(item, {ui_colsize: `${width}px`})
      }
    });
    // Output to parent
  }

  changeUiSort(header) {
    if(!header) return;
    header.ui_sort = (header.ui_sort + 1) % 3;
    console.log(header.ui_sort);
    if (header.ui_sortorder === 0) {
        header.ui_sortorder = 1;
        this.itemList.forEach(element => {
            if (element.ui_sortorder > 0) {
                element.ui_sortorder += 1;
            }
        });
    }
    this.itemList.forEach(element => {
        if (element.sensor_ref === header.sensor_ref.id) {
            Object.assign(element, { ui_sort: header.ui_sort, ui_sortorder: header.ui_sortorder });
        }
    });
    // Output to parent
  }

  cancelOrder(header) {
    if(!header) return;
    const currentItem = this.itemList.find(item => item.sensor_ref === header.sensor_ref.id);
    if (!currentItem) return;
    const currentSortOrder = currentItem.ui_sortorder;
    if (currentSortOrder > 0) {
        currentItem.ui_sortorder = 0;
        this.itemList.forEach(item => {
            if (item.ui_sortorder > currentSortOrder) {
                item.ui_sortorder -= 1;
            }
        });
    }
    // Output to parent
}

  hasMultipleSortOrders(): boolean {
    return this.columns.filter(item => item.ui_sortorder > 0).length >= 2;
  }

}
