<ng-container *ngIf="tableHeaderLabels.length > 0">
    <th *ngFor="let head of tableHeaderLabels" [innerHTML]="head.name"></th>
</ng-container>
<ng-container *ngIf="columns.length > 0"></ng-container>
<th
  *ngFor="let head of columns; let i = index"
  resizable
  (widthChange)="onResize($event, head?.sensor_ref?.id)"
  (resizeEnd)="onResizeEnd(head?.sensor_ref?.id)"
  (click)="changeUiSort(head)"
>
  <div class="th_container" [ngStyle]="{ width: head?.ui_colsize }">
    <div class="name_part">
      {{ head?.field_type ? head?.field_type?.name : "" }}
    </div>
    <div class="absolut_part">
      <div class="order">
        <ng-container *ngIf="head?.ui_sortorder > 0 && hasMultipleSortOrders()">
          <span class="order_number">
            <div class="custom_entry">{{ head?.ui_sortorder }}</div>
            <div
              class="custom_entry hov"
              (click)="cancelOrder(head); $event.stopPropagation()"
            >
              <span class="small_icon">
                <i class="fa fa-times"></i>
              </span>
            </div>
          </span>
        </ng-container>
      </div>
      <div class="priority">
        <ng-container *ngIf="head?.ui_sort === 1">
          <span class="small_icon">
            <div class="custom_entry">
              <i class="fa fa-caret-up"></i>
            </div>
          </span>
        </ng-container>

        <ng-container *ngIf="head.ui_sort === 2">
          <span class="small_icon">
            <div class="custom_entry">
              <i class="fa fa-caret-down"></i>
            </div>
          </span>
        </ng-container>
      </div>
    </div>
  </div>
</th>
