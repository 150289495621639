import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { NavService } from '../../../../shared/services/nav.service';
import { Subscription } from 'rxjs';
import { ActivityTypeModel } from '../../../../core/models/activity/activity-type.model';

@Component({
  selector: 'fap-plan-item',
  templateUrl: './plan-item.component.html',
  styleUrls: ['./plan-item.component.scss']
})
export class PlanItemComponent implements OnInit, OnDestroy {
  @Input() public activity: any = null;
  @Input() public activityTypes: Array<ActivityTypeModel> = [];
  @Input() public planData;
  @Output() public getChildActivityPlan: EventEmitter<any> = new EventEmitter();
  @Output() public selectActivity: EventEmitter<any> = new EventEmitter();
  public activityRows = [];
  public dateColumns = [];
  public columnWidth = 60;

  public langString
  public subscriptions: Array<Subscription> = [];

  constructor(public globalRegistry: GlobalRegistryService, private navService: NavService) {}

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
    }));
  }

  getTranslation(translation) {
    const t =this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  getActivityPlan() {
    if(this.planData.period.start) {
      this.generateDateColumns(this.planData.period.start, this.planData.period.end);
    }
  }

  getActivityTypeColor(activityTypeId: number) {
    let color = '#BA6969';
    this.activityTypes.forEach(type => {
      if(type.id === activityTypeId) {
        color = type.color;
      }
    });
    return color;
  }

  getActivityOverlayWidth(start: number, end: number): string {
    const totalDays = end - start + 1; // Assuming start and end are indices
    return (totalDays * this.columnWidth) + 'px';
  }
  
  getActivityOverlayPosition(start: number): string {
    const leftPosition = start * this.columnWidth;
    return (leftPosition) + 'px';
  }

  generateDateColumns(startDate: string, endDate: string): void {
    this.dateColumns = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
      this.dateColumns.push(new Date(d));
    }
  }

  ngOnDestroy(): void {
    
  }

}
