<div class="classical_forms row d-flex dark_bg m-0" [ngClass]="{
    'workflow_form': formDisplayType === 'workflow_modal'
}">
    <div class="form_img p-0 pc-view" *ngIf="entityInfo?.form_css?.[size]?.banner?.image?.file && !isLoading
    " [ngClass]="'col-' + (size === 'pc' ? entityInfo?.form_css?.pc?.banner?.cols : size === 'tablet' ? entityInfo?.form_css?.tablet?.banner?.cols : entityInfo?.form_css?.mobile?.banner?.cols) + ' row-' + (size === 'pc' ? entityInfo?.form_css?.pc?.banner?.rows : size === 'tablet' ? entityInfo?.form_css?.tablet?.banner?.rows : entityInfo?.form_css?.mobile?.banner?.rows)">
        <img [src]="mediaUrl + entityInfo?.form_css?.[size]?.banner?.image?.file" alt="">
    </div>
    
    <div class="fixed_section form_img p-0 mobile-view" [ngClass]="'col-' + (size === 'pc' ? entityInfo?.form_css?.pc?.banner?.cols : size === 'tablet' ? entityInfo?.form_css?.tablet?.banner?.cols : entityInfo?.form_css?.mobile?.banner?.cols) + ' row-' + (size === 'pc' ? entityInfo?.form_css?.pc?.banner?.rows : size === 'tablet' ? entityInfo?.form_css?.tablet?.banner?.rows : entityInfo?.form_css?.mobile?.banner?.rows)">
        <img [src]="mediaUrl + entityInfo?.form_css?.[size]?.banner?.image?.file" alt="" *ngIf="entityInfo?.form_css?.[size]?.banner?.image?.file">
        <h3 class="title mobile-view" *ngIf="typeOfForm === 'workflow' && entityInfo?.name_t?.id">{{ entityInfo?.name_t[langString] ? entityInfo?.name_t[langString] : entityInfo?.name }}</h3>
        <h3 class="title mobile-view" *ngIf="typeOfForm === 'workflow' && !entityInfo?.name_t">{{ entityInfo?.name }}</h3>
    </div>
    
    <div class="form_area" [ngClass]="{
        'workflow_modal': formDisplayType === 'workflow_modal'
     }">
        <h3 class="title pc-view" *ngIf="typeOfForm === 'workflow' && entityInfo?.name_t?.id">{{ entityInfo?.name_t[langString] ? entityInfo?.name_t[langString] : entityInfo?.name }}</h3>
        <h3 class="title pc-view" *ngIf="typeOfForm === 'workflow' && !entityInfo?.name_t">{{ entityInfo?.name }}</h3>
        <span *ngIf="isLoading" class="loader"><i></i></span>
        <div class="flex-wrap d-flex w-100 overflow-x-hidden" *ngIf="!isLoading">
            <div class="col-12 e_info" *ngIf="formType?.info" [innerHTML]="sanitizeHTML(formType?.info?.[langString])"></div>
            <div class="col-12 e_info" *ngIf="!formType?.info && entityInfo?.info?.id" [innerHTML]="sanitizeHTML(entityInfo?.info?.[langString])"></div>
            <ng-container *ngFor="let field of mixedFields">
                <!-- Check if field is a group -->
                <ng-container *ngIf="field?.nestedFields; else displayField">
                    <div class="group-container p-0" [ngClass]="'col-' + (size === 'pc' ? field?.pc?.cols : size === 'tab' ? field?.tablet?.cols : field?.mobile?.cols) + ' row-' + (size === 'pc' ? field?.pc?.rows : size === 'tab' ? field?.tablet?.rows : field?.mobile?.rows)"
                    [ngStyle]="{'order': field?.[size]?.pos}"
                    >
                        <!-- Iterate through nestedFields of the group -->
                        <ng-container *ngFor="let nestedField of field.nestedFields">
                            <ng-container *ngFor="let attr of formType?.fields">
                                <ng-container *ngIf="attr?.type === nestedField?.field?.id">
                                    <div *ngIf="nestedField?.field?.value_type==='entity'"
                                    [ngStyle]="{'order': size ? nestedField?.[size]?.pos : nestedField?.[size]?.pos}"
                                    [ngClass]="'col-' + getCols(nestedField, size) + ' row-' + getRows(nestedField, size)"
                                    [ngStyle]="{'order': getOrder(nestedField, size)}"
                                    class="pos_rel"
                                    >
                                        <classical-form-field 
                                        style="height: 100%"
                                            [screenSize]="size"
                                            [height]="nestedField"
                                            [field]="nestedField" 
                                            [selectedAttr]="attr" 
                                            (editValue)="editValue(attr.id, $event)" 
                                            [resetForm]="resetForm"
                                            [errorFields]="errorFields" 
                                            [entityInfo]="entityInfo">
                                        </classical-form-field>
                                        <ng-container *ngIf="attr?.error?.message">
                                            <div class="custom-error">{{attr?.error?.message?.[langString]}}</div>
                                        </ng-container>
                                    </div>
                                    <div *ngIf="nestedField?.field?.value_type!='entity'"
                                    [ngClass]="'col-' + getCols(nestedField, size) + ' row-' + getRows(nestedField, size) + ' mb-' + (nestedField?.field?.value_type === 'image' ? 2 : 0)"
                                    [ngStyle]="{'order': getOrder(nestedField, size)}"
                                    class="ov_hide pos_rel"
                                    >
                                        <classical-form-field 
                                        style="height: 100%"
                                            [screenSize]="size"
                                            [height]="nestedField"
                                            [field]="nestedField" 
                                            [selectedAttr]="attr" 
                                            (editValue)="editValue(attr.id, $event)" 
                                            [resetForm]="resetForm"
                                            [errorFields]="errorFields" 
                                            [entityInfo]="entityInfo">
                                        </classical-form-field>
                                        <ng-container *ngIf="attr?.error?.message">
                                            <div class="custom-error">{{attr?.error?.message?.[langString]}}</div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <!-- <ng-container *ngIf="formType?.fields.length === 0">
                                <div class="center_empty_text">{{ 'forms.emptyFields' | translate }}</div>
                            </ng-container> -->
                        </ng-container>
                    </div>
                </ng-container>
                <!-- Display normal fields -->
                <ng-template #displayField>
                    <!-- {{formType | json}} -->
                    <ng-container *ngFor="let attr of formType?.fields">
                        <ng-container *ngIf="attr?.type === field?.field?.id">
                            <div *ngIf="field?.field?.value_type==='entity'"
                            [ngClass]="'col-' + getCols(field, size) + ' row-' + getRows(field, size)"
                            [ngStyle]="{'order': getOrder(field, size)}"
                            class="pos_rel"
                            >
                                <classical-form-field 
                                style="height: 100%"
                                    [size]="size"
                                    [height]="field"
                                    [field]="field" 
                                    [selectedAttr]="attr" 
                                    (editValue)="editValue(attr.id, $event)" 
                                    [resetForm]="resetForm" 
                                    [errorFields]="errorFields"
                                    [entityInfo]="entityInfo">
                                </classical-form-field>
                                <ng-container *ngIf="attr?.error?.message">
                                    <div class="custom-error">{{attr?.error?.message?.[langString]}}</div>
                                </ng-container>
                            </div>
                            <div *ngIf="field?.field?.value_type!='entity'" class="ov_hide"
                            [ngClass]="'col-' + getCols(field, size) + ' row-' + getRows(field, size) + ' mb-' + (field?.field?.value_type === 'image' ? 2 : 0)"
                            [ngStyle]="{'order': getOrder(field, size)}"
                            class="pos_rel"
                            >
                                <classical-form-field 
                                style="height: 100%"
                                    [size]="size"
                                    [height]="field"
                                    [field]="field" 
                                    [selectedAttr]="attr" 
                                    (editValue)="editValue(attr.id, $event)" 
                                    [resetForm]="resetForm" 
                                    [errorFields]="errorFields"
                                    [entityInfo]="entityInfo">
                                </classical-form-field>
                                <ng-container *ngIf="attr?.error?.message">
                                    <div class="custom-error">{{attr?.error?.message?.[langString]}}</div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                    <!-- <ng-container *ngIf="formType?.fields.length === 0">
                        <div class="center_empty_text">{{ 'forms.emptyFields' | translate }}</div>
                    </ng-container> -->
                </ng-template>
            </ng-container>
            
        </div>
        <ng-container *ngIf="!btnsDisabled">
        <div class="btns" *ngIf="formDisplayType === 'modal'">
            <button class="btn" (click)="closeModal.emit()">{{ 'cancel' | translate }}</button>
            <button class="btn"(click)="updateFields()">{{ 'ok' | translate }}</button>
        </div>
        <div class="modal_btns" *ngIf="formDisplayType === 'workflow_modal'">
            <div class="progress_bar">
                <div class="page_count" *ngIf="workflow?.progress <= 100">
                    <mat-progress-bar mode="determinate" [value]="workflow?.progress"></mat-progress-bar>
                </div>
            </div>
            <button class="btn prev mr-0" *ngIf="workflow?.progress <= 100 && workflow?.progress > 0" (click)="prevButtonClick.emit(); checkPrevButtonClick()">
                <!-- {{ 'prev' | translate }} -->
                <i class="fa fa-chevron-left"></i>
            </button>
            <button class="btn next" (click)="updateFields()" *ngIf="workflow?.progress < 100">
                <!-- {{ 'next' | translate }} -->
                <i class="fa fa-chevron-right"></i>
            </button>
            <button class="btn next" *ngIf="workflow?.progress === 100" (click)="updateFields(); hideModal.emit()">
                <!-- {{ 'complete' | translate }} -->
                <i class="fa fa-check"></i>
            </button>
        </div>
    </ng-container>
    </div>

</div>