<div class="d-flex d-md-flex persn">
  <div class="card person-card horizontal">
  <div class="card-image valign-wrapper user_im img_place_bg" *ngIf="person?.picture"  [routerLink]="['edit/', person.id]">
    <img 
      [src]="mediaUrl + person.picture"
      [alt]="'creator'"
    />
  </div>
  <div class="card-image valign-wrapper user_im img_place_bg ph" *ngIf="!person?.picture"  [routerLink]="['edit/', person.id]">
    <img
      [src]="defaultImg"
      [alt]="'creator'"
    />
  </div>
  <div class="card-stacked">
  <div class="card-content"  [routerLink]="['edit/', person.id]">
    <div class="card-title">
      <h4>
        {{person?.display}}
    </h4>
      <p style="font-style: italic;"> {{ person?.job }}</p>
      <h6 class="mb-0 text-capitalize">{{ person?.party?.name }}</h6>
    </div>
    
    <!-- <p class="m-0"><b>Address:</b> {{ person?.address || "-" }}</p> -->

    <div class="card-middle">
        <p class="farm_names">
          <ng-container *ngIf="person?.activeFarms && person?.activeFarms.length">
            {{ [globalRegistryService.systemData.farms, person.activeFarms] | getLotNames }}
            <ng-container *ngIf="person?.activeFarms.length === 0"><i>No active farms</i></ng-container>
          </ng-container>
        </p>
        <div class="roles">
            <div *ngIf="person?.role == 1000" class="card-counter">
                <p style="color: #808080" class="text-truncate">Company Admin</p>
              </div>
              <div *ngIf="person?.role == 100" class="card-counter">
                <p style="color: #808080" class="text-truncate">Farm Admin</p>
              </div>
              <div *ngIf="person?.role == 10" class="card-counter">
                <p style="color: #808080" class="text-truncate">Worker</p>
              </div>
        </div>
        <p class="email">{{person?.user?.email}}
            <i *ngIf="!person?.user?.email">No email</i>
        </p>
        <!-- <p class="m-0">{{person?.user?.phone}}
            <i *ngIf="!person?.user?.phone">No Phone</i>
        </p> -->
    </div>

      <div class="card-tail">
        <p class="m-0">{{ [globalRegistryService.systemData.peopleGroups, person?.groups] | getLotNames }}</p>
        <p *ngIf="person?.groups.length === 0"><i class="no_value">No group</i></p>
      </div>
  </div>

  <div class="card-actions">
    <span (click)="showMsgModal(person?.id)">
      <img src="../../../../../assets/images/msg_btn.svg" alt="message">
    </span>
  </div>
    <!-- <div class="card-content card-counters flex-grow-0">
        <div class="card-counter">
          <div class="counter red">{{ activity_count }}</div>Activities
        </div>
        <div *ngIf="person?.role == 1000 || person?.role == 100" class="card-counter">
          <i class="material-icons">person</i>Admin
        </div>
      </div> -->
</div>
</div>
</div>

<fap-modal
        #sendMsgModal
        [inputPrimaryButtonInterface]="
        sendMsgModalButtonPrimaryInterface
        "
        [inputSecondaryButtonInterface]="
        sendMsgModalButtonSecondaryInterface
        "
        inputTitle="Send message"
    >
        <form [formGroup]="messageForm" class="col-12 p-0">
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ "notes.type_name" | translate }}</mat-label>
                    <input matInput formControlName="name" />
                    <mat-error *ngIf=" messageForm.controls['name'].errors?.required">
                        <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="w-100">
                <mat-label>{{ 'auth.company.plan' | translate }}</mat-label>
                <mat-select formControlName="plan" name="plan">
                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                    <mat-form-field appearance="fill" class="w-100">
                        <input matInput #planFilter placeholder="Search">
                    </mat-form-field>
                    <!-- <div class="input-group-append pl-1" (click)="showCropModal()">
                        <span class="input-group-text"><i
                                class="icofont icofont icofont-plus"></i></span>
                    </div> -->
                </div>
                <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option class="noteIcons" *ngFor="let plan of globalRegistryService.systemData.plans | filter : planFilter.value" [value]="crop.id">
                        <div class="name">
                            <!-- <span *ngIf="crop?.product">
                                <span *ngIf="getTranslation(crop?.cropType?.nameT) === '' || getTranslation(crop?.cropType?.nameT) == null || getTranslation(crop?.cropType?.nameT) == undefined ">{{crop?.cropType?.name}}</span>
                                <span *ngIf="getTranslation(crop?.cropType?.nameT) !== ''">{{getTranslation(crop?.cropType?.nameT)}}</span>
                            </span> -->
                            <!-- <span *ngIf="!crop?.cropType?.nameT">{{crop?.cropType?.name}}</span> -->
                             <span>{{plan?.name}}</span>
                        </div> 
                        <!-- <div class="actions">
                            <i class="fa fa-edit" (click)="editCrop($event, type)"></i>
                            <i class="fa fa-trash" (click)="deleteCrop($event, type)"></i>
                        </div> -->
                    </mat-option>
                </div>
                </mat-select>
                <mat-error *ngIf="messageForm.controls['plan'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="w-100">
                <mat-label>Info</mat-label>
                <textarea matInput formControlName="info" name="info" class="text-background p-2" style="height: 80px; width: 100%"></textarea>
            </mat-form-field>
            </div>
            <div class="form-group w-100">
              <label class="col-form-label float p-0 " style="font-size: 0.8rem;">{{ "notes.images" | translate }}</label>
              <fap-image-list [imageIds]="msgImages"  [group]="''" (deleteImage)="deleteNoteImage($event)" (addImage)="addImage($event)"></fap-image-list>
          </div>
          </form>
        </fap-modal>