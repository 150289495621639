import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'charWidth'
})
export class CharWidthPipe implements PipeTransform {

  transform(value: string, font: string = '14px Roboto, sans-serif'): number {
    if(!value) return 0;
    const span = document.createElement('span');
    span.style.font = font;
    span.style.display = 'inline-block';
    span.style.visibility = 'hidden';
    span.style.whiteSpace = 'nowrap';
    span.textContent = value;

    document.body.appendChild(span);
    const width = span.offsetWidth;
    const padding = 7;
    document.body.removeChild(span);
    return Math.ceil(width + padding);

  }

}
