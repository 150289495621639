import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ForecastService } from './../../../../../core/services/api/forecast/forecast.service';
import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import {
    ActivatedRoute,
    Params,
    Router
} from '@angular/router';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { LotService } from '../../../../../core/services/api/farm/lot.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { take } from 'rxjs/operators';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { MapPolygonInterface } from '../../../../../shared/layout/fap_main-map/data/map-polygon.interface';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';

@Component({
    templateUrl: './crops-container.component.html',
    styleUrls: ['./crops-container.component.scss']
})
export class lotsContainerComponent implements OnDestroy, OnInit {

    public filteredFarms: Array<FarmModel> = [];
    public filteredLots: Array<LotModel> = [];
    public forecasts: Array<WeatherModel> = [];
    public currentUser;
    public isSelectDialogOpen: boolean = false;

    public queryParams: Params = {};
    public farmForecastMapping: { [key: number]: WeatherModel } = {};

    private subscriptions: Array<Subscription> = [];

    constructor(public farmService: FarmService,
                public lotService: LotService,
                public activatedRoute: ActivatedRoute,
                public mapService: MapService,
                public forecastService: ForecastService,
                public globalRegistry: GlobalRegistryService,
                public router:Router,
                public toastr: ToastrService,
                public translateService: TranslateService,
                public userService: UserService,
                public widgetService: WidgetsService
                ) {
                    this.subscriptions.push(
                        this.userService.getCurrentUser.subscribe(data => {
                            if(Object.keys(data).length != 0) {
                            this.currentUser = data;
                            const path = this.router.routerState.snapshot.url;
                            this.userService.getMenus.subscribe(menus => {  
                                if(Object.keys(menus).length != 0) {
                                    const pathExist = menus.some(menu => menu.path === path);
                                    if(pathExist === false) {
                                        this.router.navigate(['pages/posts']);
                                    }
                                }
                            })
                            }
                        })
                    );
        const localQueryParams = localStorage.getItem("queryParams")?JSON.parse(localStorage.getItem("queryParams")):{};
        this.router.navigate([],{
            relativeTo:this.activatedRoute,
            queryParams: localQueryParams
        });
        this.activatedRoute.queryParams
            .subscribe((queryParams: Params): void => {
                this.queryParams = queryParams;
                this.processLots();
                });
    }

    public processLots() {
        if (!this.forecasts || this.forecasts.length === 0) {
            // this.getForecasts();
        }
        if (this.queryParams['farms'] !== undefined) {
            // we have farms in query params, but need to check if we have an array of farms
            const filteredFarmIds: Array<number> = Array.isArray(this.queryParams['farms'])
                ? this.queryParams['farms'].map((farmId: string): number => parseInt(farmId, 10))
                : [parseInt(this.queryParams['farms'], 10)];
            this.filteredFarms = this.globalRegistry.systemData.farms.filter((farm: FarmModel): boolean => {
                return filteredFarmIds.includes(farm.id);
            });
        }
        else {
            this.filteredFarms = this.globalRegistry.filterFarms(this.queryParams['filter']);
        }
        if (this.queryParams['lots'] !== undefined) {
            // we have lots in query params, but need to check if we have an array of lots
            const filteredLotIds: Array<number> = Array.isArray(this.queryParams['lots'])
                ? this.queryParams['lots'].map((lotId: string): number => parseInt(lotId, 10))
                : [parseInt(this.queryParams['lots'], 10)];
            this.filteredLots = this.globalRegistry.systemData.lots.filter((lot: LotModel): boolean => {
                return filteredLotIds.includes(lot.id);
            });
        }
        else if (this.queryParams['farms'] !== undefined) {
            this.filteredLots = this.globalRegistry.systemData.lots.filter((lot: LotModel): boolean => {
                return this.filteredFarms.some((farm: FarmModel): boolean => farm.id === lot.farm);
            });
        }
        else {
            this.filteredLots = this.globalRegistry.systemData.lots;
        }
    }

    public ngOnDestroy(): void {
        this.mapService.resetMap();
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    }

    public ngOnInit() {
        // this.globalRegistry.reloadLots();
        this.filteredLots = this.globalRegistry.systemData.lots;
        console.log(this.filteredLots);
        this.widgetService.setSize(6);
        this.initMap();
    }

    public centerMapOnLot(lot: LotModel): void {
        const lotPolygon: MapPolygonInterface = this.mapService.mapPolygons
            .find((poly: MapPolygonInterface): boolean => poly.data['lot'] === lot.id);
        this.mapService.centerMapOnPolygon(lotPolygon);
    }

    public deleteLot(lotId: number): void {
        this.lotService.deleteLot(lotId).subscribe((): void => {
            this.globalRegistry.reloadLots();
            this.processLots();
            this.initMap();
            this.toastr.success(this.translateService.instant('farm.lot.lotDeletedSuccessfully'));
        }, (): void => {
            this.toastr.error(this.translateService.instant('farm.lot.errorDeletingLot'));
        });
    }

    private getForecasts(): void {
        this.forecastService.getForecast({ content_type: 'farm' })
            .pipe(take(1))
            .subscribe((forecasts: ResponseModel<WeatherModel[]>): void => {
                this.forecasts = forecasts.model;
                console.log(forecasts);
                this.initForecastMapping();
            });
    }

    private initForecastMapping(): void {
        if(this.forecasts) {
            this.forecasts.forEach((forecast: WeatherModel): void => {
                this.farmForecastMapping[forecast.objectId] = forecast;
            });
        }
    }

    private initMap(): void {
        if (window.innerWidth >= 767) {
            this.mapService.showMap();
        }
        this.mapService.resetMap();
        this.mapService.mapPolygons = [];
        this.mapService.isEditMode = false;
        this.filteredLots.forEach((lot: LotModel): void => {
            if (lot.coords && lot.coords.coordinates) {
                this.mapService.mapPolygons.push({
                    points: MapHelper.convertToAGMPolygon(lot.coords.coordinates[0]),
                    fillColor: '#ffff00',
                    strokeColor: '#ffff00',
                    data: { lot: lot.id }
                });
            }
        });
        this.mapService.centerMapOnPolygons();
    }
}
