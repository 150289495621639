import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { environment } from '../../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { H2oService } from '../../../../../core/services/api/h2o/h2o.service';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../../shared/services/nav.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'fap-classical-form',
    templateUrl: './fap-classical-form.component.html',
    styleUrls: ['./fap-classical-form.component.scss']
})
export class FapClassicalFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() objectId: number;
    @Input() entityId: number;
    @Input() formType: any;
    @Input() entityInfo: any;
    @Input() type = 'add';
    @Input() typeOfForm = '';
    @Input() formDisplayType = 'modal';
    @Input() btnsDisabled: boolean = false;
    mediaUrl = environment.mediaUrl;
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
    @Output() hideModal: EventEmitter<any> = new EventEmitter<any>();
    @Output() formUpdate: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitPreviousFormClick: EventEmitter<any> = new EventEmitter<any>();

    @Output() nextButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() prevButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() formFieldsEmit: EventEmitter<any> = new EventEmitter<any>();
    
    resetForm = false;
    public formFields = [];
    public mixedFields = [];
    @Input() previousFormClick = false;
    @Input() nextFormClick = false;
    public langString;
    public subscriptions: Array<Subscription> = [];
    // public defaultSize = 'pc';
    @Input() public errorFields = [];

    @Input()
    public entityRelation:string;

    @Input() workflow: any;
    @Input() public size: string = null;
    @Input() isLoading: boolean = false;

    constructor(public attributeService: AttributeService, public h2oService: H2oService, public toastr: ToastrService, public navService: NavService, private sanitizer: DomSanitizer) {
        this.updateDefaultSize();
    }

    ngOnInit(): void {
        this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.updateDefaultSize();
    }

    @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateDefaultSize();
  }

  private updateDefaultSize() {
    const width = window.innerWidth;
    this.size = width >= 1025 ? 'pc' : (width >= 768 ? 'tablet' : 'mobile');
    // if(this.size) {
    //     this.defaultSize = this.size;
    // }
  }

    public get width() {
        return window.innerWidth;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'entityInfo') && this.entityInfo) {
            console.log(this.entityInfo);
            this.configureData(this.entityInfo);
        }
        if(Object.prototype.hasOwnProperty.call(changes, 'nextFormClick') && this.nextFormClick) {
            console.log('next clicked');
            this.updateFields();
            this.nextFormClick = false
        }
        if(Object.prototype.hasOwnProperty.call(changes, 'previousFormClick') && this.previousFormClick) {
            console.log('previous clicked');
            this.formFields = [];
        }
        if(changes.hasOwnProperty('size') && this.size) {
            console.log(this.size);
        }
        console.log(changes);
    }

    configureData(entityInfo) {
        const organizeFieldsByGroup = (fields, groups_css) => {
            const groupMap = new Map();
            groups_css.forEach(group => {
              groupMap.set(group.id, { ...group, nestedFields: [] });
            });
            const ungroupedFields = [];
            fields.forEach(field => {
              const groupId = field.group;
          
              if (groupId === '' || groupId == 1 || groupId == null) {
                ungroupedFields.push(field);
              } else if (groupMap.has(groupId)) {
                groupMap.get(groupId).nestedFields.push(field);
              } else {
                if (!groupMap.has(groupId)) {
                  groupMap.set(groupId, { id: groupId, name: field.name, nestedFields: [], pc: field.pc, tablet: field.tablet, mobile: field.tablet });
                }
                groupMap.get(groupId).nestedFields.push(field);
              }
            });
            const groupedFields = Array.from(groupMap.values());
            console.log(groupedFields);
            return [...groupedFields, ...ungroupedFields];
          };
          console.log(entityInfo.groups_css);
          if(entityInfo.groups_css) {
            const organizedFields = organizeFieldsByGroup(entityInfo.fields_css, Object.values(entityInfo.groups_css));
            this.mixedFields = organizedFields;
          } else {
            this.mixedFields = entityInfo.fields_css;
          }

          console.log(this.mixedFields);
    }

    editValue(id, event) {
        console.log(event);
        this.formType.fields.forEach((field) => {
            if (id === field.id) {
                field.value.input = event.value;
                if(!event.validation) {
                    this.appendField(id, event.value);
                } else {
                    this.updateFormWithValidation(id, event.value, field.type, field.error)
                }
            }
        });
        console.log(this.formType);
    }

    updateFormWithValidation(id, value, type, error) {
        const formField = { id, value: {input: value}, type, error };
        this.formType.fields = this.formType.fields.map(field => field.id === formField.id ? formField : field);
        console.log(this.formType);
        this.attributeService.updateFormWithValidation(this.formType.id, {updated_fields: this.formType.fields}, formField.id).subscribe(data => {
            console.log(data);
            if(data) {
                this.formType.fields = data.body.results.fields;
                this.formFields = data.body.results.fields;
                this.formUpdate.emit(data.body.results.fields);
            }
        }, (error) => {
            this.handleError(error);
        });
    }

    appendField(id, value) {
        const formField = {
          id,
          value
        };
        const existingFieldIndex = this.formFields.findIndex(obj => obj.id === id);
        
        if (existingFieldIndex !== -1) {
          this.formFields[existingFieldIndex].value = {input: value};
        } else {
          this.formFields.push(formField);
        }
        this.formFieldsEmit.emit(this.formFields);
        console.log(this.formFields);
      }
      

      updateFields() {
        this.attributeService.updateForm(this.formType.id, { updated_fields: this.formFields }).subscribe(
            data => {
                console.log(data);
    
                if (this.entityRelation === 'pool' && data.body.results.id) {
                    const body = { form: this.formType.id };
                    this.h2oService.createPool(body).subscribe(
                        poolData => {
                            console.log(poolData);
                            if (poolData) {
                                this.handleSuccess(poolData.body.results);
                            }
                        },
                        err => this.handleError(err)
                    );
                } else {
                    this.handleSuccess(data.body.results);
                }
            },
            err => this.handleError(err)
        );
    }
    
    handleSuccess(result: any) {
        this.errorFields = [];
        if(result.errors > 0) {
            this.formFields = result.fields;
            this.formType.fields = result.fields;
            return
        } else {
            this.closeModal.emit();
            this.formUpdate.emit(result);
            if (this.entityRelation === 'pool') {
                this.resetForm = true;
            }
            if (this.entityRelation === 'workflow' || this.entityRelation === 'workflow_modal' || this.entityRelation === 'activity_list') {
                this.nextButtonClick.emit(true);
            }
        }
    }
    
    handleError(error: any) {
        this.toastr.error(error.error.results.error);
        if(error.error.results.error.form) {
            console.log(error.error.results.error.form.fields);
            this.errorFields = error.error.results.error.form.fields;
        }
    }
    

    checkPrevButtonClick() {
        console.log('prev button clicked')
    }

    sanitizeHTML(html): any {
        if(html && html !== '') {
            return this.sanitizer.bypassSecurityTrustHtml(html);
        }
    }

    getCols(field: any, size: string = 'pc'): number {
        if(!field?.[size]) return 12;
        return field?.[size]?.cols 
          ?? field?.pc?.cols 
          ?? field?.default?.cols 
          ?? 12; // Final fallback to default value
      }
      
      getRows(field: any, size: string = 'pc'): number {
        if(!field?.[size]) return 1;
        return field?.[size]?.rows 
          ?? field?.pc?.rows 
          ?? field?.default?.rows 
          ?? 1; // Final fallback to default value
      }
      
      getOrder(field: any, size: string = 'pc'): number {
        if(!field?.[size]) return 1;
        return field?.[size]?.pos 
          ?? field?.pc?.pos 
          ?? field?.default?.pos 
          ?? 1; // Final fallback to default value
      }  

    ngOnDestroy() {
        this.subscriptions.forEach(element => {
            element.unsubscribe();
        });
    }
}
