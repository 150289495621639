import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    OnInit,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { environment } from 'src/environments/environment';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { PersonModel } from '../../../../core/models/person/person.model';
import { ActivityService } from '../../../../core/services/api/activity/activity.service';
import { CompanyService } from '../../../../core/services/api/company/company.service';
import { FarmService } from '../../../../core/services/api/farm/farm.service';
import { FapModalComponent } from '../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { NavService } from '../../../../shared/services/nav.service';

@Component({
    selector: 'fap-user-card',
    templateUrl: './fap_user-card.component.html',
    styleUrls: ['./fap_user-card.component.scss'],
})
export class FapUserCardComponent implements OnInit {
    public defaultImg: string;

    @Input()
    public person: PersonModel;

    public groupNames = '';

    @Input()
    public enableAddEdit: boolean;

    @Input()
    public enableDelete: boolean;

    @Output()
    public removePerson: EventEmitter<number> = new EventEmitter();
    public closeCombo: boolean = true;
    
    public images;
    public imageForm: UntypedFormGroup;
    public image: File;
    uploadFileAcceptFormats: string[] = ['image/jpeg', 'image/gif', 'image/png', 'image/svg+xml'];
    activity_count = 0;
    public messageForm: UntypedFormGroup;
    public sendMsgModalButtonPrimaryInterface: FapModalButtonInterface;
    public sendMsgModalButtonSecondaryInterface: FapModalButtonInterface;
    @ViewChild('sendMsgModal')
    public sendMsgModal: FapModalComponent;
    public translatedNames:any = [];
    public langString: string;
    @ViewChild('addImageModal')
    public addImageModal: FapModalComponent;
    public subscription: Subscription = new Subscription();
    public msgImages = [];
    public personIds = [];
    public mediaUrl = environment.mediaUrl;
    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};

    @ViewChild('fileUploader') fileUploader:ElementRef;

    constructor(
        public globalRegistryService: GlobalRegistryService,
        public activityService: ActivityService,
        public translateService: TranslateService,
        public navService: NavService,
        public companyService: CompanyService,
        public farmService: FarmService,
        public cacheService: CacheResolverService,
        public toastrService: ToastrService
    ) {}

    ngOnInit(): void {
      this.defaultImg = 'assets/images/people_placeholder.svg';
      this.groupNames = this.globalRegistryService.systemData.peopleGroups
          .filter((group) => group.users.includes(this.person.id))
          .map((group) => group.name)
          .join(',');
          if(this.person && this.person.activities) {
            this.activity_count = this.person.activities.length;
          }
      this.initForm();
      this.initSendMsgModalButtons();
      this.initImageForm();
      this.langString = localStorage.getItem('language');
      this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
          if(lang) {
              this.langString = lang;
          }
      }));
      this.translatedNames = this.globalRegistryService.systemData.translations;
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'Deselect All',
        itemsShowLimit: 3000,
        allowSearchFilter: true
      };
  }

  initImageForm() {
    this.imageForm = new UntypedFormGroup({
      file: new UntypedFormControl('', Validators.required),
      group: new UntypedFormControl('posts', Validators.required),
      tags: new UntypedFormControl([], Validators.required),
    });
  }

  updateItems(lots) {
    this.messageForm.get('lots').setValue(lots);
  }

  changeLotFormat(lotIds) {
    const lots = [];
    lotIds.forEach(element => {
        if(typeof(element) === 'number') {
        const lot = this.globalRegistryService.systemData.lots.filter(l => {
            if(l.id === element) {
                return l;
            }
        });
        lots.push(lot[0]);
    }});
    const uniqueLots = lots.filter(function(item, pos) {
        return lots.indexOf(item) == pos;
    })
    this.selectedItems = uniqueLots;
    return uniqueLots
}

    public sendMsg(personId) {
        console.log(personId);
    }

    public initSendMsgModalButtons(): void {
        const _this: FapUserCardComponent = this;

        this.sendMsgModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.submit();
            },
            text: this.translateService.instant('save'),
        };

        this.sendMsgModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.sendMsgModal.hideModal();
            },
            text: this.translateService.instant('cancel'),
        };
    }

    public initForm() {
        this.messageForm = new UntypedFormGroup({
            name: new UntypedFormControl('', Validators.required),
            plan: new UntypedFormControl(null, Validators.required),
            images: new UntypedFormControl([]),
            info: new UntypedFormControl('')
        });
    }

    get f() {
      return this.messageForm.controls;
    }

    public submit() {
        if (this.messageForm.invalid) {
            Object.keys(this.messageForm.controls).forEach(
                (controlName: string) =>
                    this.messageForm.controls[controlName].markAsTouched()
            );
            return;
        }
        console.log(this.messageForm.value);
        this.farmService.sendMessage(this.personIds, this.messageForm.value).subscribe(data => {
          console.log(data);
          if(data.body.results) {
            
            this.sendMsgModal.hideModal();
          }
        }, () => {
          this.toastrService.error("Send message failed!");
        })
    }

    public showMsgModal(id): void {
      this.sendMsgModal.showModal();
      this.personIds.push(id);
  }

  getTranslation(translation) {
    const t =this.translatedNames.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
      return translation
  }
  }

addImage(event) {
  const imageId = event.value.id;
  this.msgImages = [...this.msgImages, imageId];
  console.log(this.msgImages);
  this.messageForm.get('images').setValue(this.msgImages);
}

deleteNoteImage(imgId) {
  this.msgImages = this.msgImages.filter((item) => item !== imgId);
  console.log(this.msgImages);
  this.messageForm.get('images').setValue(this.msgImages);
}

}
