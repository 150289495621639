<fap-header>
    <fap-header-actions [title]="'farm.park' | translate" [isDeleteEnabled]="farmId ? true : false"></fap-header-actions>
</fap-header>
<fap-farm-edit-layout
    [farmId]="farmId"
    [farmName]="farmName"
    [farmParty]="farmParty"
    [isEditMode]="isEditMode"
    [isSubmitDisabled]="checkSubmitEnable()"
    [parties]="globalRegistry.systemData.parties"
    (submitFarm)="submitFarm($event)"
    (deleteFarmEvent)="showDeleteFarmModal($event)"
    >
</fap-farm-edit-layout>
<fap-modal
    #deleteFarmConfirmationModal
    [inputTitle]="'confirmDelete' | translate"
    [inputPrimaryButtonInterface]="deleteFarmModalPrimaryButton"
    [inputSecondaryButtonInterface]="deleteFarmModalSecondaryButton">
    <p>{{ 'farm.areYouSureDeleteFarm' | translate }}</p>
</fap-modal>