import { Component, EventEmitter, Input, OnInit, Output, Type } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'fap-generic-modal',
  templateUrl: './fap-generic-modal.component.html',
  styleUrls: ['./fap-generic-modal.component.scss']
})
export class FapGenericModalComponent implements OnInit {

  @Input() public showFilters: boolean = true;
  @Input() public addEnabled: boolean = true;
  @Input() public isProduct: boolean = false;
  @Input() public displayFields: string[] = [];
  @Input() public headerFields: any[] = [];
  @Input() public items: any[] = [];
  @Input() public usePipes: boolean = true;
  @Input() public pipe: Type<any>;
  @Input() public pipeUsedBy;
  @Input() public pipeArgs;
  @Input() public enableActions: boolean = true;
  @Input() public selectField: string = 'name';
  @Input() public tableHeight: string = '100%';
  @Input() public isEditable: boolean = true;
  @Input() public isDeleteable: boolean = true;
  @Input() public count: number = 0;
  @Input() public pageSize: number = 0;
  @Input() public selectionMode: 'single' | 'multiple' | null = 'single';
  @Input() public selectedItems: any[] = [];
  @Input() public isRowSelectable: boolean = false;
  @Input() public selectedIds: number[] = [];
  @Input() public multiple: boolean = false;
  @Input() public setCustomHeight: boolean = true;

  @Output() public outputOnCreate: EventEmitter<any> = new EventEmitter();
  @Output() public outputOnEdit: EventEmitter<any> = new EventEmitter();
  @Output() public outputOnDelete: EventEmitter<any> = new EventEmitter();
  @Output() public outputOnSearch: EventEmitter<any> = new EventEmitter();
  @Output() public closeModal: EventEmitter<any> = new EventEmitter();
  @Output() public handlePagination: EventEmitter<any> = new EventEmitter();
  @Output() public selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() public outputOnSelectItems: EventEmitter<any> = new EventEmitter();

  public filterForm: FormGroup;
  public selectAll: boolean = false;
  
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  public initForm() {
    const formBody = {
      search: [''],
    };
    this.filterForm = this.formBuilder.group(formBody);
  }

  emitSearch(e) {
    this.outputOnSearch.emit(e.target.value);
  }

  selectItem(item) {
    console.log(item);
    if(!this.multiple) {
      this.selectionChange.emit(item);
    } else {
      this.toggleSelection(item);
    }
  }

  getNestedValue(object: any, path: string) {
    return path.split('.').reduce((o, key) => (o ? o[key] : undefined), object);
  }

  isItemSelected(item: any): boolean {
    return this.selectedIds.includes(item.id);
  }

  toggleSelection(item: any) {
    if (this.isItemSelected(item)) {
      this.selectedIds = this.selectedIds.filter(id => id !== item.id);
    } else {
      this.selectedIds.push(item.id);
    }
    if(this.items.length === this.selectedIds.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
    this.outputOnSelectItems.emit(this.selectedIds);
  }

  toggleSelectAll() {
    if (this.selectedIds.length !== this.items.length) {
      this.selectedIds = this.items.map(item => item.id);
      this.selectAll = true;
    } else {
      this.selectedIds = [];
      this.selectAll = false;
    }
    this.outputOnSelectItems.emit(this.selectedIds);
  }

}
