<div class="dashboard-card dash_cont">
  <div class="d-flex justify-content-between">
      <div class="col-md-4 pl-0">
          <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
      </div>
      <div class="col-md-6 col_lot_name">
          <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
      </div>
      <div class="col-md-2 text-right p-0">
          <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
      >edit</em
    > -->
          <span class="conf_icon" (click)="toggleMenu()">
              <i></i>
              <i></i>
              <i></i>
          </span>
          <div class="new_menu {{ toggleMain }}">
              <ul (click)="hideMain()">
                  <li (click)="emitShow.emit(true)"><span>Settings</span></li>
              </ul>
          </div>
      </div>
  </div>
  <div class="row">
      <div #container class="fixed_img_width" style="text-align: center; max-height: 100%;">
          <div class="position-relative crop-eye-img" (click)="getCoords($event)">
              <ng-container *ngFor="let image of images">
                <ng-container *ngIf="selectedDropdownValue == image?.id">
                <img *ngIf="image?.value_json?.data?.url" class="myImg" #originalImage [src]="mediaUrl + image?.value_json?.data?.url" alt="">
                <div id="sball"></div>
                <span class="loading small-loader" *ngIf="!image?.value_json?.data">
                    <span class="loader">
                        <i></i>
                    </span>
                </span>
                <div class="battery color-yellow-a" *ngIf="batteryData?.sensor?.lastValue?.value_float">
                    <div class="label_container d-flex align-items-center">
                        <div class="icon">
                            <ng-container *ngIf="batteryData?.sensor?.icon">
                                <img [src]="mediaUrl + batteryData?.sensor?.icon?.file" alt="">
                            </ng-container>
                            <ng-container *ngIf="!batteryData?.sensor?.icon && batteryData?.unitType?.icon">
                                <img [src]="mediaUrl + batteryData?.unitType?.icon?.file" alt="">
                            </ng-container>
                        </div>
                        <div class="value">
                            {{batteryData?.sensor?.lastValue?.value_float}}
                        </div>
                        <div class="unit">
                            {{batteryData?.unitType?.unit}}
                        </div>
                    </div>
                </div>
                <div class="camera_status color-yellow-a" *ngIf="cameraStatus?.element?.value_float === 1">
                    <div class="label_container d-flex align-items-center">
                        <div class="icon">
                            <ng-container *ngIf="cameraStatus?.sensor?.icon">
                                <img [src]="mediaUrl + cameraStatus?.sensor?.icon?.file" alt="">
                            </ng-container>
                            <ng-container *ngIf="!cameraStatus?.sensor?.icon && cameraStatus?.unitType?.icon">
                                <img [src]="mediaUrl + cameraStatus?.unitType?.icon?.file" alt="">
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="teaser w-100 d-flex" (click)="showDataModal(widget)" *ngIf="selectedDropdownValue == image?.id">
                    <div class="info">
                        <div class="text-white fs12 bg-blur" style="padding: 1px; text-align: left;">
                            <span class="color-yellow-a">Zoom: {{
                                image?.value_json?.data?.zoom
                            }}</span>
                            <span>
                                Pan: {{ image?.value_json?.data?.pan }}º
                            </span>
                            <span class="color-yellow-a"
                                >Tilt: {{ image?.value_json?.data?.tilt }}º</span
                            >
                        </div>
                    </div>
                    <!-- <div class="slider">
                        <marquee *ngIf="notes.length > 0">
                            <div class="row" *ngFor="let note of notes">
                                <div class="inner-item">
                                    <span
                                        class="dot"
                                        [ngStyle]="{ background: note?.type?.color }"
                                    ></span>
                                    <p
                                        class="mb-0 f-14 green letter-spacing-pt2em text-center line-height-pt3"
                                    >
                                        {{ note?.type?.name }}
                                    </p>
      
                                    <p class="mb-0 val font-weight-bold">
                                        <span *ngIf="note?.name">{{ note?.name }}</span>
                                    </p>
                                </div>
                            </div>
                        </marquee>
                    </div> -->
                    <!-- <span *ngIf="notes.length === 0">
                        {{ 'widget.noExtraInfo' | translate }}
                    </span> -->
                </div></ng-container>
              </ng-container>
          </div>
          <div class="bg-green-b row mx-0">
              <div class="col-md-12 px-2 py-1" style="width: 100%; display: block;">
                  <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                      <button
                          (click)="previousNextValue(false)"
                          [disabled]="selectedDropdownValue <= 1"
                          class="round-btn-green round-btn-green-left mr-2"
                      >
                          <img
                              src="./assets/images/Polygon 4.svg"
                              alt="Polygon 4"
                          />
                      </button>
                      <select
                          (change)="changeDropdown($event.target.value)"
                          [value]="selectedDropdownValue"
                          name="id"
                          class="custom-select bs-select border-green-select text-center m-0"
                      >
                          <option
                              *ngFor="
                                  let option of dropdownValue;
                                  let i = index
                              "
                              [value]="option.id"
                          >
                              {{ getFormattedDate(option.time) }}
                          </option>
                      </select>
                      <button
                          (click)="previousNextValue(true)"
                                                     
                          class="round-btn-green round-btn-green-right ml-2"
                      >
                          <img
                              src="./assets/images/Polygon 4.svg"
                              alt="Polygon 4"
                          />
                      </button>
                      <a class="btn mr-1 round-btn-green d-flex align-items-center justify-content-center p-0 pt-1" (click)="showList()" style="line-height:20px"><i class="fa fa-list" aria-hidden="true"></i></a>
                      <button (click)="showNewAcquisition()" class="btn round-btn-green d-flex align-items-center justify-content-center p-0 pt-1"><i class="fa fa-camera" aria-hidden="true"></i></button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<fap-modal
  [inputIsLarge]="true"
  #addEditWidgetModal
  [titleColor]="'#ffffff'"
  inputTitle="Crop eye"
  class="crop_modal"
  [showFooter]="false"
>
  <div class="md_content">
      <h4>{{"widget.acquisitionList" | translate}}</h4>
      <div class="aq_list">
          <div class="aq_li" *ngFor="let list of acquisitionList; let idx = index">
              <div class="date">
                  {{ getFormattedDate(list.time) }}
              </div>
              <div class="user">
                
                  <ng-container *ngFor="let user of personList">
                    <ng-container *ngIf="user?.user">
                        <span *ngIf="user?.user?.id == list?.user">
                          <span class="picture" *ngIf="user?.picture">
                            <img style="max-width: 100%; display: inline-block;" src="{{mediaUrl + user?.picture}}" alt="profile picture" />
                          </span>
                          <span class="picture" *ngIf="!user?.picture">
                            <img style="max-width: 100%; display: inline-block;" src="../../../../assets/images/people_placeholder.png" alt="profile picture" />
                          </span>
                          <b *ngIf="list.user === 1">{{ user?.firstName }} {{ user?.lastName }} {{ user?.first_name }} {{ user?.last_name }}</b>
                          <span *ngIf="list.user != 1">{{ user?.firstName }} {{ user?.lastName }} {{ user?.first_name }} {{ user?.last_name }}</span>
                          </span
                      >
                      </ng-container>
                  </ng-container>
              </div>
              <!-- <div class="type">
                  <ng-container *ngFor="let type of imageTypes">
                      <span *ngIf="type.id === list.type">{{
                          type.name
                      }}</span>
                  </ng-container>
              </div> -->
              <div class="status">
                  <input
                      type="checkbox"
                      (change)="statusChange(list)"
                      [value]="list.accepted"
                      [checked]="list.accepted"
                      [disabled]="
                          !globalRegistry.userData.profile.role == 1000
                      "
                  />
                  <span></span>
              </div>
              <div
                  class="delete"
                  *ngIf="
                      globalRegistry.userData.profile.role == 1000 ||
                      globalRegistry.userData.profile.id == list.user
                  "
              >
                  <span style="cursor: pointer">
                      <em class="material-icons delete-icon" (click)="removeItem(list.id)"
                          >delete</em
                      >
                  </span>
              </div>
          </div>
      </div>
      <!-- <a class="btn mt-2 ml-0" target="_blank" [href]="images[0]?.image" style="line-height:20px">View</a> -->
  </div>
</fap-modal>

<fap-modal
  [inputIsLarge]="true"
  #notesModal
  [titleColor]="'#ffffff'"
  inputTitle="Notes"
>
  <div class="pl-1 mt-3 mt-md-0 c-eye">
      <div class="crop-eye-bg h-100">
          <div
              class="row border-green-a mb-2 mx-0"
              *ngFor="let note of notes"
          >
              <div
                  class="col-6 border-left-blue-a"
                  [ngStyle]="{ 'border-left-color': note?.type?.color }"
              >
                  <p
                      class="mb-0 f-14 letter-spacing-pt2em text-center line-height-pt3"
                  >
                      {{ note?.type?.name }}
                  </p>
              </div>
              <div class="col-6 px-0 bg-green-a py-1">
                  <p
                      class="mb-0 pad-1px letter-spacing-pt2em f-13 text-center line-height-pt3 font-weight-bold"
                  >
                      {{ note?.name }}
                  </p>
              </div>
          </div>
      </div>
  </div>
</fap-modal>

<fap-modal
  [inputIsLarge]="true"
  [showFooter]="false"
  #confirmPopup
  [titleColor]="'#ffffff'"
  [inputTitle]="'widget.imageAcquisition' | translate"
>
  <div class="mod_body">
      <h2>{{"widget.focusArea" | translate}}</h2>
      <p class="region_info">{{'widget.focusHere' | translate}}</p>
      <div class="img_box" *ngIf="size">
        <ng-container *ngFor="let image of images">
          <img class="myImg" #originalImage *ngIf="selectedDropdownValue == image?.id" [src]="mediaUrl + image?.value_json?.data?.url" alt="" [ngStyle]="{'width': size[0] + 'px', 'height': size[1] + 'px'}">
          <div id="sball1"></div>
        </ng-container>
      </div>
  <div class="d-flex p-1 row">
      <form
          class="form-group w-100 image_form"
          [formGroup]="imageForm"
      >
          <mat-form-field class="zoom">
              <mat-label>{{"widget.zoom" | translate}}</mat-label>
              <mat-select required formControlName="zoom">
                  <div class="slct scroll_wrap">
                      <mat-option
                          *ngFor="let zoom of zoomLevels"
                          [value]="zoom"
                          style="text-transform: capitalize"
                      >
                          <span class="small_text">{{ zoom }}</span>
                      </mat-option>
                  </div>
              </mat-select>
              <span class="unit small"><i class="fa fa-times"></i></span>
          </mat-form-field>
          <mat-form-field class="pan" *ngIf="this.selectedType != 30">
              <mat-label>{{"widget.pan" | translate}}</mat-label>
              <mat-select required formControlName="pan">
                  <div class="slct scroll_wrap">
                      <mat-option
                          *ngFor="
                              let pan of panLevels
                          "
                          [value]="pan"
                          style="text-transform: capitalize"
                      >
                          <span class="small_text">{{ pan }}</span>
                      </mat-option>
                  </div>
              </mat-select>
              <span class="unit">°</span>
          </mat-form-field>
          <mat-form-field class="tilt">
              <mat-label>{{"widget.tilt" | translate}}</mat-label>
              <input matInput placeholder="Tilt" type="number" (blur)="restrict($event)" formControlName="tilt">
              <span class="unit">°</span>
          </mat-form-field>
          <div class="btns">
              <button class="btn" (click)="submit()">{{"yes" | translate}}</button>
              <button class="btn" (click)="closeModal(widget)">{{"no" | translate}}</button>
          </div>
      </form>
  </div>
  </div>
</fap-modal>

<fap-modal #automationPopup [inputTitle]="'widget.automation' | translate">
  <form [formGroup]="addEditAutomationForm" class="col-12 p-0">
      <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name', 'nameInput1', automation, addEditAutomationTranslationPopup, 'addEditAutomationForm')">
          <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
          <input matInput formControlName="name" readonly #nameInput1>
          <mat-error *ngIf="addEditAutomationForm.controls['name_t'].errors?.required">
              <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
          </mat-error>
      </mat-form-field>
      <fap-add-edit-delete-select 
      [inputName]="'scheduler'"
      style="max-width: 100%; padding: 0;"
      class="nhalf_width col-4"
      [formControl]="addEditAutomationForm.controls['scheduler']"
      name="scheduler"
      [inputIsRequired]="true"
      [apiSearchEnabled]="true"
      [inputNameFieldName]="'name'"
      [inputNameTFieldName]="'name_t'"
      [inputValueFieldName]="'id'"
      [inputSelectValues]="schedulers"
      [inputConfirmDeleteText]="'widget.confirmDeleteScheduler' | translate"
      (outputOnDelete)="deleteScheduler($event)"
      (outputOnCreate)="schedulerPopup.showModal()"
      (outputOnEdit)="editScheduler($event)"
      (selectionChange)="addEditAutomationForm.get('scheduler').setValue($event)"
      >
  </fap-add-edit-delete-select>
      
      <ng-container *ngFor="let autoForm of automationForm">
          <ng-container *ngFor="let formField of autoFormFields">
              <ng-container *ngIf="autoForm.type === formField.id">
                  <ng-container *ngIf="formField.valueType === 'boolean'">
                      <label>{{ formField.name }}</label>
                      <div class="custom_toggle mb-4">
                          <div class="on">
                              <input type="radio" (change)="toggleValue(1)" [formControlName]="autoForm?.id" value="1">
                              <span>On</span>
                          </div>
                          <div class="off">
                              <input type="radio" (change)="toggleValue(0)" [formControlName]="autoForm?.id" value="0">
                              <span>Off</span>
                          </div>
                      </div>
                  </ng-container>
              </ng-container>
          </ng-container>
      </ng-container>
      
      <div class="btns">
          <div class="delt_btn_container">
              <button class="delt_btn" *ngIf="automation">
                  <i class="fa fa-trash" (click)="deleteAutomation(automation?.id)"></i>
              </button>
          </div>
          <div class="action_btns d-flex justify-content-end">
              <button class="btn mr-1" (click)="submitAutomation()">{{ 'translations.save' | translate }}</button>
              <button class="btn" (click)="automationPopup.hideModal(); addEditAutomationForm.reset()">{{ 'translations.cancel' | translate }}</button>
          </div>
      </div>
  </form>
</fap-modal>

<fap-modal #schedulerTriggerModal [inputTitle]="'scheduler.command' | translate" [showFooter]="false" [inputIsMedium]="true">
  <fap-scheduler-trigger 
      [formTypeId]="sensorData?.type?.entity_form"
      [sensorId]="sensorData?.id"
      (updatedForm)="onUpdatedForm($event)"
  ></fap-scheduler-trigger>
</fap-modal>

<fap-modal #addEditTranslationsPopup [inputTitle]="label | translate" [showFooter]="false">
  <add-edit-translations 
      [obj]="convertKeysToCamelCase(this.currentSensor)" 
      prefix="SENSOR_" 
      [label]="label"
      [translation]="translation"
      [group]="'sensors'"
      (submitTranslation)="onAddUpdateTranslation($event)" 
  >
  </add-edit-translations>
</fap-modal>

<fap-modal #addEditAutomationTranslationPopup [inputTitle]="label | translate" [showFooter]="false">
  <add-edit-translations 
      [obj]="convertKeysToCamelCase(this.currentSensor)" 
      prefix="TRIGGER_" 
      [label]="label"
      [translation]="automationTranslation"
      [group]="'triggers'"
      (submitTranslation)="onAddUpdateAutomationTranslation($event)" 
  >
  </add-edit-translations>
</fap-modal>