import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ViewChild,
    OnDestroy,
    ElementRef
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import {
    FapModalComponent,
} from '../../../../../shared/partials';
import { EquipmentTypeModel } from '../../../../../core/models/type/equipment-type.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentInterface } from '../../../../../core/interfaces/equipment/equipment.interface';
import { PartyModel } from '../../../../../core/models/party/party.model';
import { NameValueInterface } from '../../../../../core/interfaces/general/name-value.interface';
import { UnitValueModel } from '../../../../../core/models/units/unit-value.model';
import { NavService } from '../../../../../shared/services/nav.service';
import { Subscription } from 'rxjs';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { take } from 'rxjs/operators';
import { NewUnitModel } from '../../../../../core/models/units/new-unit-model';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { UnitModel } from '../../../../../core/models/units/unit.model';
import { UnitService } from '../../../../../core/services/api/units/unit.service';
import { ResponseModel } from '../../../../../core/models/response.model';
import { ResourceTypeModel } from '../../../../../core/models/type/resource-type.model';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { DocumentModel } from '../../../../../core/models/stock/document.model';
import { ActivityService } from '../../../../../core/services/api/activity/activity.service';
import * as moment from 'moment';
import { PartyService } from './../../../../../core/services/api/company/party.service';
import { environment } from '../../../../../../environments/environment';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { Location } from '@angular/common';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { MapPolygonInterface } from '../../../../../shared/layout/fap_main-map/data/map-polygon.interface';
import { UtilsHelper } from '../../../../../core/heplers/utils.helper';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';

@Component({
    selector: 'fap-add-edit-equipment-layout',
    templateUrl: './fap_add-edit-equipment-layout.component.html',
    styleUrls: ['./fap_add-edit-equipment-layout.component.scss']
})
export class FapAddEditEquipmentLayoutComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    public isEditMode = false;

    @Input()
    public isPreviewMode = false;

    @Input()
    public equipment: EquipmentModel;

    @Input()
    public equipmentId: number;

    @Input()
    public isSubmitDisabled = false;

    @Input()
    public equipmentPoweredTypes: Array<NameValueInterface>;

    @Input()
    public equipmentTypes: Array<EquipmentTypeModel>;

    public parties = [];

    @Input()
    public farms: Array<FarmModel> = [];

    public products = [];

    @Output()
    public submitEquipment: EventEmitter<{}> = new EventEmitter();

    @Output()
    public enabledEdit: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public deleteParty: EventEmitter<number> = new EventEmitter();

    @Output()
    public deleteEquipmentType: EventEmitter<number> = new EventEmitter();

    @Output()
    public deleteEquipment: EventEmitter < any > = new EventEmitter();

    @Output()
    public deleteProduct: EventEmitter<number> = new EventEmitter();

    @Output()
    public addedProduct: EventEmitter<any> = new EventEmitter();

    @Output()
    public updatedProduct: EventEmitter<any> = new EventEmitter();

    // @ViewChild('equipmentImageUpload')
    // public equipmentImageUpload: FapUploadImageComponent;

    @ViewChild('addEditPartyModal')
    public addEditPartyModal: FapModalComponent;

    @ViewChild('addEditProductModal')
    public addEditProductModal: FapModalComponent;

    @ViewChild('productAlertModal')
    public productAlertModal: FapModalComponent;

    @ViewChild('typeFilter')
    public typeFilter: ElementRef

    // @ViewChild('addEditEquipmentTypeModal')
    // public addEditEquipmentTypeModal: FapModalComponent;

    public equipmentForm: UntypedFormGroup;

    public changedImage: boolean;
    public equipmentHasImage: boolean;
    public equipmentImage: File = null;

    public initialEquipmentForm: EquipmentInterface;
    public initialEquipmentImageUrl: string = null;

    public selectedParty: PartyModel;
    public selectedEquipmentType: EquipmentTypeModel;
    public parkingPlace: MapPolygonInterface = null;
    public manufacturePower: UnitValueModel;
    public length: UnitValueModel;
    public width: UnitValueModel;
    public height: UnitValueModel;
    public weight: UnitValueModel;
    public workingWidth: UnitValueModel;
    public tankSize: UnitValueModel;
    public consumptionMin: UnitValueModel;
    public consumptionMax: UnitValueModel;
    private subscriptions: Array<Subscription> = [];
    public isEdit = false;
    public closeCombo: boolean = true;
    public translatedNames:any = [];
    public langString: string;
    public subscription: Subscription = new Subscription();
    public product;
    public productId;
    public activity;
    public inspection;
    public bill;
    
    public quantityPack: NewUnitModel;
    public selectedUnit: UnitModel;
    public currentUnit: UnitModel;
    public parentUnit: UnitModel;
    public productImages:any = [];
        
    public units: Array<UnitModel> = [];
    public unitForm: UntypedFormGroup;
    public selectedUnitId: number;
   
    public selectedResource: ResourceTypeModel;
    public parentResource: ResourceTypeModel;
    public images =  [];
    public image: File;
    imageSrc: string;
    public imageForm: UntypedFormGroup;
    public farmId = null
  
    public persons: Array<PersonModel> = [];
    public bills: Array<DocumentModel> = [];
    public activities;
    public imgWidth: number;
    public imgHeight: number;
    public orientation: string;
    @Input() resourceTypes: Array<ResourceTypeModel> = [];
    @ViewChild('mys') mys;
    public searchKeyword = '';
  @ViewChild('addProductModal')
  public addProductModal: FapModalComponent;
 
  @ViewChild('addEditUnitModal')
  public addEditUnitModal: FapModalComponent;
  @ViewChild('addEditPartyModal')
  @Output()
  public addedParty: EventEmitter<PartyModel> = new EventEmitter();
  public getMore = true
  @Output()
  public updatedParty: EventEmitter<PartyModel> = new EventEmitter();
  public selectedEquipment;
  public showProdError = false;
  public packUnit: NewUnitModel;
  public quantityPackError = false;
  public packUnitError = false;
  public getMoreParties = true;
  public getMoreDocs = true;
  public nextToken: { group: 'EQUIPMENT'; limit: number; offset: number; search: string; } = null;
  public nextDocToken: { offset: number; limit: number, search?: string } = null;
  public limit = 7;
  public mediaUrl = environment.mediaUrl;
  public partyToken: { offset: number; limit: number, search: string; } = null;
  public selectedPartyId: number;
  public docId: number;
  public farmPolygon: MapPolygonInterface;
  public entityInfo: any;
  public newEquipment = false;
  public formType;
  public formFields;

    constructor(public formBuilder: UntypedFormBuilder,
                public toastr: ToastrService,
                public navService: NavService,
                public translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                public mapService: MapService,
                public globalRegistryService: GlobalRegistryService,
                public typesService: TypesService,
                public stockService: StockService,
                public unitService: UnitService,
                public toastyService: ToastrService,
                public confirmModalService: ConfirmModalService,
                public companyService: CompanyService,
                public activityService: ActivityService,
                public partyService: PartyService,
                public cacheService: CacheResolverService,
                private location: Location,
                public farmService: FarmService,
                public widgetService: WidgetsService,
                public attributeService: AttributeService
                ) { }

    public ngOnInit(): void {
      this.widgetService.setSize(6);
        this.initForm();
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.globalRegistryService.systemData.translations;
        
        this.subscriptions.push(this.navService.emitSubmit.subscribe((value: boolean) => {
            if (value) {
                this.submitForm();
                this.updateAttributes();
            }
        }));

        this.subscriptions.push(this.navService.emitDelete.subscribe((value: boolean) => {
            value && this.deleteCurrentEquipment();
        }));
        console.log(this.farms);
        this.enableEdit();
               
        this.persons = this.globalRegistryService.systemData.persons;

        this.subscriptions.push(this.mapService.onLastPolyPathChange.subscribe((polygon: MapPolygonInterface): void => {
          if (polygon.data && polygon.data['isFarm']) {
              return;
          }
          this.parkingPlace = polygon;
          this.parkingPlace.strokeColor = '#3366ff';
          this.parkingPlace.fillColor = '#3366ff';
          this.parkingPlace.isEditable = true;
      }));
      this.getFormTypes();

      this.route.url.subscribe(segments => {
        this.newEquipment = segments.some(segment => segment.path === 'add');
        if (this.newEquipment) {
          this.equipmentForm.get('farm').setValue(this.farms[0].id)
          this.changeFarm(this.farms[0].id);
        }
      });
      
    }

    public getFormTypes() {
      const url = this.typesService.getUrl('form/');
      this.cacheService.delete(url+'entity=equipment');
      const params = {entity: 'equipment'}
      this.typesService.getFormTypes(params).subscribe(data => {
        console.log(data);
        this.entityInfo = data.body.results[0];
      })
    }

    private loadFarm(): void {
      this.mapService.mapPolygons = [];
      this.parkingPlace =  null
      const url = this.farmService.getUrl('')
      this.cacheService.delete(url + 'farm/' + this.farmId + '/')
      this.farmService.getFarm(this.farmId).pipe(take(1)).subscribe((farm: ResponseModel<FarmModel>): void => {
        this.farmPolygon = {
              points: MapHelper.convertToAGMPolygon(farm.model.coords.coordinates[0]),
              strokeColor: '#a31f1f',
              fillColor: '#de3333',
              isEditable: false,
              data: { isFarm: true }
          }
          this.mapService.mapPolygons.push(this.farmPolygon);
          if(this.equipment) {
          if (this.equipment.farm && (this.equipment.farm === farm.model.id)) {
            this.parkingPlace = {
                points: MapHelper.convertToAGMPolygon(this.equipment.garageArea['coordinates'][0]),
                strokeColor: '#3366ff',
                fillColor: '#3366ff',
                isEditable: true
            };
            this.mapService.mapPolygons.push(this.parkingPlace);
        }
        }

        if(this.newEquipment) {
          setTimeout(() => {
            const innerPolygonCoords = this.generateSquareCoordinatesInsidePolygon(this.farmPolygon);
            console.log(innerPolygonCoords);
            this.parkingPlace = innerPolygonCoords;
            this.mapService.mapPolygons.push(this.parkingPlace);
        }, 1000)
        }
          this.mapService.centerMapOnPolygons();
          this.mapService.showMap();
      });
  }

  generateSquareCoordinatesInsidePolygon(outerPolygonCoords: MapPolygonInterface): MapPolygonInterface {
    const [minLat, maxLat] = [Math.min(...outerPolygonCoords.points.map(coord => coord.lat)), Math.max(...outerPolygonCoords.points.map(coord => coord.lat))];
    const [minLng, maxLng] = [Math.min(...outerPolygonCoords.points.map(coord => coord.lng)), Math.max(...outerPolygonCoords.points.map(coord => coord.lng))];
  
    const centerLat = (minLat + maxLat) / 2;
    const centerLng = (minLng + maxLng) / 2;
  
    const sideLength = Math.min(maxLat - minLat, maxLng - minLng) / 4; // Adjust the divisor as needed
  
    const squareCoordinates = [
      { lat: centerLat - sideLength, lng: centerLng - sideLength },
      { lat: centerLat - sideLength, lng: centerLng + sideLength },
      { lat: centerLat + sideLength, lng: centerLng + sideLength },
      { lat: centerLat + sideLength, lng: centerLng - sideLength },
    ];
  
    const polygon: MapPolygonInterface = {
      points: squareCoordinates,
      fillColor: '#248a38',
      strokeColor: '#33de55',
      isEditable: true,
      data: {}
    };
  
    return polygon;
  }

public productEdit(product) {
  this.product = product
  this.productId = product.id
  console.log(this.productId);
  this.addEditProductModal.showModal();
}

public setProduct(product) {
  console.log(product)
  this.productId = product.value;
  this.getParties();
}

private loadEquipment(): void {
  this.parkingPlace = null;
      if (this.equipment) {
          this.parkingPlace = {
              points: MapHelper.convertToAGMPolygon(this.equipment.garageArea['coordinates'][0]),
              strokeColor: '#3366ff',
              fillColor: '#3366ff',
              isEditable: true
          };
          this.mapService.mapPolygons.push(this.parkingPlace);
          this.farmId = this.equipment.farm;
      } else {
        this.farmId = null
      }
      this.parkingPlace = UtilsHelper.getDeepCopy(this.parkingPlace);
      // this.loadFarm();
}


filterParties(value) {
  console.log(value);
  this.subscription.add(this.partyService.getParties({product:this.productId, search: value}).subscribe(data => {
    console.log(data);
    if(data.body.next) {
      this.getMoreParties = true;
    }
    this.partyToken = data.body.next
          ? this.globalRegistryService.getQueryStringParams(data.body.next.split("?")[1])
          : null;
    this.parties = data.body.results;
    if(this.partyToken) this.getMoreParties = true
  }))
}

public getParties() {
  const url = this.partyService.getUrl('');
  this.cacheService.delete(url+'product='+this.productId+'&limit='+this.limit+'&search='+this.searchKeyword);
  this.subscription.add(this.partyService.getParties({ product: this.productId, limit: this.limit, search: this.searchKeyword }).subscribe((data: ResponseModel<PartyModel[]>): void => {
    console.log(data);
    this.parties = data.body.results;
    this.checkDefaultPartyExistence();
    this.partyToken = data.body.next
          ? this.globalRegistryService.getQueryStringParams(data.body.next.split("?")[1])
          : null;
  }))
}

productDelete(productId) {
  this.stockService.deleteProduct(productId).subscribe(data => {
    this.product = null;
    console.log(data);
    
    this.products = this.products.filter(product => {
      return product.id !== productId
  });
  })
}

public addProductAction(ev) {
  console.log(ev);
  this.product = ev;
  this.addedProduct.emit(ev);
  this.equipmentForm.controls['product'].setValue(ev.id);
  this.products.findIndex((product) => {
      if(product.id === ev.id) {
        return product = ev;
      }
  })
  this.mys.close();
  this.typeFilter.nativeElement.value = ''
  this.stockService.getProducts().subscribe(data => {
    this.products = data.body.results;
    const productExist = data.body.results.some((p) => p.id === ev.id);
    if(!productExist) {
      this.products.unshift(ev);
    }
  })
  this.addEditProductModal.hideModal();
}

public updateProductAction(ev) {
  console.log(ev);
  console.log(this.products);
  this.updatedProduct.emit(ev);
  this.equipmentForm.controls['product'].setValue(ev.id);
  const target = this.products.find((obj) => obj.id === ev.id);
  Object.assign(target, ev);
  this.mys.close();
  this.typeFilter.nativeElement.value = ''
  this.addEditProductModal.hideModal();
}

scrollParties() {
  const url = this.partyService.getUrl('');
  console.log('parties scrolled');
  if(this.getMoreParties) {
  this.partyToken &&
  this.subscription.add(this.partyService.getParties(this.partyToken).subscribe((data) => {
    this.cacheService.delete(url+'limit='+this.partyToken.limit+'&offset='+this.partyToken.offset+'&search='+this.partyToken.search);
      console.log(data);
      if(this.parties) {
        this.parties = [...this.parties, ...data.body.results];
      } else {
        this.parties = data.body.results;
      }
      if(data.body.next == null) {
        this.getMoreParties = false;
        return
      } else {
        const url = data.body.next.split('?')
        const urlParams = new URLSearchParams(url[1]);
        const entries = urlParams.entries();
        const params = this.paramsToObject(entries);
        console.log(params);
        if(this.partyToken.offset != params['offset']) {
        this.partyToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
        } else {
          return
        }
      }
    }));
  } else {
    return
  }
}

scrollBills() {
  const url = this.stockService.getUrl('docs/');
  console.log('docs scrolled');
  if(this.getMoreDocs) {
  this.nextDocToken &&
  this.subscription.add(this.stockService.getDocs(this.nextDocToken).subscribe((data) => {
    this.cacheService.delete(url+'limit='+this.nextDocToken.limit+'&offset='+this.nextDocToken.offset+'&search='+this.nextDocToken.search);
      console.log(data);
      if(this.bills) {
        this.bills = [...this.bills, ...data.model];
      } else {
        this.bills = data.model;
      }
      if(data.body.next == null) {
        this.getMoreDocs = false;
        return
      } else {
        const url = data.body.next.split('?')
        const urlParams = new URLSearchParams(url[1]);
        const entries = urlParams.entries();
        const params = this.paramsToObject(entries);
        console.log(params);
        if(this.nextDocToken.offset != params['offset']) {
        this.nextDocToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
        } else {
          return
        }
      }
    }));
  } else {
    return
  }
}

    public ngOnDestroy():void {
        this.subscriptions.forEach(s=>s.unsubscribe());
        this.subscription.unsubscribe();
        this.widgetService.setSize(6);
        this.farmPolygon = null;
        this.parkingPlace = null;
        this.farmId = null;
        this.mapService.mapPolygons = [];
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'equipment') && this.equipment) {
            this.initForm();
        }
        if(Object.prototype.hasOwnProperty.call(changes, 'isSubmitDisabled') && this.isSubmitDisabled){
            this.navService.submitFarm.next(false);
        }else{
            this.navService.submitFarm.next(true);
        }
    }

    public deleteCurrentEquipment() {
        console.log(this.equipment.id);
        this.deleteEquipment.emit(this.equipment.id);
        setTimeout(()=> {
            this.router.navigate(['/pages/equipments']);
        }, 1000);
    }

    public submitForm(): void {
        console.log(this.equipmentForm.value);
        if (this.equipmentForm.invalid) {
            this.toastr.error(this.translateService.instant('equipment.pleaseFillInMandatoryFields'));
            return;
        }
       
        const equipment: EquipmentInterface = {
            farm: this.equipmentForm.value['farm'],
            product: this.equipmentForm.value['product'],
            workingHours: this.equipmentForm.value['workingHours'],
            manufactureSerial: this.equipmentForm.value['manufactureSerial'],
            inspection: this.inspection,
            registrationPlate: this.equipmentForm.value['registrationPlate'],
            rentedFrom: this.equipmentForm.value['rentedFrom'],
            info: this.equipmentForm.value['info'],
            parked: this.equipmentForm.value['parked'],
            lastActivity: this.activity,
        };

        const equipmentObject: {} = {
            equipment,
        };
        // console.log(equipmentObject);
        // return;
        this.submitEquipment.emit(equipmentObject);
    }

    public getBills() {
      const url = this.stockService.getUrl('docs/');
      this.cacheService.delete(url+'limit='+this.limit+'&search='+this.searchKeyword);
      this.subscription.add(this.stockService.getDocs({ limit: this.limit, search: this.searchKeyword }).subscribe((data): void => {
        console.log(data);
        this.bills = data.model;
        // this.checkDefaultPartyExistence();
        this.nextDocToken = data.body.next
              ? this.globalRegistryService.getQueryStringParams(data.body.next.split("?")[1])
              : null;
      }))
    }

    public imageUploaded(file: File): void {
        this.changedImage = true;
        this.equipmentImage = file;
        this.equipmentHasImage = file ? true : false;
        this.equipmentForm.markAsDirty();
    }

    calcTime(time) {
        return moment.duration(time).asMinutes();
    }

    public enableEdit(): void {
        this.isPreviewMode = false;
        this.enabledEdit.emit(true);
        this.equipmentForm.enable();
    }

    // public resetForm(): void {
    //     this.equipmentForm.setValue(this.initialEquipmentForm);
    //     if (this.changedImage) {
    //         this.equipmentImageUpload.remove();
    //         this.equipmentImageUpload.url = this.initialEquipmentImageUrl;
    //         this.equipmentHasImage = this.initialEquipmentImageUrl ? true : false;
    //         this.changedImage = false;
    //     }
    //     this.equipmentForm.markAsPristine();
    // }
    
    public showProductModal(): void {
      this.productId = -1;
      console.log(this.productId);
      this.addEditProductModal.showModal();
    }
    
    public hideProductModal(): void {
      this.addEditProductModal.hideModal();
    }

    public showAlertPopup() {
      this.productAlertModal.showModal();
    }

      validateAllFormFields(formGroup: UntypedFormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
          const control = formGroup.get(field);             
          if (control instanceof UntypedFormControl) {             
            control.markAsTouched({ onlySelf: true });
          } else if (control instanceof UntypedFormGroup) {        
            this.validateAllFormFields(control);            
          }
        });
      }
      
    goToInvoiceIn() {
      if(this.equipment) {
        this.router.navigate(['/pages/stock/bill/add'], {queryParams: {type: 'invoice_in', from: 'equipment', equipmentId: this.equipment.id, new_doc: 'true', farm: this.farmId}});
      } else {
        this.router.navigate(['/pages/stock/bill/add'], {queryParams: {type: 'invoice_in', from: 'equipment', new_doc: 'true', farm: this.farmId}});
      }
      this.productAlertModal.hideModal();
    }

    goToInternalNote() {
      if(this.equipment) {
        this.router.navigate(['/pages/stock/bill/add'], {queryParams: {type: 'internal_note', from: 'equipment', equipmentId: this.equipment.id, new_doc: 'true', farm: this.farmId}});
      } else {
        this.router.navigate(['/pages/stock/bill/add'], {queryParams: {type: 'internal_note', from: 'equipment', new_doc: 'true', farm: this.farmId}});
      }
      this.productAlertModal.hideModal();
    }

    goToProductionNote() {
      if(this.equipment) {
        this.router.navigate(['/pages/stock/bill/add'], {queryParams: {type: 'production_note', from: 'equipment', equipmentId: this.equipment.id, new_doc: 'true', farm: this.farmId}});
      } else {
        this.router.navigate(['/pages/stock/bill/add'], {queryParams: {type: 'production_note', from: 'equipment', new_doc: 'true', farm: this.farmId}});
      }
      this.productAlertModal.hideModal();
    }

    public editParty(party: PartyModel): void {
        this.addEditPartyModal.showModal();
        this.selectedParty = party;
    }

    public getProducts() {
      const url = this.stockService.getUrl('products/');
      this.cacheService.delete(url+'group=EQUIPMENT&limit='+this.limit+'&search='+this.searchKeyword);
      this.subscription.add(this.stockService.getProducts({ group: 'EQUIPMENT', limit: this.limit, search: this.searchKeyword }).subscribe((data): void => {
        console.log(data);
        this.products = data.body.results;
        this.nextToken = data.body.next
              ? this.globalRegistryService.getQueryStringParams(data.body.next.split("?")[1])
              : null;
          if(this.nextToken) this.getMore = true;
      }))
    }

    onScroll(event: any) {
      if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        this.scrolledDown();
      }
  }

  onScrollDoc(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.scrollBills();
    }
}
  
    paramsToObject(entries) {
      const result = {}
      for(const [key, value] of entries) {
        result[key] = value;
      }
      console.log(result);
      return result;
    }
  
    scrolledDown() {
      // this.getMore = true;
      const url = this.stockService.getUrl('products/');
  // console.log('products scrolled');
  if(this.getMore) {
    this.nextToken &&
  this.subscription.add(this.stockService.getProducts(this.nextToken).subscribe((data) => {
      console.log(data);
      this.cacheService.delete(url+'group=EQUIPMENT&limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
      if(this.products) {
        this.products = [...this.products, ...data.body.results];
        console.log(this.products)
      } else {
        this.products = data.body.results;
      }
      if(data.body.next == null) {
        this.getMore = false; 
        return
      } else {
        const url = data.body.next.split('?')
        const urlParams = new URLSearchParams(url[1]);
        const entries = urlParams.entries();
        const params = this.paramsToObject(entries);
        console.log(params);
        if(this.nextToken.offset != params['offset']) {
        this.nextToken = { group: 'EQUIPMENT', limit: params['limit'], offset: params['offset'], search: params['search']};
        } else {
          return
        }
      }
    }));
  } else {
    return
  }
    }

    filterProduct(value) {
      const url = this.stockService.getUrl('products/');
      this.subscriptions.push(this.stockService.getProducts({search: value, group: "EQUIPMENT"}).subscribe(data => {
        this.cacheService.delete(url+'search='+value+'&group=EQUIPMENT');
        console.log(data);
        this.nextToken = data.body.next
              ? this.globalRegistryService.getQueryStringParams(data.body.next.split("?")[1])
              : null;
        this.products = data.body.results;
      }))
    }

    filterDocs(value) {
      const url = this.stockService.getUrl('docs/');
      this.subscriptions.push(this.stockService.getDocs({search: value}).subscribe(data => {
        this.cacheService.delete(url+'search='+value);
        console.log(data);
        this.nextToken = data.body.next
              ? this.globalRegistryService.getQueryStringParams(data.body.next.split("?")[1])
              : null;
        this.bills = data.model;
      }))
    }

    public addPartyModal(): void {
        this.addEditPartyModal.showModal();
        this.selectedParty = null;
    }

    // public onDeleteParty(party: PartyModel): void {
    //     this.selectedParty = party;
    //     this.deleteParty.emit(this.selectedParty.id);
    //     if (this.equipmentForm.controls['rentedFrom'].value === this.selectedParty.id) {
    //         this.equipmentForm.controls['rentedFrom'].setValue(null);
    //     }
    // }

    public onDeleteRentedFrom(party: PartyModel): void {
        this.partyService.deleteParty(party.id).pipe(take(1)).subscribe(
          (): void => {
            if (this.equipmentForm.controls['rentedFrom'].value === this.selectedParty.id) {
                this.equipmentForm.controls['rentedFrom'].setValue(null);
              }
              this.getParties();
              // this.globalRegistryService.reloadParties();
              this.toastyService.success(this.translateService.instant('people.party.partyDeletedSuccessfully'));
          }, (): void => {
              this.toastyService.success(this.translateService.instant('people.party.errorDeletingParty'));
          }
        );
    }

    public addParty(ev) {
        this.parties.push(ev);
        this.addEditPartyModal.hideModal();
    }

     public addedOrUpdatedParty(ev) {
      console.log('party updated', ev)
        this.addEditPartyModal.hideModal();
    }

    public onDeleteEquipmentType(equipmentType: EquipmentTypeModel): void {
        this.selectedEquipmentType = equipmentType;
        this.deleteEquipmentType.emit(this.selectedEquipmentType.id);
        if (this.equipmentForm.controls['type'].value === this.selectedEquipmentType.id) {
            this.equipmentForm.controls['type'].setValue(null);
        }
    }

    private initForm(): void {
        const formBody: {} = {
            product:   [this.equipment ? this.equipment.product ? this.equipment.product.id : null  : null, Validators.required],
            farm:              [this.equipment ? this.equipment.farm : null, Validators.required],
            inspection:              [this.equipment ? this.equipment.inspection ? this.equipment.inspection.id : null : null],
            manufactureSerial: [this.equipment ? this.equipment.manufactureSerial : null],
            workingHours:      [this.equipment ? this.equipment.workingHours : 0],
            registrationPlate: [this.equipment ? this.equipment.registrationPlate : null],
            rentedFrom:            [this.equipment ? this.equipment.rentedFrom : null, Validators.required],
            parked:            [this.equipment ? this.equipment.parked : false],
            lastActivity:        [this.equipment ? this.equipment.lastActivity ? this.equipment.lastActivity.id : null : null],
        };
        if(this.equipment) {
          // this.farmId = this.equipment.farm;
            formBody['id'] = this.equipment.id;
            this.changeFarm(this.equipment.farm)
            this.selectedEquipment = this.equipment.product.id;
            this.selectedPartyId = this.equipment.rentedFrom;
            if(this.equipment.bill) {
              this.docId = this.equipment.bill.id;
            }
            
        }
        this.equipmentForm = this.formBuilder.group(formBody);
      
        // this.initialEquipmentForm = this.equipmentForm.value;
        // in preview mode we should not be able to edit or submit the form
        this.isPreviewMode ? this.equipmentForm.disable() : this.equipmentForm.enable();

        this.equipmentForm.valueChanges.subscribe(()=>{
            if(this.equipmentForm.invalid){
                this.navService.invalidForm.next(false);
            }else{
                this.navService.invalidForm.next(true);
            }
        })

        // if(!this.equipment) {
          // this.equipmentForm.get('farm').setValue(this.farms[0].id)
          // this.changeFarm(this.farms[0].id);
          // setTimeout(() => {
      //     let innerPolygonCoords = this.generateSquareCoordinatesInsidePolygon(this.farmPolygon);
      //     console.log(innerPolygonCoords);
      //     this.parkingPlace = innerPolygonCoords;
      //     this.mapService.mapPolygons.push(this.parkingPlace);
      // }, 2000)
        // }
    }

    public checkDefaultPartyExistence() {
        const INpartyFromPresence = this.parties.some(p => p.id == this.selectedPartyId)
        if(!INpartyFromPresence && this.selectedPartyId) {
          this.subscription.add(this.partyService.getParty(this.selectedPartyId).subscribe(data => {
            this.parties.unshift(data.body.results);
          }))
        }
    }

    public checkDefaultDocExistence() {
      const docPresence = this.parties.some(p => p.id == this.docId)
      if(!docPresence) {
        this.subscription.add(this.stockService.getDoc(this.docId).subscribe(data => {
          this.bills.unshift(data.model);
        }))
      }
  }

    public verifyProductPresence() {
        const productPresence = this.products.some(p => p.id == this.selectedEquipment)
        if(!productPresence) {
          this.subscription.add(this.stockService.getProduct(this.selectedEquipment).subscribe(data => {
            this.products.unshift(data.body.results);
          }))
        }
    }

    public changeFarm(farm) {
        this.mapService.resetMap();
        console.log(farm);
        this.farmId = farm;
        this.productId = null;
        this.products = [];
        this.parties = [];
        this.equipmentForm.get('rentedFrom').reset();
        this.equipmentForm.get('product').reset();
        this.getEquipmentProducts()
        this.loadFarm();
        this.loadEquipment();
        this.mapService.mapPolygons = [];
    }

    public getEquipmentProducts() {
      const params = {
        group: 'EQUIPMENT',
        status: 'not_empty',
        locations: this.farmId
      }
      const url = this.stockService.getUrl('stock_items/');
      this.cacheService.delete(url+'group=EQUIPMENT&status=not_empty&locations='+this.farmId);
      this.stockService.getStockItemProducts(params).subscribe(data => {
        this.products = data.model;
        if(this.products.length === 0) {
          this.showProdError = true
        } else {
          this.showProdError = false;
          this.productId = data.model[0].id
          this.getParties()
        }
      })
    }

    public changeProduct(product) {
        console.log(product);
    }

    hideModal() {
        const elem = document.getElementById('custom_modal');
        const parent = document.getElementById('parent');
        elem.classList.remove('active');
        parent.classList.remove('backdrop');
    }

    getTranslations() {
        setTimeout(()=> {
            this.subscription.add(this.typesService.getTranslations().subscribe(data => {
                this.translatedNames = data.model;
                return
            }))
        }, 1000) 
    }

    getTranslation(translation) {
        const t =this.translatedNames.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
          if(t[0][this.langString] === null || t[0][this.langString] === '') {
            return translation
          } else {
            return t[0][this.langString];
          }
        } else {
            return translation
          }
      }

      public updateAttributes() {
        if(this.formType && this.formType.id) {
            this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
                console.log(data);
            })
        }
    }

}
