<fap-header>
    <div class="d-flex align-items-center">
        <div class="tree_combo mr-3">
            <!-- <fap-tree-combo></fap-tree-combo> -->
          </div>
          <div class="add_circle mr-2">
            <mat-form-field>
                <mat-label style="top: -2px; position: relative;">{{'attribute.entityTypes' | translate}}</mat-label>
                <mat-select #mys panelClass="prevent-enter-close" disableOptionCentering="true" name="entityTypes" [placeholder]="'attribute.entityTypes' | translate" (selectionChange)="filterEntityData($event)">
                    <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                    <mat-form-field appearance="fill" class="w-100">
                        <input matInput #entityTypesFilter [placeholder]="'attribute.entityTypes' | translate" (keydown.enter)="$event.stopPropagation()">
                    </mat-form-field>
                    <mat-label style="position: absolute; right: 65px; cursor: pointer;">
                        <i class="fa fa-search f-20"></i>
                    </mat-label>
                    <div class="input-group-append pl-1">
                        <span class="input-group-text">
                            <i class="icofont icofont icofont-plus" (click)="addEntityType(); mys.close();"></i>
                         </span>
                    </div>
                </div>
                <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option class="noteIcons" *ngFor="let type of entityTypes | filter : entityTypesFilter.value" [value]="type.id">
                        <span class="mat-option-text">
                        <div class="form_type_name name" [attr.data-formtype]="type?.uniqueid">
                            {{ type?.name }}
                        </div>
                        <div class="actions">
                            <i class="fa fa-edit center f-20" (click)="editEntityType(type);"></i>
                            <i class="fa fa-trash center f-20 ml-1" (click)="deleteEntityType(type); $event.stopPropagation()"></i>
                        </div>
                        </span>
                    </mat-option>
                </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="add_btn mr-2">
            <fap-round-button [backgroundColor]="'tertiary'" (clicked)="addNewEntity()"></fap-round-button>
        </div>
        <div class="add_circle mr-3" (click)="showConfigModal()">
            <div class="rounded-circle r config"></div>
        </div>
        <fap-search-filter
            [isSearchEnabled]="true"
>       </fap-search-filter>
    </div>
</fap-header>
<div class="entities_container">
    <div class="loader_container">
        <span *ngIf="isLoading" class="loader">
            <i></i>
          </span>
    </div>

    <div class="entities_page" *ngIf="!isLoading" #deviceContainer>
        <div class="table_wrapper">
            <table>
                <thead>
                    <tr>
                        <fap-table-header [tableHeaderLabels]="tableHeaderLabels" [columns]="entityColumns" [itemList]="itemList"></fap-table-header>
                        <!-- <th></th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of entityData">
                        <fap-table-column [data]="data" [entityTypesMap]="entityTypesMap" [allEntityColumnsMap]="allEntityColumnsMap" (selectItem)="editForm($event)" [langString]="langString"></fap-table-column>
                        <!-- <td>delete</td> -->
                    </tr>
                </tbody>
            </table>
        </div>
        <fap-pagination *ngIf="count > limit"
            [count]="count"
            [pageSize]="limit"
            (emitNextPrev)="handlePagination($event)"
        ></fap-pagination>
    </div>
</div>

<fap-modal
    [inputIsLarge]="true"
    [showFooter]="false"
    #configModal
    [titleColor]="'#ffffff'"
    inputTitle="Config"
>
    <div class="config_block clearfix">
        <form [formGroup]="profileForm" class="row">
            <div class="col-md-12">
                <fap-add-edit-delete-select 
                    [inputName]="'attribute.profile' | translate"
                    class="w-100"
                    [formControl]="profileForm.controls['sensorGroup']"
                    name="profile"
                    [inputIsRequired]="false"
                    [apiSearchEnabled]="false"
                    [inputValueFieldName]="'id'"
                    [inputNameFieldName]="'name'"
                    [inputNameTFieldName]="'nameT'"
                    [inputSelectValues]="profiles"
                    [inputConfirmDeleteText]="'sensor.deleteProfileMessage' | translate"
                    (selectionChange)="profileForm.get('sensorGroup')?.setValue($event); profileId = $event; changeGroup()"
                    (outputOnCreate)="addProfile(); closeProfileCombo = false;"
                    (outputOnEdit)="editProfile($event); closeProfileCombo = false;"
                    (outputOnDelete)="deleteProfile($event);"
                    [closeCombo]="closeProfileCombo"
                    >
                </fap-add-edit-delete-select>
            </div>
            <div class="col-md-12 mb-3 pos_rel">
                <label class="col-form-label float">{{ 'Add items' }}</label>
                <div class="add_btn">
                    <fap-round-button (click)="openFieldsModal()"></fap-round-button>
                </div> 
            </div>
          </form>                   

        <div class="form-group mt-3" *ngIf="selectedFields.length > 0">
           <fap-generic-modal class="selected_items_table"
                [headerFields]="['id', 'name', 'value type', 'input type']"
                [displayFields]="['id', 'name', 'value_type', 'input_type']"
                [tableHeight]="'150px'"
                [items]="selectedFields"
                [addEnabled]="false"
                [isEditable]="false"
                [addEnabled]="false"
                [showFilters]="false"
                [isDeleteable]="true"
                [setCustomHeight]="false"
                (outputOnDelete)="removeItem($event.id)"
            ></fap-generic-modal>
        </div>
        <div class="btn-group mt-3 d-flex justify-content-end">
            <button class="btn mr-2" (click)="addColumns()">{{ 'translations.save' | translate }}</button>
            <button class="btn" (click)="configModal.hideModal()">{{ 'translations.cancel' | translate }}</button>
        </div>
    </div>
</fap-modal>

<fap-modal #entityFormModal
[inputTitle]="formType?.name" [inputIsLarge]="true" [showFooter]="false">
<div class="minus_margin">
    <fap-attributes
        [entityRelation]="'entities'"
        [entityId]="formType?.id"
        [entityInfo]="formType"
        [form]="form"
        [objectId]="0"
        (closeModal)="hideModal()"
        (formUpdate)="onUpdateAction($event)"
        [isDynamic]="true">
    </fap-attributes>
</div>
</fap-modal>

<fap-modal #addTypeModal [inputTitle]="'sensor.addEditEntityType' | translate">
    <form [formGroup]="entityTypeForm" class="col-12 p-0">
        <div class="form-group m-0">
            <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name', 'nameInput2', entityType, addEditTranslationsModal, 'entityTypeForm')">
                <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                <input matInput formControlName="name" readonly #nameInput2>
                <mat-error *ngIf="entityTypeForm.controls['name'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group m-0">
            <fap-add-edit-delete-select
                inputName="{{ 'stock.formType' | translate }}"
                class="w-100"
                [formControl]="entityTypeForm.controls['entity_form']"
                name="formType"
                [inputIsRequired]="true"
                [apiSearchEnabled]="true"
                [inputValueFieldName]="'id'"
                [inputNameFieldName]="'name'"
                [inputNameTFieldName]="'nameT'"
                [inputSelectValues]="formTypes"
                [closeCombo]="closeTypeCombo"
                [isEditable]="false"
                [isDeleteable]="false"
                [createEnabled]="false"
                (clicked)="setFormType($event)"
            >
            </fap-add-edit-delete-select>
        </div>
        <div class="form-group m-0">
            <mat-form-field class="w-100">
                <mat-label>{{ 'Unique ID' }}</mat-label>
                <input matInput placeholder="Unique ID" formControlName="uniqueid">
              </mat-form-field>
        </div>
        <div class="form-group m-0">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'notes.color' | translate }}</mat-label>
                <input class="w-100" matInput type="color" formControlName="color">
                <mat-error *ngIf="entityTypeForm.controls['color'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="btn-group mt-3 d-flex justify-content-end">
            <button class="btn mr-2" (click)="updateForm()">{{ 'translations.save' | translate }}</button>
            <button class="btn" (click)="addTypeModal.hideModal()">{{ 'translations.cancel' | translate }}</button>
        </div>
    </form>
</fap-modal>

<fap-modal #addEditProfileModal [inputTitle]="'sensor.addEditProfile' | translate">
    <form [formGroup]="addEditProfileForm" class="col-12 p-0">
        <div class="form-group m-0">
            <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name', 'nameInput', profile, addEditProfileTranslationPopup, 'addEditProfileForm')">
                <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                <input matInput formControlName="name" readonly #nameInput>
                <mat-error *ngIf="addEditProfileForm.controls['name'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group m-0">
            <fap-add-edit-delete-select
                inputName="{{ 'stock.formType' | translate }}"
                class="w-100"
                [formControl]="addEditProfileForm.controls['entity_form']"
                name="formType"
                [inputIsRequired]="true"
                [apiSearchEnabled]="true"
                [inputValueFieldName]="'id'"
                [inputNameFieldName]="'name'"
                [inputNameTFieldName]="'nameT'"
                [inputSelectValues]="formTypes"
                [isEditable]="false"
                [isDeleteable]="false"
                [createEnabled]="false"
                (clicked)="addEditProfileForm.get('entity_form').setValue($event.id)"
            >
            </fap-add-edit-delete-select>
        </div>
        <div class="form-group m-0">
            <mat-form-field class="w-100">
                <mat-label>{{ 'Unique ID' }}</mat-label>
                <input matInput placeholder="Unique ID" formControlName="uniqueid">
              </mat-form-field>
        </div>
        <div class="form-group m-0">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'notes.color' | translate }}</mat-label>
                <input class="w-100" matInput type="color" formControlName="color">
                <mat-error *ngIf="addEditProfileForm.controls['color'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="btn-group mt-3 d-flex justify-content-end">
            <button class="btn mr-2" (click)="sensorGroupTypeSubmit()">{{ 'translations.save' | translate }}</button>
            <button class="btn" (click)="addEditProfileModal.hideModal()">{{ 'translations.cancel' | translate }}</button>
        </div>
    </form>
</fap-modal>

<fap-modal #fieldsModal [inputIsLarge]="true"
[inputTitle]="'Fields' | translate" [showFooter]="false">
    <fap-generic-modal class="fields_table"
            [headerFields]="['id', 'name', 'value type', 'input type']"
            [displayFields]="['id', 'name', 'value_type', 'input_type']"
            [items]="filteredFields"
            [selectedIds]="selectedFieldsIds"
            [enableActions]="false"
            [isDeleteable]="false"
            [isEditable]="false"
            [addEnabled]="false"
            (outputOnSearch)="filterEntities($event)"
            (closeModal)="fieldsModal.hideModal()"
            [showFilters]="true"
            [multiple]="true"
            (outputOnSelectItems)="collectValues($event)"
    ></fap-generic-modal>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.entityType)" 
        [prefix]="'TYPES_'" 
        [translation]="translation"
        [label]="label"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditProfileTranslationPopup [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.profile)" 
        [prefix]="'PROFILE_'" 
        [translation]="translation"
        [label]="label"
        [group]="'types'"
        (submitTranslation)="onAddUpdateProfileTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>