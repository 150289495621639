import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { GenericWebSocketService } from './generic-websocket.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationManagerService {
  private registeredResources = new Map<number, any>(); // Map for direct access by ID
  private resourceUpdates = new Subject<any>();
  private batchUpdates = new Subject<any[]>();
  private sessionExpired$ = new BehaviorSubject<boolean>(false);
  private socketSubscription: Subscription;

  constructor(private websocketService: GenericWebSocketService) {}

  public onResourceUpdate(): Observable<any[]> {
    return this.batchUpdates.asObservable();
  }

  // Register a resource to listen for updates
  registerResource(resource: any): void {
    if (!resource || !resource.id) return;
  
    if (!this.registeredResources.has(resource.id)) {
      this.registeredResources.set(resource.id, resource);
      this.websocketService.sendMessage(resource); // Notify WebSocket
      console.log('Registered resource:', resource);
    } else {
      console.warn(`Resource with ID ${resource.id} is already registered.`);
    }
  }  

  // Unregister a resource
  unregisterResource(id: number): void {
    if (this.registeredResources.has(id)) {
      const resource = this.registeredResources.get(id);
  
      console.log('Unregistering resource:', resource);
  
      // Remove from `Map`
      this.registeredResources.delete(id);
  
      // Notify WebSocket
      this.websocketService.sendMessage({
        id: resource.id,
        type: 'DEREGISTER_OBJ_NOTIF',
        method: 'REQ',
        params: resource.params,
      });
  
      console.log('Updated registered resources:', this.registeredResources);
    } else {
      console.warn(`Resource with ID ${id} not found in registeredResources.`);
    }
  }  

  // Listen for WebSocket notifications
  startListening(): void {
    this.socketSubscription = this.websocketService.onMessage().subscribe({
      next: (message) => {
        console.log('Received WebSocket message:', message);
  
        if (
          message?.params?.data?.id
        ) {
          this.resourceUpdates.next({
            id: message.params.data.id.toString(),
            data: message,
            method: message.method,
            type: message.type,
            operation: message.params.operation
          });
        }
      },
      error: (err) => console.error('WebSocket error:', err),
      complete: () => console.log('WebSocket connection closed'),
    });
  
    // Batch notifications
    this.resourceUpdates
      .pipe(
        debounceTime(100),
        distinctUntilChanged(
          (prev, curr) =>
            prev.id === curr.id &&
            JSON.stringify(prev.data) === JSON.stringify(curr.data)
        )
      )
      .subscribe((update) => {
        console.log('Processed individual update:', update);
        this.batchUpdates.next([update]);
      });
  
    this.batchUpdates.pipe(debounceTime(500)).subscribe((batch) => {
      console.log('Processed batch update:', batch);
      this.handleBatchUpdates(batch);
    });
  }
  
  // Stop listening (e.g., on session expiration or dashboard exit)
  stopListening(): void {
    this.socketSubscription?.unsubscribe();
    this.registeredResources.forEach((_, id) => this.unregisterResource(id));
    this.registeredResources.clear();
    this.websocketService.closeConnection();
  }  

  // Handle batched updates
  private handleBatchUpdates(batch: any[]) {
    batch.forEach((update) => {
      // Perform operations for each update
      console.log('Processing update for:', update.id, update.data);
    });
  }

  // Handle session expiration
  onSessionExpired(): void {
    this.sessionExpired$.next(true);
    this.stopListening();
  }
}
