import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { ToastrService } from 'ngx-toastr';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { FapModalComponent } from '../../fap-modal/fap-modal.component';

@Component({
  selector: 'fap-scheduler-trigger',
  templateUrl: './fap-scheduler-trigger.component.html',
  styleUrls: ['./fap-scheduler-trigger.component.scss']
})
export class FapSchedulerTriggerComponent implements OnChanges {

  @Input() formTypeId: any = null;
  @Input() sensorId: any = null;
  @Output() updatedForm: EventEmitter<any> = new EventEmitter<any>();
  public formType = null;
  public entityInfo = null;
  public formFields = [];
  public triggers = [];
  public schedulers = [];
  public trigger = null;
  public triggerId = null;
  public isLoading = true;
  public collapsed = true;
  @ViewChild('triggerModal') triggerModal: FapModalComponent;

  constructor(private attributeService: AttributeService, private cacheService: CacheResolverService, public globalRegistry: GlobalRegistryService, public typesService: TypesService, public toastrService: ToastrService, public sensorService: SensorService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('formTypeId') && this.formTypeId) {
      console.log(this.formTypeId);
      this.getFormTypes(this.formTypeId);
    }

    if(changes.hasOwnProperty('sensorId') && this.sensorId) {
      this.getTriggers(this.sensorId);
    }
  }

  getMatchingScheduler(trigger: any): any | undefined {
    if (!trigger || !this.schedulers) {
        return undefined;
    }
    const matchingScheduler = this.schedulers.find(scheduler => scheduler.id === trigger.scheduler);
    return matchingScheduler;
}

  public getFormTypes(formTypeId) {
        this.getFormTypeById(formTypeId);
    }

    public getFormTypeById(typeId: number) {
      // const url = this.typesService.getUrl('form/');
      // this.cacheService.delete(url+typeId);
      this.typesService.getFormTypeById(typeId).subscribe(data => {
        console.log(data);
        this.entityInfo = data.body.results;
        this.isLoading = false;
      })
    }

    updateForm() {
      this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
        console.log(data);
        this.updatedForm.emit(data.body.results);
    })
    }

    getTriggers(sensorId: number) {
      const param = {sensor: sensorId}
      const url = this.sensorService.getUrl('trigger/');
      this.cacheService.delete(url+'sensor='+sensorId);
      this.schedulers = [];
      this.sensorService.getTriggers(param).subscribe((data) => {
        console.log(data);
        this.triggers = data.body.results;
        this.triggers.forEach(trigger => {
          this.getScheduler(trigger.scheduler);
        });
        console.log(this.triggers);
      });
  }

  getScheduler(schedulerId: number) {
    let url = this.sensorService.getUrl('scheduler/');
    this.cacheService.delete(url + schedulerId + '/');
    let scheduler = null;
    this.sensorService.getScheduler(schedulerId).subscribe(data => {
      console.log(data);
      scheduler = data.body.results;
      this.schedulers.push(data.body.results);
    })
    return scheduler;
  }

  addTrigger(trigger) {
    this.triggers.unshift(trigger);
    this.triggerModal.hideModal();
  }

  updateTrigger(updatedTrigger: any) {
    const existingTriggerIndex = this.triggers.findIndex(trigger => trigger.id === updatedTrigger.id);
    if (existingTriggerIndex !== -1) {
      this.triggers[existingTriggerIndex] = updatedTrigger;
    } else {
      this.triggers.unshift(updatedTrigger);
    }
    this.triggerModal.hideModal();
  }  

  addScheduler(scheduler) {
    this.schedulers.unshift(scheduler);
    this.triggerModal.hideModal();
  }

  updateScheduler(scheduler) {
    const index = this.schedulers.findIndex(scheduler => scheduler.id === scheduler.id);
    if (index !== -1) {
      this.schedulers[index] = scheduler;
    }
  }

  getSchedulers() {
    // const url = this.sensorService.getUrl('scheduler/');
    // this.cacheService.delete(url);
    this.sensorService.getSchedulers().subscribe((data) => {
      console.log(data);
      this.schedulers = data.body.results;
    });
  }

  createNewTrigger() {
    this.sensorService.postTrigger({sensor: this.sensorId}).subscribe(data => {
      console.log(data);
      if(data) {
        this.trigger = data.results;
        this.triggerId = data.results.id;
        this.triggerModal.showModal();
      }
    })
  }

  addEditTrigger(trigger?) {
    if(trigger) { 
      this.trigger = trigger;
      this.triggerId = trigger.id;
    } else {
      this.trigger = null;
      this.triggerId = 0;
    }
    this.triggerModal.showModal();
  }

  deleteTrigger(triggerId: number) {
    const index = this.triggers.findIndex(trigger => trigger.id === triggerId);
    if (index !== -1) {
      this.sensorService.deleteTrigger(triggerId).subscribe(() => {
        this.triggers.splice(index, 1);
        this.toastrService.success('Trigger deleted successfully');
      }, (error) => {
        this.toastrService.error(error.error.results.error);
      });
    }
  }

}
