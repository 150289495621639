import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {
    TranslateModule,
    TranslateLoader,
    TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { ROUTES } from './app.routing';
import { NotFoundComponent } from './views/pages/not-found/not-found.component';
import { FapServicesModule } from './core/services/fap-services.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalRegistryService } from './core/global-registry/global-registry.service';
import { APP_GUARDS } from './core/guard/guards.data';
import { AgmCoreModule } from '@agm/core';
import { MapHelper } from './core/heplers/map.helper';
import { PagesModule } from './views/pages/pages.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgApexchartsModule } from 'ng-apexcharts';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatTableExporterModule } from 'mat-table-exporter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from './core/services/websocket/data.service';
import { OverviewService } from './core/services/api/overview/overview.service';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { CacheResolverService } from './core/services/api/cache/cache-resolver.service';
import { CacheInterceptor } from './core/interceptors/cache-interceptor';
import localeEn from '@angular/common/locales/en';
import { LocaleService } from './core/services/api/locale/locale.service';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { DefaultIntl } from './shared/utils/date-time-intl';
import { NotificationManagerService } from './core/services/notification/notification-manager.service';
registerLocaleData(localeEn)

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent
    ],
    imports: [
        MatTableExporterModule,
        MatTableModule,
        NgApexchartsModule,
        ColorPickerModule,
        BrowserModule,
        CommonModule,
        SharedModule,
        ToastrModule.forRoot(),
        RouterModule.forRoot(ROUTES),
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        }),
        AgmCoreModule.forRoot({
            apiKey: MapHelper.GOOGLE_API_KEY,
            libraries: ['drawing', 'places']
        }),
        FapServicesModule.forRoot(),
        PagesModule,
        NgxPermissionsModule.forRoot(),
        InfiniteScrollModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxNativeDateModule,
        NgxMatMomentModule,
        FormsModule,
        ReactiveFormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        // NgMultiSelectDropDownModule.forRoot(),
    ],
    providers: [
        APP_GUARDS,
        GlobalRegistryService,
        TranslateService,
        DataService,
        OverviewService,
        CacheResolverService,
        NotificationManagerService,
        { provide: LOCALE_ID, useFactory: (localeService: LocaleService) => localeService.locale, deps: [LocaleService] },
    LocaleService,
        {
        provide: HTTP_INTERCEPTORS,
        useClass: CacheInterceptor,
        multi: true,
        },
        {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
        {provide: LOCALE_ID, useValue: "en-US"},
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' },
        { provide: OwlDateTimeIntl, useClass: DefaultIntl } // Use the custom Intl
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
