import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { NavService } from '../../../../shared/services/nav.service';
import { FapModalComponent } from '../../../../shared/partials';
import { Subscription } from 'rxjs';
import { StockService } from '../../../../core/services/api/stock/stock.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { CompanyService } from '../../../../core/services/api/company/company.service';
import { ToastrService } from 'ngx-toastr';
import { PlanModel } from '../../../../core/models/company/plan.model';
import moment from 'moment';
import { PlanTypeModel } from '../../../../core/models/company/plan.type.model';
import { FapModalButtonInterface } from '../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { TranslateService } from '@ngx-translate/core';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { ConfirmModalService } from '../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { AttributeService } from '../../../../core/services/api/attribute/attribute.service';

export const DATE_TIME_FORMAT = {
  parse: {
    dateInput: 'l, LTS',
  },
  display: {
    dateInput: 'MMM DD YYYY',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
}

@Component({
  selector: 'fap-add-edit-plan',
  templateUrl: './fap-add-edit-plan.component.html',
  styleUrls: ['./fap-add-edit-plan.component.scss'],
  providers: [{provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT}, {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FapAddEditPlanComponent),
    multi: true
  }]
})
export class FapAddEditPlanComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public plan: PlanModel = null;
  @Input() public planId: number = null;
  @Input() public planTypes: Array<PlanTypeModel> = [];

  @Output() public closeModal: EventEmitter<any> = new EventEmitter();
  @Output() public addPlan: EventEmitter<any> = new EventEmitter();
  @Output() public updatePlan: EventEmitter<any> = new EventEmitter();

  public planForm: FormGroup;
  public label: string = '';
  public translation: any = null;
  public langString: string = 'en';
  public subscriptions: Subscription[] = [];
  public selectedItems = [];
  public resourcesDoc;
  public minDate;
  public maxDate;
  public currency;
  public closeCombo: boolean = true;
  public nextDocToken: { offset: number; limit: number, search?: string } = null;
  public limit = 20;
  public searchKeyword: string = '';
  public planTypeForm: FormGroup;
  public planType: PlanTypeModel;
  public planTypeId: number;
  @Input() public formTypes = [];
  public form = null;
  public objectId = null;
  public entityInfo = null;
  public formFields;
  public addEditPlanTypeModalButtonPrimaryInterface: FapModalButtonInterface;
  public addEditPlanTypeModalButtonSecondaryInterface: FapModalButtonInterface;
  public planTypeImages: any = [];
  public resources: any = [];

  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  @ViewChild('nameInput1', { static: true }) nameInput1: ElementRef;
  @ViewChild('addEditPlanTranslationPopup') public addEditPlanTranslationPopup: FapModalComponent;
  @ViewChild('addEditPlanTypeModal') public addEditPlanTypeModal: FapModalComponent;
  @ViewChild('addEditTranslationsModal') public addEditTranslationsModal: FapModalComponent;

  constructor(private formBuilder: FormBuilder, public globalRegistry: GlobalRegistryService, private navService: NavService, private stockService: StockService, public cacheService: CacheResolverService, public companyService: CompanyService, public toastr: ToastrService, public translateService: TranslateService, public typesService: TypesService, private confirmModalService: ConfirmModalService, private attributeService: AttributeService) {}

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
    this.initPlanForm();
    this.initPlanTypeForm();
    this.initAddEditPlanTypeModalButtons();
    // this.getFormTypes();
  }

  // public getFormTypes() {
  //   const url = this.typesService.getUrl('form/');
  //   this.cacheService.delete(url);
  //   this.typesService.getFormTypes().subscribe((data) => {
  //       this.formTypes = data.body.results;
  //       console.log(data.body.results);
  //   });
  // }

  setFormType(formType) {
    this.planTypeForm.get('entity_form').setValue(formType.id);
    if(this.planType.entityForm === formType.id || this.planType.entityForm == null) {
        this.getFormTypeById(formType.id);
    } else {
        this.confirmModalService.openConfirmModal(
            this.translateService.instant('forms.changeFormType'),
            this.translateService.instant('forms.changeFormTypeMessage'));
            this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
                if (confirmed) {
                    if(formType.id) {
                        this.objectId = formType.id;
                        this.planTypeForm.get('entity_form').setValue(formType.id);
                        this.getFormTypeById(formType.id);
                    }
                }
            }
        );
    }
  }

  verifyFormType(noteType: PlanTypeModel) {    
    if(noteType.entityForm) {
        this.confirmModalService.openConfirmModal(
        this.translateService.instant('forms.changeFormType'),
        this.translateService.instant('forms.changeFormTypeMessage'));
        this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
            if (confirmed) {
                if(noteType.entityForm) {
                    this.objectId = noteType.entityForm;
                    this.planTypeForm.get('type').setValue(noteType.id);
                    this.getFormTypeById(noteType.entityForm)
                }
            }
        });
    } else {
        this.entityInfo = null;
        this.objectId = null;
    }
  }

  public getFormTypeById(typeId: number) {
    if(!typeId) return;
    this.entityInfo = null;
    this.formFields = null;
    const url = this.typesService.getUrl('form/');
    this.cacheService.delete(url+typeId);
    this.typesService.getFormTypeById(typeId).subscribe(data => {
      console.log(data);
      this.entityInfo = data.body.results;
    })
  }

  public initAddEditPlanTypeModalButtons(): void {
    const _this: FapAddEditPlanComponent = this;

    this.addEditPlanTypeModalButtonPrimaryInterface = {
        clickFunction(): void {
            _this.planTypeSubmit();
        },
        text: this.translateService.instant('save'),
    };

    this.addEditPlanTypeModalButtonSecondaryInterface = {
        clickFunction(): void {
            _this.addEditPlanTypeModal.hideModal();
        },
        text: this.translateService.instant('cancel'),
    };
}

  public initPlanTypeForm() {
    this.planTypeForm = new FormGroup({
        name: new FormControl('', Validators.required),
        name_t: new FormControl(''),
        entity_form: new FormControl(''),
        icon: new FormControl(null),
        color: new FormControl('#ff0000')
    });
}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('planId') && this.planId === 0) {
      this.plan = null;
      this.planType = null;
      this.form = null;
      this.entityInfo = null;
      this.formFields = null;
      this.planForm.reset();
      this.planForm.clearValidators();
      this.initPlanForm();
    }
    if(changes.hasOwnProperty('plan') && this.plan && this.planId !== 0) {
      this.initPlanForm();
      this.getPlanType(this.plan.type);
      this.getPlanCost(this.plan.id);
    }
  }

  getPlanType(planTypeId: number) {
    this.planTypes.forEach(planType => {
      if(planType.id === planTypeId) {
        this.planType = planType;
        planTypeId = planType.id;
        this.getFormTypeById(planType.entityForm);
      }
    });
  }

  initPlanForm() {
    const formBody = {
      name: [null, Validators.compose([Validators.required])],
      name_t: [this.plan ? this.plan.nameT : null, Validators.compose([Validators.required])],
      lots: [this.plan ? this.plan.lots : []],
      start: [this.plan ? this.plan.start : null],
      end: [this.plan ? this.plan.end : null],
      progress: [this.plan ? this.plan.progress : null],
      type: [this.plan ? this.plan.type : null]
    }
    this.planForm = this.formBuilder.group(formBody);
    if(this.plan) {
      this.getPlanCost(this.plan.id);
      if(this.plan['nameT']) {
        const translation = this.getTranslation(this.plan['nameT']);
        this.planForm.get('name').setValue(translation);
      } else {
        this.planForm.reset();
      }
    } else {
      this.planForm.reset();
    }
  }

  public getPlanCost(planId: number) {
    this.companyService.getPlanCost(planId).subscribe(data => {
      console.log(data);
      this.resources = data.body.results;
    })
  }

  public ifControlHasError(controlName: string, validationType: string): boolean {
    const control: any = this.planForm.controls[controlName];
    if (!control) {
        return false;
    }

    const result: boolean =
        control.hasError(validationType) &&
        (control.dirty || control.touched);
    return result;
}

  public getDocDetails(id) {
    this.subscriptions.push(this.stockService.getDoc(id).subscribe(data => {
        this.resourcesDoc = data.body.results;
        console.log(data.body.results);
        this.currency = data.body.results.currency;
    }))
}

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  updateItems(lots) {
    this.planForm.get('lots').setValue(lots);
    this.selectedItems = lots;
  }

  public minValueChanged(ev) {
    this.minDate = ev.target.value;
    localStorage.setItem('started', ev.target.value);
}

public maxValueChanged(ev) {
    this.maxDate = ev.target.value;
    localStorage.setItem('ended', ev.target.value);
}

addEditTranslation(label: string, inputElement: any, translationTarget: any, popup: any, form: any) {
  this.label = label;
  const name = this[inputElement].nativeElement.value;
  const type: any = this.convertKeysToCamelCase(translationTarget);
  console.log(name);
  console.log(type);
  if (name === null || name === '' || type.nameT === null) {
      this.translation = null;
      popup.showModal();
  } else {
      for (const translation of this.globalRegistry.systemData.translations) {
          if (type && translation.id === type.nameT) {
              this.translation = translation;
              console.log(this.translation);
              popup.showModal();
              return;
          }
      }

      if (Object.keys(type).length === 0 && name) {
          const newTranslation = this[form].controls['name_t'].value;
          for (const translation of this.globalRegistry.systemData.translations) {
              if (translation.id === newTranslation) {
                  this.translation = translation;
                  console.log(this.translation);
                  popup.showModal();
                  return;
              }
          }
      }
  }
}       

convertKeysToCamelCase(obj) {
  const camelCaseObj = {};
  for (const key in obj) {
    const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
    camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
  }
  return camelCaseObj;
}

  onAddUpdatePlanTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.planForm.controls['name_t'].setValue(
        translation.id
    );
    this.planForm.controls['name'].setValue(
        translation[this.langString]
    );
    this.translation = translation;
    this.addEditPlanTranslationPopup.hideModal();
  }

  formatDate(date: Date | string): string {
    return moment(date).format('YYYY-MM-DD');
  }

  planTypeEdit(planType: PlanTypeModel) {
    this.planTypeId = planType.id;
    this.planType = planType;
    this.planTypeImages = planType.icon ? [planType.icon.id] : [];
    console.log(planType)
    this.planTypeForm.controls['name'].setValue(planType.name);
    this.planTypeForm.controls['name_t'].setValue(planType.nameT);
    this.planTypeForm.controls['color'].setValue(planType.color);
    // this.planTypeForm.controls['icon'].setValue(planType.icon);
    this.planTypeForm.controls['entity_form'].setValue(planType.entityForm);
    this.addEditPlanTypeModal.showModal();
  }

  planTypeDelete(planType: any) {
    console.log(planType)
    this.typesService.deletePlanType(planType.id).subscribe(() => {
      this.planTypes = this.planTypes.filter(planType => planType.id !== planType.id);
    }, (err) => {
      this.toastr.error(err.error.results.error);
    })
  }

  showPlanTypeModal() {
    this.planType = null;
    this.planTypeId = null;
    this.planTypeForm.reset();
    this.planTypeForm.clearValidators();
    this.initPlanTypeForm();
    this.addEditPlanTypeModal.showModal();
  }

  submitPlan() {
   const body = {
    nameT: this.planForm.value.name_t,
    lots: this.planForm.value.lots,
    start: this.planForm.value.start ? this.formatDate(this.planForm.value.start) : null,
    end: this.planForm.value.end ? this.formatDate(this.planForm.value.end) : null,
    progress: this.planForm.value.progress,
    type: this.planForm.value.type
   }
    if(this.plan) {
        this.companyService.updatePlan(this.plan.id, body).subscribe((data) => {
            console.log(data);
            this.updatePlan.emit(data.model);
            this.plan = null;
            this.updateAttributes();
            this.toastr.success('Plan updated successfully');
        }, (error) => {
              this.toastr.error(error.error.results.error)
        })
    } else {
        this.companyService.createPlan(body).subscribe((data) => {
            console.log(data);
            this.addPlan.emit(data.model);
            this.plan = null;
            this.toastr.success('Plan created successfully');
        }, (error) => {
          this.toastr.error(error.error.results.error)
        })
    }
  }

  public planTypeSubmit() {
    if (this.planTypeForm.invalid) {
        Object.keys(this.planTypeForm.controls).forEach(
            (controlName: string) =>
                this.planTypeForm.controls[controlName].markAsTouched()
        );
        return;
    }
    console.log(this.planTypeForm.value);
    // return
    if (this.planTypeId) {
        this.editPlanType(this.planTypeForm.value, this.planTypeId);
        this.addEditPlanTypeModal.hideModal();
    } else {
        this.addPlanType(this.planTypeForm.value);
        this.addEditPlanTypeModal.hideModal();
    }
    this.planType = null;
    this.planTypeForm.reset();
    this.closeCombo = true;
  }

  public addPlanType(planType: FormData): void {
    this.typesService.addplanType(planType).subscribe((data) => {
      this.toastr.success(this.translateService.instant('activities.planTypeCreatedSuccessfully'));
      this.planTypes.unshift(data.model);
      this.planForm.get('type').setValue(data.model.id)
      this.closeCombo = true;
    });
  }

  public editPlanType(planType: FormData, planTypeId: number): void {
    this.typesService.updatePlanType(planType, planTypeId).subscribe((data) => {
      this.toastr.success(this.translateService.instant('activities.planTypeUpdatedSuccessfully'));
      const index = this.planTypes.findIndex(type => type.id === planTypeId);
      if (index !== -1) {
        this.planTypes[index] = data.model;
      }
      this.planForm.get('type').setValue(data.model.id)
    });
  }

  onAddUpdateTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.planTypeForm.controls['name_t'].setValue(translation.id);
    this.planTypeForm.controls['name'].setValue(translation[this.langString]);
    this.addEditTranslationsModal.hideModal();
  }

  public updateAttributes() {
    if(this.form && this.formFields) {
        this.attributeService.updateForm(this.form.id, {updated_fields: this.formFields}).subscribe(data => {
            console.log(data);
        })
    }
  }

  addPlanTypeImage(event) {
    const imageId = event.value.id;
    this.planTypeImages = [imageId];
    this.planTypeForm.get('icon').setValue(this.planTypeImages.toString());
    console.log(this.planTypeImages);
    console.log(this.planTypeForm.value);
  } 

  public deletePlanTypeIcon(editBankAddress) {
    this.planTypeImages = [];
    this.planTypeForm.controls['icon'].setValue(null);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
