<div class="farms farms_toggle">
    <i class="material-icons select-icon text-white">house</i>
    <mat-form-field class="w-100 custom_tree">
	<mat-select #matSelect placeholder="Places" [(ngModel)]="modelGroup" multiple #itemSelect="ngModel">
        <div class="d-flex" style="align-items: center; padding: 0 10px;">
            <div class="custom_checkbox" (click)="toggleAll(itemSelect)">
                <span [ngClass]="toggleAllItems ? 'checked' : ''"></span>
            </div>
            <mat-form-field class="w-100" appearance="fill">
                <input matInput #farmLotFilter placeholder="Search">
            </mat-form-field>
            <mat-label style="position: absolute; right: 80px; cursor: pointer;">
                <i class="fa fa-search f-20"></i>
            </mat-label>
            <!-- <i class="fa fa-plus center f-20 ml-2" [routerLink]="'/pages/farm/create'"></i> -->
            <fap-round-button [routerLink]="'/pages/farm/create'" [backgroundColor]="'tertiary'"></fap-round-button>
        </div>
        <div class="custom_scroll_wrap">
            <!-- <mat-option class="filter-option" (click)="toggleAll(itemSelect)"> -->
            <!-- </mat-option> -->
            <mat-optgroup *ngFor="let group of lotGroup">
                <div class="barr d-flex no-color justify-content-between">
                    <div class="type-name">
                        <span>{{group.name}}</span>
                    </div>
                    <div class="actions d-flex align-items-center no-color optgroup">
                        <action-buttons 
                            [locked]="group?.locked" 
                            [isDeleteable]="false"
                            (editEmit)="navigateToEntity('farm', group?.id)" 
                            (deleteEmit)="deleteFarm(group?.id)"
                        ></action-buttons>
                    </div>
                </div>
                <mat-option *ngFor="let lot of group.lots | filter : farmLotFilter.value" [value]="lot.id">
                    <div class="d-flex no-color justify-content-between w-100">
                        <div class="type-name">
                            {{lot.name}}
                        </div>
                        <div class="opts actions d-flex align-items-center no-color">
                        <action-buttons 
                            [locked]="lot?.locked" 
                            [isDeleteable]="false"
                            (editEmit)="navigateToEntity('lot', lot?.id)" 
                            (deleteEmit)="deleteFarm(lot?.id)"
                        ></action-buttons>
                        </div>
                    </div>
                </mat-option>
            </mat-optgroup>
        </div>
        <div class="save_btn" (click)="updateUrl()">
            <button class="btn">Ok</button>
        </div>
        </mat-select>
        <mat-select-trigger *ngIf="getSelectedLotsDisplay()" [innerHtml]="getSelectedLotsDisplay()">
          </mat-select-trigger>
</mat-form-field>
</div>