import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MapService } from '../../../shared/layout/fap_main-map/service/map-service';
import { TypesService } from '../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../core/services/api/cache/cache-resolver.service';
import { Subscription } from 'rxjs';
import { NavService } from '../../../shared/services/nav.service';
import { WidgetsService } from '../../../core/services/api/widgets/widgets.service';
import { environment } from '../../../../environments/environment';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FapModalButtonInterface } from '../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { TranslateService } from '@ngx-translate/core';
import { FapModalComponent } from '../../../shared/partials';
import { CompanyService } from '../../../core/services/api/company/company.service';
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AttributeModel } from '../../../core/models/attribute/attribute.model';
import { UnitModel } from '../../../core/models/units/unit.model';
import { ConfirmModalService } from '../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { UnitInterface } from '../../../core/interfaces/unit/unit.interface';
import { UnitService } from '../../../core/services/api/units/unit.service';
import { ResponseModel } from '../../../core/models/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import isEqual from 'lodash/isEqual'; 
import { AttributeService } from '../../../core/services/api/attribute/attribute.service';
import { DomSanitizer } from '@angular/platform-browser';

function noSpecialChars(control: AbstractControl): ValidationErrors | null {
  const validPattern = /^[a-zA-Z0-9\-_]+$/;
  if (control.value && !validPattern.test(control.value)) {
    return { 'specialChars': true };
  }
  return null;
}

@Component({
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit, OnDestroy {
  formTypes = [];
  public langString: string;
  public subscriptions:Array<Subscription> = [];
  newType = false;
  public formName = '';
  public namePlaceholder = 'ADD FORM NAME';
  public infoPlaceholder = 'ADD INFO';
  public uniqueid = '';
  public type = null;
  public originalType = null
  public bgImg = null;
  public info = '';
  public mediaUrl = environment.mediaUrl;
  dragItemIndex: number | null = null;
  isNestedDrag: boolean = false;
  dragParentIndex: number = -1;
  public selectedEditField = 'name';
  public selectedEditFieldLabel = '';
  public showInfo = false;
  public showImg = false;
  public images = [];
  public imgPath = '';
  public nextImgToken :{ offset: number; limit: number;} = null;
  public getMoreImg = true;
  imageSrc: string;
  public imageForm: FormGroup;
  @ViewChild('addImageModal')
  public addImageModal: FapModalComponent;
  @ViewChild('imageModal') public imageModal: FapModalComponent;
  @ViewChild('editFieldModal') public editFieldModal: FapModalComponent;
  @ViewChild('editUniqueidModal') public editUniqueidModal: FapModalComponent;
  @ViewChild('fieldsModal') public fieldsModal: FapModalComponent;
  @ViewChild('groupModal') public groupModal: FapModalComponent;
  @ViewChild('cssModal') 
  public cssModal: FapModalComponent;
  public imgWidth: number;
  public imgHeight: number;
  public orientation: string;
  public image
  @ViewChild('fileUploader') fileUploader:ElementRef;
  public changedImage: boolean;
  public fields = [];
  public groups = [];
  public addEditAttributeForm: UntypedFormGroup;
  public addEditUniqueidForm: UntypedFormGroup;
  public icon: any;
  public selectedAttribute: AttributeModel;
  public valueTypes = ['string', 'number', 'entity', 'datetime', 'boolean', 'image', 'coords', 'surface', 'polyline'];
  public inputTypes = ['single', 'multiselect', 'range', 'choice'];
  public components = ['input', 'radio', 'checkbox', 'combo', 'map', 'label'];
  public filteredComponents = [];
  public filteredInputTypes = [];
  @ViewChild('addEditAttributePopup')
  public addEditAttributePopup: FapModalComponent;
  public translatedNames:any = [];
  public nameT: any = null;
  @ViewChild('addEditTranslationsModal')
  public addEditTranslationsModal: FapModalComponent;
  @ViewChild('addEditFieldTranslationPopup')
  public addEditFieldTranslationPopup: FapModalComponent;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  @ViewChild('nameInput1', { static: true }) nameInput1: ElementRef;
  public translation = null;
  public fieldTranslation = null;
  public units: Array<UnitModel> = [];
  public unitForm: UntypedFormGroup;
  public selectedUnitId: number;
  public selectedUnit: UnitModel;
  public currentUnit: UnitModel;
  public parentUnit: UnitModel;
  @ViewChild('addEditUnitModal')
  public addEditUnitModal: FapModalComponent;
  @ViewChild('previewModal') public previewModal: FapModalComponent;
  @ViewChild('formConfigModal') public formConfigModal: FapModalComponent;
  @ViewChild('mys') public mys;
  @ViewChild('unitsCombo') public unitsCombo;
  @ViewChild('criticalDeleteModal')
  public criticalDeleteModal: FapModalComponent;
  public selectedFields = [];
  public selectedField = null;
  public selectedGroup = null;
  columnOptions = Array.from({ length: 12 }, (_, index) => index + 1);
  rowOptions = Array.from({ length: 10 }, (_, index) => index + 1);
  public typeCss = null;
  public valueType = null;
  public inputType = null;
  public component = null;
  selectedTabIndex = 0;
  public css = null;
  public defaultSize = 'pc';
  public newFormFieldTypes = [];
  public removeFormFieldTypes = [];
  public isSubmitEnabled: boolean = false;
  public newFormType: number
  public label:string
  public allEntities = [];
  public choices: any[] = [];
  public unit: any;
  public isFieldValidated: boolean = false;
  public groupId: any = '';
  public groupName: any = '';
  public mixedFields: any[] = [];
  public objectId: any = null;
  public deleteFieldName: string = null;
  public deleteFieldId: number = null;
  public formCss: any;
  public previewType = null;
  public size = null;

  public pcWidth = '400px';
  public tabletWidth = '400px';
  public mobileWidth = '400px';

  public pcHeight = '200px';
  public tabletHeight = '200px';
  public mobileHeight = '200px';
  
  public pcCol = 12;
  public tabletCol = 12;
  public mobileCol = 12;

  public pcRow = null;
  public tabletRow = null;
  public mobileRow = null;

  public pcComponent:string = null;
  public tabletComponent:string = null;
  public mobileComponent:string = null;
  
  public pcPosition = 1;
  public tabletPosition = 1;
  public mobilePosition = 1;

  public editFieldButtonPrimaryInterface: FapModalButtonInterface;
  public editFieldButtonSecondaryInterface: FapModalButtonInterface;
  public addImageModalButtonPrimaryInterface: FapModalButtonInterface;
  public addImageModalButtonSecondaryInterface: FapModalButtonInterface;
  public imageModalButtonPrimaryInterface: FapModalButtonInterface;
  public imageModalButtonSecondaryInterface: FapModalButtonInterface;
  public fieldModalButtonPrimaryInterface: FapModalButtonInterface;
  public fieldModalButtonSecondaryInterface: FapModalButtonInterface;
  public cssButtonPrimaryInterface: FapModalButtonInterface;
  public cssButtonSecondaryInterface: FapModalButtonInterface;
  public groupCssButtonPrimaryInterface: FapModalButtonInterface;
  public groupCssButtonSecondaryInterface: FapModalButtonInterface;
  public editUniqueidButtonPrimaryInterface: FapModalButtonInterface;
  public editUniqueidButtonSecondaryInterface: FapModalButtonInterface;

  constructor(public mapService: MapService, public typesService: TypesService, public cacheService: CacheResolverService, public navService: NavService, public widgetsService: WidgetsService, public globalRegistry: GlobalRegistryService, private changeDetector: ChangeDetectorRef, public translateService: TranslateService, public companyService: CompanyService, public toastrService: ToastrService, public typeService: TypesService, public formBuilder: UntypedFormBuilder, public confirmModalService: ConfirmModalService, public unitService: UnitService, public activatedRoute: ActivatedRoute, public router: Router, private cdr: ChangeDetectorRef, public attributeService: AttributeService, private sanitizer: DomSanitizer) { }

  get f() {
    return this.addEditAttributeForm.controls;
  }

  get uniqueidField() {
    return this.addEditAttributeForm.get('uniqueid');
  }

  filterInput(event: any) {
    const input = event.target.value;
    const filteredInput = input.replace(/[^\w\-]/g, '');
    const upperCaseInput = filteredInput.toUpperCase();
    event.target.value = upperCaseInput;
    this.uniqueidField.setValue(upperCaseInput);
  }

  ngOnInit(): void {
    this.mapService.hideMap();
    this.widgetsService.setSize(12);
    this.getFormTypes();
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
      }));
      this.translatedNames = this.globalRegistry.systemData.translations;
      this.initaddEditAttributeForm();
      this.initunitForm();
      this.initaddEditUniqueidForm()
      this.initEditModalButtons();
      this.initFieldModalButtons();
      this.initCssModalButtons();
      this.initGroupCssModalButtons();
      this.initUniqueidEditModalButtons();
      this.filteredInputTypes = this.inputTypes;
      this.filteredComponents = this.components;
      setTimeout(() => {
        this.updateSelectedTabIndex();
      });
      this.getModels();
      this.updateDefaultSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateDefaultSize();
  }

  private updateDefaultSize() {
    const width = window.innerWidth;
    this.defaultSize = width >= 1025 ? 'pc' : (width >= 768 ? 'tablet' : 'mobile');
  }

  addNewFormType() {
    this.uniqueid = null;
    this.fields = [];
    this.groups = [];
    this.selectedFields = [];
    this.type = null;
    this.mixedFields = [];
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}${currentDate.getFullYear()}`;
    const timestamp = `${currentDate.getHours().toString().padStart(2, '0')}${currentDate.getMinutes().toString().padStart(2, '0')}${currentDate.getSeconds().toString().padStart(2, '0')}`;
    this.uniqueid = `FORM_TYPE_${formattedDate}_${timestamp}`;
          this.type = {};
          this.bgImg = null;
          this.fields = [];
          this.type = {
            uniqueid: this.uniqueid,
            color: null,
            icon: null,
            name: 'New form '+formattedDate,
            name_t: null,
            groups: [],
            locked: false,
            fields_css: [],
            groups_css: {},
            form_css: {},
            info: null,
            image: {},
            commit: null
        }
        this.updateFormType();
  }

  editFormType(id) {
    let type = this.formTypes.find(item => item.id === id);
          this.newFormType = id
          if(this.type && this.newFormType != this.type.id) {
            console.log('verify')
            this.verifyFormFields(this.type)
          } else {
            this.displayFormType(type);
            console.log('no verify')
          }
  }

  getSizeLabel(t: number): string {
    const row = Math.floor(t / 10);
    const col = t % 10;
    return `${row} x ${col}`;
  }

  public showFields() {
    if(this.type.locked) {
      return;
    }
    this.fieldsModal.showModal();
    // this.selectedFields = [...new Set(this.selectedFields)];
    this.selectedFields = this.fields.map(type => type.field ? type.field.id : type);
    console.log(this.selectedFields);
  }

  public showAddGroupModal() {
    if(this.type.locked) {
      return;
    }
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}${currentDate.getFullYear()}`;
    const timestamp = `${currentDate.getHours().toString().padStart(2, '0')}${currentDate.getMinutes().toString().padStart(2, '0')}${currentDate.getSeconds().toString().padStart(2, '0')}`;
    this.groupId = `GROUP_${formattedDate}_${timestamp}`;
    this.groupName = null;
    this.groupModal.showModal();
  }

  public showFormCssModal() {
    console.log(this.type);
    if(this.type.form_css.pc) {
      if(this.defaultSize === 'pc') {
        this.pcCol = this.type.form_css.pc.banner.cols;
        this.pcRow = this.type.form_css.pc.banner.rows;
      }
      if(this.defaultSize === 'tablet') {
        this.tabletCol = this.type.form_css.tablet.banner.cols;
        this.tabletRow = this.type.form_css.tablet.banner.rows;
      }
      if(this.defaultSize === 'mobile') {
        this.mobileCol = this.type.form_css.mobile.banner.cols;
        this.mobileRow = this.type.form_css.mobile.banner.rows;
      }
    }
    this.formCss = this.type.form_css;
    this.getHeightAndWidth();
    this.formConfigModal.showModal();
    this.isSubmitEnabled = true;
  }

  getHeightAndWidth() {
    if(this.type.form_css.pc) {
      this.pcWidth = this.type.form_css.pc.width;
      this.pcHeight = this.type.form_css.pc.height;
      this.tabletWidth = this.type.form_css.tablet.width;
      this.tabletHeight = this.type.form_css.tablet.height;
      this.mobileWidth = this.type.form_css.mobile.width;
      this.mobileHeight = this.type.form_css.mobile.height;
    }
  }

  setHeightAndWidth() {
    if(this.type.form_css.pc) {
      this.type.form_css.pc.width = this.pcWidth;
      this.type.form_css.pc.height = this.pcHeight;
      this.type.form_css.tablet.width = this.tabletWidth;
      this.type.form_css.tablet.height = this.tabletHeight;
      this.type.form_css.mobile.width = this.mobileWidth;
      this.type.form_css.mobile.height = this.mobileHeight;
    }
  }

  getColKey(): string {
    return `${this.defaultSize}Col`;
}

getRowKey(): string {
  return `${this.defaultSize}Row`;
}

getPositionKey(): string {
    return `${this.defaultSize}Position`;
}

getComponentKey(): string {
    return `${this.defaultSize}Component`;
}

getColValue(): any {
    return this[this.getColKey()];
}

getRowValue(): any {
  return this[this.getRowKey()];
}

getPositionValue(): any {
    return this[this.getPositionKey()];
}

getComponentValue(): any {
    return this[this.getComponentKey()];
}

changeCssItem(key: string, event: any): void {
    this[key] = event.value;
}

changeValue(key: string, event: any): void {
    this[key] = event.target.value;
}

changeFormCssItem(key: string, event: any): void {
  this[key] = event.value;
  console.log(key);
}

get width(): string {
  if (this.defaultSize === 'pc') {
    return this.pcWidth;
  } else if (this.defaultSize === 'tablet') {
    return this.tabletWidth;
  } else {
    return this.mobileWidth;
  }
}

set width(value: string) {
  if (this.defaultSize === 'pc') {
    this.pcWidth = value;
  } else if (this.defaultSize === 'tablet') {
    this.tabletWidth = value;
  } else {
    this.mobileWidth = value;
  }
}

get height(): string {
  if (this.defaultSize === 'pc') {
    return this.pcHeight;
  } else if (this.defaultSize === 'tablet') {
    return this.tabletHeight;
  } else {
    return this.mobileHeight;
  }
}

set height(value: string) {
  if (this.defaultSize === 'pc') {
    this.pcHeight = value;
  } else if (this.defaultSize === 'tablet') {
    this.tabletHeight = value;
  } else {
    this.mobileHeight = value;
  }
}

changeFormHeight(event: any): void {
  if(this.defaultSize === 'pc') {
    this.pcHeight = event.value;
  } else if(this.defaultSize === 'tablet') {
    this.tabletHeight = event.value;
  } else {
    this.mobileHeight = event.value;
  }
  this.type.form_css[this.defaultSize].height = event.value;
}

changeFormWidth(event: any): void {
  if(this.defaultSize === 'pc') {
    this.pcWidth = event.value;
  } else if(this.defaultSize === 'tablet') {
    this.tabletWidth = event.value;
  } else {
    this.mobileWidth = event.value;
  }
  this.type.form_css[this.defaultSize].width = event.value;
}

onDragOver(event: DragEvent): void {
    event.preventDefault();
}

onDragStart(event: DragEvent, index: number, isNested: boolean = false, parentIndex: number = -1): void {
  this.dragItemIndex = index;
  this.isNestedDrag = isNested;
  this.dragParentIndex = parentIndex;
  const data = JSON.stringify({ index, isNested, parentIndex });
  event.dataTransfer?.setData('text/plain', data);
}

onDrop(event: DragEvent, dropIndex: number, isNestedDrop: boolean = false, parentDropIndex: number = -1): void {
  event.preventDefault();
  const draggedData = JSON.parse(event.dataTransfer?.getData('text/plain') || '{}');
  const { index: draggedIndex, isNested, parentIndex } = draggedData;

  if (draggedIndex === null) {
    return;
  }

  const oldMixedFields = JSON.parse(JSON.stringify(this.mixedFields));

  let draggedItem = null;
  let parentGroup = null;
  let dropGroup = null;

  if (isNested) {
    parentGroup = this.mixedFields[parentIndex];
    draggedItem = parentGroup.nestedFields[draggedIndex];
    dropGroup = this.mixedFields[parentDropIndex];
  } else {
    draggedItem = this.mixedFields[draggedIndex];
  }

  let dropItem = isNestedDrop ? (dropGroup?.nestedFields?.[dropIndex] || null) : (this.mixedFields[dropIndex] || null);

  if (dropItem && !dropItem.field && draggedItem && draggedItem.field) {
    if (!dropItem.nestedFields) {
      dropItem.nestedFields = [];
    }
    dropItem.nestedFields.push(draggedItem);
    draggedItem.group = dropItem.id;

    if (isNested && parentGroup) {
      parentGroup.nestedFields = parentGroup.nestedFields.filter(field => field.id !== draggedItem.id);
    } else {
      this.mixedFields.splice(draggedIndex, 1);
    }

    draggedItem.form = this.type.id;
    draggedItem.field = draggedItem.field.id;

    this.typesService.updateFormFieldType(draggedItem.id, draggedItem).subscribe(
      data => {
        draggedItem = data.body.results;
        const index = this.fields.findIndex(product => product.id === data.body.results.id);
        if (index !== -1) {
          this.fields[index] = data.body.results;
        }
        this.displayFormType(this.type);
        this.toastrService.success(this.translateService.instant('attribute.fieldTypeUpdatedSuccessfully'));
      },
      error => {
        this.toastrService.error(error.error.results.error);
      }
    );
  } else {
    if (isNested && parentGroup) {
      parentGroup.nestedFields = parentGroup.nestedFields.filter(field => field.id !== draggedItem.id);
      draggedItem.group = ""; // Clear the group when dropped outside
    } else {
      this.mixedFields.splice(draggedIndex, 1);
    }

    if (isNestedDrop) {
      dropGroup.nestedFields.splice(dropIndex, 0, draggedItem);
    } else {
      this.mixedFields.splice(dropIndex, 0, draggedItem);
    }

    // Swap positions
    const draggedPos = draggedItem[this.defaultSize]?.pos;
    const dropPos = dropItem[this.defaultSize]?.pos;
    if (draggedPos !== undefined && dropPos !== undefined) {
      draggedItem[this.defaultSize].pos = dropPos;
      dropItem[this.defaultSize].pos = draggedPos;
    }
  }

  // Update positions
  if (isNestedDrop) {
    dropGroup?.nestedFields?.forEach((item, index) => {
      const oldIndex = oldMixedFields[parentDropIndex]?.nestedFields?.findIndex(oldItem => oldItem.id === item.id);
      if (oldIndex !== -1 && index !== oldIndex) {
        item[this.defaultSize].pos = index + 1;
      }
    });
  } else {
    this.mixedFields.forEach((item, index) => {
      const oldIndex = oldMixedFields.findIndex(oldItem => oldItem.id === item.id);
      if (oldIndex !== -1 && index !== oldIndex) {
        item[this.defaultSize].pos = index + 1;
      }
    });
  }

  this.isSubmitEnabled = true;
  this.dragItemIndex = null;
  this.isNestedDrag = false;
  this.dragParentIndex = -1;

  // Handle groups
  let updatedMixedFields = [...this.mixedFields];
  let nestedFields = [];
  let group;

  this.mixedFields.forEach(field => {
    if (field.nestedFields) {
      nestedFields = field.nestedFields;
      const idToCut = field.id;
      const indexToCut = updatedMixedFields.findIndex(obj => obj.id === idToCut);
      if (indexToCut !== -1) {
        group = updatedMixedFields.splice(indexToCut, 1)[0];
      }
    }
  });

  if (group && !this.groups.some(item => item.id === group.id)) {
    let newGroup = { ...group };
    if (newGroup) {
      delete newGroup.nestedFields;
      this.groups.push(newGroup);
    }
  }

  // Update type and fields
  this.type.fields_css = this.fields;

  // Compare old and new mixedFields
  const changedFields = this.mixedFields.filter((newField, index) => !isEqual(oldMixedFields[index], newField));

  if (changedFields.length) {
    changedFields.forEach(changedField => this.updateFieldCss(changedField));
  }
}

public previewForm() {
  this.previewType = this.type;
  this.size = this.defaultSize;
  this.objectId = 0;
  this.previewModal.showModal();
}

  public removeGroup(group) {
    console.log(group);
    if(group.nestedFields && group.nestedFields.length) {
      this.confirmModalService.openConfirmModal(
        this.translateService.instant('alert'),
        "Do you want to remove this group?"
    );
    this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
        if (confirmed) {
          const deletedGroupItems = [];
            group.nestedFields.forEach((field, index, array) => {
              const body = {
                id: field.id,
                group: ""
              }
              deletedGroupItems.push(field);
              this.typesService.updateFormFieldType(field.id, body).subscribe();
              if (index === array.length - 1) {
                delete this.type.groups_css[group.id];
              }
            });
            console.log(deletedGroupItems);
            this.mixedFields = [...this.mixedFields, ...deletedGroupItems];
            this.mixedFields = this.mixedFields.filter(field => field.id !== group.id);
            this.groups = this.groups.filter(mainGroup => mainGroup.id !== group.id);
            this.isSubmitEnabled = true;
            console.log(this.groups);
            if(this.groups.length == 0) {
              this.type.groups_css = {}
            }
            console.log(this.type);
            console.log(this.mixedFields);
        } else {
            return;
        }
    });
    } else {
      this.groups = this.groups.filter(mainGroup => mainGroup.id !== group.id);
      delete this.type.groups_css[group.id];
      this.mixedFields = this.mixedFields.filter(mixedField => mixedField.id !== group.id);
      this.isSubmitEnabled = true;
    }
  }

  private initEditModalButtons(): void {
    const _this: FormsComponent = this;
    this.editFieldButtonSecondaryInterface = {
        clickFunction: (): void => {
            _this.editFieldModal.hideModal();
        },
        text: this.translateService.instant('cancel'),
    };
    this.editFieldButtonPrimaryInterface = {
        clickFunction: (): void => {
          // _this.updateFields();
          _this.editFieldModal.hideModal();
        },
        text: this.translateService.instant('save'),
    };
}

private initUniqueidEditModalButtons(): void {
  const _this: FormsComponent = this;
  this.editUniqueidButtonSecondaryInterface = {
      clickFunction: (): void => {
          _this.editUniqueidModal.hideModal();
      },
      text: this.translateService.instant('cancel'),
  };
  this.editUniqueidButtonPrimaryInterface = {
      clickFunction: (): void => {
        _this.updateUniqueid();
        _this.editUniqueidModal.hideModal();
      },
      text: this.translateService.instant('save'),
  };
}

updateUniqueid() {
  console.log(this.addEditUniqueidForm.value);
  this.type.uniqueid = this.addEditUniqueidForm.value.uniqueid;
  this.isSubmitEnabled = true;
}

handle(ev) {
  console.log(ev);
}

private initCssModalButtons(): void {
  const _this: FormsComponent = this;
  this.cssButtonSecondaryInterface = {
      clickFunction: (): void => {
          _this.cssModal.hideModal();
      },
      text: this.translateService.instant('cancel'),
  };
  this.cssButtonPrimaryInterface = {
      clickFunction: (): void => {
        _this.updateCss();
        _this.cssModal.hideModal();
      },
      text: this.translateService.instant('save'),
  };
}

private initGroupCssModalButtons(): void {
  const _this: FormsComponent = this;
  this.groupCssButtonSecondaryInterface = {
      clickFunction: (): void => {
          _this.groupModal.hideModal();
      },
      text: this.translateService.instant('cancel'),
  };
  this.groupCssButtonPrimaryInterface = {
      clickFunction: (): void => {
        _this.updateGroupCss();
      },
      text: this.translateService.instant('save'),
  };
}

camelToSnake(obj) {
  const snakeObj = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeKey = key.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`);
      snakeObj[snakeKey] = obj[key];
    }
  } 

  return snakeObj;
}

public collectAttributes(fields) {
  console.log(fields);
  console.log(this.fields);
  const fieldIds = this.fields.map(field => field.field.id);
  const itemsNotInFields = fields.filter(item => !fieldIds.includes(item));
  console.log(itemsNotInFields);
  this.newFormFieldTypes = itemsNotInFields;
}

public initFieldModalButtons(): void {
  const _this: FormsComponent = this;
  this.fieldModalButtonPrimaryInterface = {
      clickFunction(): void {
        _this.updateFields();
        _this.fieldsModal.hideModal();
      },
      text: this.translateService.instant('save')
  };

  this.fieldModalButtonSecondaryInterface = {
      clickFunction(): void {
        _this.fieldsModal.hideModal();
        this.image = null;
      },
      text: this.translateService.instant('cancel')
  };
}

public getModels() {
  this.typeService.getModels().subscribe(data => {
    this.allEntities = data.body.results;
  })
}

async updateFormType() {
  try {
    if (this.type.locked) {
      return;
    }
    console.log(this.removeFormFieldTypes);
    console.log(this.type);
    this.type.name = this.formName;

    if (this.type.name === 'ADD FORM NAME') {
      this.type.name = null;
    }

    if (this.type.name_t) {
      this.type.name_t = this.type.name_t.id;
    }

    if (this.type.info) {
      if (this.type.info === 'ADD INFO') {
        this.type.info = null;
      } else {
        this.type.info = this.type.info.id;
      }
    }
    const obj = this.groups.reduce((acc, item) => {
      delete item.nestedFields
      acc[item.id] = item;
      return acc;
  }, {});

  const extractIds = (data) => {
    const ids = [];

    data.forEach(item => {
          if (item.id) {
              if(typeof item.id === 'number') {
                ids.push(item.id);
              }
          }
          
          if (item.nestedFields) {
              item.nestedFields.forEach(nestedItem => {
                  if (nestedItem.id) {
                      ids.push(nestedItem.id);
                  }
              });
          }
      });

      return ids;
  };
  const idsArray = extractIds(this.type.fields_css);
  console.log(idsArray);
    
    const body = {
      name: this.type.name,
      uniqueid: this.type.uniqueid,
      name_t: this.type.name_t,
      info: this.type.info,
      groups_css: obj,
      fields_css: idsArray,
      form_css: this.type.form_css,
      image: null
    };

    let imgId = null;

    if (this.type.image) {
      imgId = this.type.image.id;
      body.image = imgId;
    }

    if (this.type.id) {
      const patchObservable = this.typeService.patchFormTypes(this.type.id, body);
      patchObservable.subscribe(data => {
        console.log(data);
        if (data) {
          this.type = data.body.results;
          this.originalType = data.body.results;
          this.fields = this.type.fields_css;
          this.isSubmitEnabled = false;
          this.groupId = null;
          this.selectedGroup = null;
        }
        this.toastrService.success(this.translateService.instant('attribute.formTypeUpdatedSuccessfully'));
        this.displayFormType(this.type);
        const index = this.formTypes.findIndex(product => product.id === data.body.results.id);
        if (index !== -1) {
          this.formTypes[index] = data.body.results;
        }
      }, error => {
        this.toastrService.error(error);
      });
    } else {
      const createObservable = this.typeService.createFormType(body);
      createObservable.subscribe(data => {
        console.log(data);
        this.formTypes.unshift(data.body.results);
        this.type = data.body.results;
        this.isSubmitEnabled = false;
        this.toastrService.success(this.translateService.instant('attribute.formTypeCreatedSuccessfully'));
      }, error => {
        this.toastrService.error(error);
      });
    }
  } catch (error) {
    console.error('Error updating form type:', error);
    this.toastrService.error(error.error.results.error);
  }
}

public filterComponents() {
  if (!this.valueType || !this.inputType) {
    return;
  }

  const components = this.components;

  if (this.valueType === 'string') {
    if (this.inputType === 'multiselect') {
      this.filteredComponents = components.filter(component => !['radio', 'input', 'map', 'label', 'button'].includes(component));
    } else if (this.inputType === 'choice') {
      this.filteredComponents = components.filter(component => !['checkbox', 'input', 'map', 'label', 'button'].includes(component));
    } else {
      this.filteredComponents = components.filter(component => !['radio', 'checkbox', 'combo', 'map'].includes(component));
    }
  } else if (this.valueType === 'number') {
    if (this.inputType === 'multiselect') {
      this.filteredComponents = components.filter(component => !['radio', 'input', 'map', 'label', 'button'].includes(component));
    } else if (this.inputType === 'choice') {
      this.filteredComponents = components.filter(component => !['checkbox', 'input', 'map', 'label', 'button'].includes(component));
    } else {
      this.filteredComponents = components.filter(component => !['radio', 'checkbox', 'combo', 'map', 'label', 'button'].includes(component));
    }
  } else if (this.valueType === 'entity') {
    if (this.inputType === 'multiselect') {
      this.filteredComponents = components.filter(component => !['radio', 'checkbox', 'input', 'map', 'label', 'button'].includes(component));
    } else {
      this.filteredComponents = components.filter(component => !['radio', 'checkbox', 'combo', 'map', 'label', 'button'].includes(component));
    }
  } else if (this.valueType === 'boolean') {
    this.filteredComponents = components.filter(component => !['input', 'map', 'label', 'button'].includes(component));
  } else if (this.valueType === 'image') {
    this.filteredComponents = components.filter(component => !['input', 'radio', 'map', 'checkbox', 'label', 'button'].includes(component));
  } else if (['coords', 'surface', 'polyline'].includes(this.valueType)) {
    this.filteredComponents = components.filter(component => !['input', 'radio', 'checkbox', 'label', 'button'].includes(component));
  } else {
    // Handle default case if valueType doesn't match any condition
    this.filteredComponents = components;
  }

  console.log(this.filteredComponents);
}


public editCss(item) {
  if(this.type.locked) {
    return
  }
  console.log(item);
  this.selectedField = item;
  this.isFieldValidated = this.selectedField.validation;

  this.valueType = item.field.value_type;
  this.inputType = item.field.input_type;
  
  this.filterComponents()
  this.cssModal.showModal();
  if(item.pc) {
    this.pcCol = item.pc.cols;
    this.pcPosition = item.pc.pos;
    this.pcComponent = item.pc.component;
    this.pcRow = item.pc.rows;
  }
  if(item.tablet) {
    this.tabletCol = item.tablet.cols;
    this.tabletPosition = item.tablet.pos;
    this.tabletComponent = item.tablet.component;
    this.tabletRow = item.tablet.rows;
  }
  if(item.mobile) {
    this.mobileCol = item.mobile.cols;
    this.mobilePosition = item.mobile.pos;
    this.mobileComponent = item.mobile.component;
    this.mobileRow = item.mobile.rows;
  }
}

public editGroupCss(item) {
  if(this.type.locked) {
    return
  }
  console.log(item);
  this.selectedGroup = item;
  this.groupId = item.id;
  this.groupName = item.name;
  this.groupModal.showModal();
  if(item.pc) {
    this.pcCol = item.pc.cols;
    this.pcPosition = item.pc.pos;
    this.pcRow = item.pc.rows;
  }
  if(item.tablet) {
    this.tabletCol = item.tablet.cols;
    this.tabletPosition = item.tablet.pos;
    this.tabletRow = item.tablet.rows;
  }
  if(item.mobile) {
    this.mobileCol = item.mobile.cols;
    this.mobilePosition = item.mobile.pos;
    this.mobileRow = item.mobile.rows;
  }
}

public updateGroupCss() {
  if(!this.groupName || this.groupName === '') {
    this.toastrService.error(this.translateService.instant('attribute.enterValidGroupName'));
    return
  }
  const pc = { cols: +this.pcCol, rows: +this.pcRow, pos: +this.pcPosition };
    const tablet = { cols: +this.tabletCol, rows: +this.tabletRow, pos: +this.tabletPosition };
    const mobile = { cols: +this.mobileCol, rows: +this.mobileRow, pos: +this.mobilePosition };

    let existingElementIndex = -1;

    if (this.selectedGroup) {
        existingElementIndex = this.mixedFields.findIndex(element => element.id === this.selectedGroup.id);
    }

    if (existingElementIndex !== -1) {
        // Update existing group's dimensions
        this.mixedFields[existingElementIndex].pc = pc;
        this.mixedFields[existingElementIndex].tablet = tablet;
        this.mixedFields[existingElementIndex].mobile = mobile;
    } else {
        // Create a new group
        const newGroup = {
            id: this.groupId,
            name: this.groupName,
            pc,
            tablet,
            mobile
        };
        this.mixedFields.push(newGroup);
        this.selectedGroup = newGroup;
    }

    console.log(this.mixedFields);
    console.log(this.selectedGroup);
    let index = this.groups.findIndex(group => group.id === this.selectedGroup.id);
    if (index !== -1) {
        this.groups[index] = this.selectedGroup;
    } else {
        this.groups.push(this.selectedGroup);
    }
    
    if (!this.type.groups_css) {
      this.type.groups_css = {};
    }

    if (this.type.groups_css.hasOwnProperty(this.selectedGroup.id)) {
      this.type.groups_css[this.selectedGroup.id] = this.selectedGroup;
    } else {
      this.type.groups_css[this.selectedGroup.id] = this.selectedGroup;
      // if (this.type.groups_css[this.selectedGroup.id]['nestedFields']) {
      //     delete this.type.groups_css[this.selectedGroup.id]['nestedFields'];
      // }
    }

    this.type.groups_css = this.groups.reduce((acc, current) => {
      acc[current.id] = current;
      return acc;
  }, {});
    this.groupModal.hideModal();
    console.log(this.groups);
    console.log(this.type.groups_css);
    this.isSubmitEnabled = true;
    this.selectedGroup = null;
}


public updateCss() {
  const pc = { cols: +this.pcCol, rows: +this.pcRow, pos: +this.pcPosition, component: this.pcComponent };
  const tablet = { cols: +this.tabletCol, rows: +this.tabletRow, pos: +this.tabletPosition, component: this.tabletComponent };
  const mobile = { cols: +this.mobileCol, rows: +this.mobileRow, pos: +this.mobilePosition, component: this.mobileComponent }

  const existingElement = this.selectedField;

  if (existingElement) {
    existingElement.pc = pc;
    existingElement.tablet = tablet;
    existingElement.mobile = mobile;
  } else {
    this.type.pc = pc;
    this.type.tablet = tablet;
    this.type.mobile = mobile;
  }
  let body = {
    id: this.selectedField.id,
    field: this.selectedField.field.id,
    form: this.type.id,
    validation: this.isFieldValidated,
    pc,
    tablet,
    mobile
  }
  console.log(body);
  this.typesService.updateFormFieldType(this.selectedField.id, body).subscribe(data => {
    console.log(data);
    console.log(this.fields);
    // this.selectedField = data.body.results;
    const index = this.fields.findIndex(product => product.id === data.body.results.id);
    if (index !== -1) {
      this.fields[index] = data.body.results;
    }
    this.toastrService.success(this.translateService.instant('attribute.fieldTypeUpdatedSuccessfully'));
  }, (error) => {
    this.toastrService.error(error.error.results.error);
  })
  console.log(this.type.fields_css);
  this.isSubmitEnabled = true;
  this.displayFormType(this.type);
  console.log(this.type);
}

public validateChoice(type) {
  this.selectedAttribute.inputType = type.value;
  console.log('input_type', this.selectedAttribute.inputType);
  console.log('value_type', this.selectedAttribute.valueType)
  // console.log(this.selectedField.field.input_type);
  // console.log(type);
}

// public changeCssItem(prop, $event) {
//   console.log($event.value);
//   this[prop] = $event.value;
// }

public setBannerConfig() {
  if(this.type.form_css.pc) {
    if(this.defaultSize === 'pc') {
      this.type.form_css.pc.banner.rows = this.pcRow;
      this.type.form_css.pc.banner.cols = this.pcCol;
    } else if(this.defaultSize === 'tablet') {
      this.type.form_css.tablet.banner.rows = this.tabletRow;
      this.type.form_css.tablet.banner.cols = this.tabletCol;
    } else {
      this.type.form_css.mobile.banner.rows = this.mobileRow;
      this.type.form_css.mobile.banner.cols = this.mobileCol;
    }
  }
}

public submitFormConfig() {
  this.setHeightAndWidth();
  this.setBannerConfig();
  console.log(this.type.form_css);
  this.toastrService.success(this.translateService.instant("attribute.updatedFormConfig"));
  this.formConfigModal.hideModal();
}

public changevalue(prop, $event) {
  console.log(prop, $event.target.value);
  this[prop] = $event.target.value;
}

public updateFields() {
  console.log(this.selectedFields);
  
  const selectedFieldIds = new Set(this.selectedFields);
  this.fields = this.fields.filter(field => {
      if (!selectedFieldIds.has(field.field)) {
          return false;
      }
      return true;
  });
  console.log(this.selectedFields);
  // this.type.fields_css = this.selectedFields;
  // if(!this.type || !this.type.id) {
  //   this.updateFormType();
  // }
  setTimeout(() => {
    this.addNewFormFieldType();
  }, 2000)  
}

public addNewFormFieldType() {
  if(!this.type) {
    return
  }
  console.log(this.newFormFieldTypes);
  this.newFormFieldTypes.forEach((field, index, array) => {
    let body = {
      form: this.type.id,
      field,
      validation: false,
      pc: {cols: 12, pos: 1000, rows: 1, component: null, group: ''},
      tablet: {cols: 12, pos: 1000, rows: 1, component: null, group: ''},
      mobile: {cols: 12, pos: 1000, rows: 1, component: null, group: ''}
    };
    this.typesService.addFormFieldType(body).subscribe(data => {
      if (data) {
        this.mixedFields.push(data.body.results);
        this.fields.push(data.body.results);
        this.selectedFields.push(data.body.results.field.id);
        this.selectedFields = [...new Set(this.selectedFields)];
        this.type.fields_css = this.mixedFields;
        this.isSubmitEnabled = true;
        console.log(this.mixedFields);
        console.log(this.fields);
        this.separateFields(this.mixedFields);
        if (index === array.length - 1) {
          this.newFormFieldTypes = [];
          // this.getFormTypeById(this.type.id);
          // this.updateFormType();
        }
      }
    });
  });
}

separateFields(mixedFields) {
  let fields = [];
  let selectedFields = [];
  mixedFields.forEach(field => {
    if(field.nestedFields) {
      field.nestedFields.forEach(nestedField => {
        fields.push(nestedField);        
        selectedFields.push(nestedField.field.id)
      });
    } else {
      fields.push(field);
      selectedFields.push(field.field.id)
    }
  });
  console.log(fields);
  this.fields = fields;
  this.type.fields_css = fields;
  console.log(selectedFields);
  this.selectedFields = selectedFields;
  this.displayFormType(this.type);
}

getFormTypeById(id) {
  this.typesService.getFormTypeById(id).subscribe(data => {
    this.type = data.body.results;
    this.displayFormType(this.type);
  })
}

public updateImage() {
  console.log(this.imgPath);
  console.log(this.image);
  if(!this.image) {
    return
  }
  this.bgImg = this.image;
  this.showImg = true;
  this.type.image = this.image;
  console.log(this.type);
  this.imageModal.hideModal();
}

public showImageModal(): void {
  this.addImageModal.showModal();
}

public showBgImageModal(): void {
  this.imageModal.showModal();
}

public hideInfo() {
  this.showInfo = false;
  this.info = null;
}

public hideImg() {
  this.showImg = false;
  this.bgImg = null;
  this.type.image = null;
  this.type.form_css[this.defaultSize].banner.image = null;
}

public updateTextField(field, $event) {
  console.log($event.target.value);
  if(!$event.target.value || $event.target.value.trim() === '') {
    return
  }
  this.type[field] = $event.target.value;
  console.log(this.type);
  this.showInfo = true;
  if(field === 'info') {
    this.info =  $event.target.value
  } else {
    this.formName = $event.target.value
  }
}

onToggleImage(event) {
  console.log(event)
  if(event.checked === true) {
    this.editImage();
    this.showBgImageModal();
  } else {
    this.type.image = null;
    this.bgImg = null;
  }
}

  public getFormTypes() {
    const url = this.typesService.getUrl('form/');
    this.cacheService.delete(url);
    this.typesService.getFormTypes().subscribe((data) => {
        console.log(data);
        this.formTypes = data.body.results;
        // this.displayFormType(this.formTypes[0]);
        if(localStorage.getItem('type')) {
          this.type = this.formTypes.find(type => type.id === +localStorage.getItem('type'));
          this.displayFormType(this.type);
          console.log(this.type);
          console.log(localStorage.getItem('type'));
        } else {
          if(!this.type) {
            this.type = this.formTypes[0];
            this.displayFormType(this.type);
            localStorage.setItem('type', this.type.id);
          }
        }
        this.typesService.setFormTypes(data.body.results);
    });
  }

  displayFormType(type) {
    console.log(type);
    if (!type || !type.fields_css) {
      return;
    }
  
    if (type.id) {
      this.newType = false;
      this.router.navigate(['pages/forms']);
    }
  
    this.initializeType(type);
    this.configureFields(type);
    this.fields = type.fields_css;
    this.mixedFields = [...this.fields];
    this.bgImg = type.form_css && type.form_css[this.defaultSize] ? type.form_css[this.defaultSize].banner.image : null;
    if(this.bgImg) {
      this.showImg = true;
      this.image = this.bgImg;
      this.imgPath = this.bgImg.file
    } else {
      this.showImg = false;
      this.image = null;
      this.imgPath = null
    }
    console.log(this.bgImg)
    if (type.groups_css) {
      this.groups = Object.values(type.groups_css);
      const organizedFields = this.organizeFieldsByGroup(this.fields, this.groups);
      this.mixedFields = this.removeDuplicates(organizedFields);
      console.log(this.mixedFields);
    }
  
    this.info = type.info;
    if (type.info) {
      this.showInfo = true;
    }
    console.log(this.fields);
    console.log(this.mixedFields);
  }
  
  initializeType(type) {
    this.type = type;
    this.selectedGroup = null;
    this.groupId = null;
    this.groupName = null;
    this.groups = [];
    localStorage.setItem('type', this.type.id);
    console.log(type);
  }
  
  organizeFieldsByGroup(fields, groups) {
    const groupMap = this.createGroupMap(groups);
    const ungroupedFields = [];
  
    fields.forEach(field => {
      const groupId = field.group;
  
      if (!groupId || groupId == 1) {
        ungroupedFields.push(field);
      } else {
        this.addToGroupMap(groupMap, field, groupId);
      }
    });
  
    const groupedFields = this.processGroupedFields(groupMap);
    const uniqueUngroupedFields = this.removeDuplicates(ungroupedFields);
  
    console.log(groupedFields);
    return [...groupedFields, ...uniqueUngroupedFields];
  }
  
  createGroupMap(groups) {
    const groupMap = new Map();
    groups.forEach(group => {
      groupMap.set(group.id, { ...group, nestedFields: [] });
    });
    return groupMap;
  }
  
  addToGroupMap(groupMap, field, groupId) {
    if (!groupMap.has(groupId)) {
      groupMap.set(groupId, {
        id: groupId,
        name: field.name,
        nestedFields: [],
        pc: field.pc,
        tablet: field.tablet,
        mobile: field.mobile
      });
    }
    groupMap.get(groupId).nestedFields.push(field);
  }
  
  processGroupedFields(groupMap) {
    return Array.from(groupMap.values()).map(group => {
      group['nestedFields'] = this.removeDuplicateFields(group['nestedFields']);
      return group;
    });
  }
  
  removeDuplicateFields(fields) {
    const uniqueFields = new Map();
    fields.forEach(field => {
      uniqueFields.set(field.id, field);
    });
    return Array.from(uniqueFields.values());
  }
  

  removeDuplicates = (array) => {
    const seen = new Map();
    return array.filter(item => {
      if (seen.has(item.id)) {
        return false;
      } else {
        seen.set(item.id, true);
        return true;
      }
    });
  };

  verifyFormFields(type) {
    console.log(type, this.type, this.newFormType);

    const updateFormTypeAndDisplay = () => {
        this.updateFormType();
    };

    const resetToNewFormType = () => {
        this.type = this.formTypes.find(ftype => ftype.id === this.newFormType);
        if (this.type) {
            this.selectedFields = this.type.fields_css.map(type => type.id);
            this.fields = this.type.fields_css;
            console.log(this.fields);
            this.isSubmitEnabled = false;
            this.displayFormType(this.type);
        }
    };

    if (this.type && this.isSubmitEnabled) {
        this.confirmModalService.openConfirmModal(
            this.translateService.instant('alert'),
            "Do you want to save your changes?"
        );
        this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
            if (confirmed) {
                updateFormTypeAndDisplay();
            } else {
                resetToNewFormType();
            }
        });
    } else {
        resetToNewFormType();
    }
}

  deepObjectComparison(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
  
  configureFields(type) {
    console.log(type);
    const nameT = type.name_t ? type.name_t[this.langString] : type.name;
    const name = type.name
    this.formName = nameT ? nameT : name;
    // this.bgImg = type.image;
    console.log(this.bgImg)
    this.selectedFields = [];
    if(type.fields_css.length) {
      type.fields_css.forEach(element => {
        this.selectedFields.push(element)
        this.selectedFields = [...new Set(this.selectedFields)];
      });
    }
    console.log(this.fields);
    console.log(this.mixedFields);
    console.log(this.groups);
    let mixedFieldsCopy = [...this.mixedFields]
    let groupedFields = [];
    mixedFieldsCopy.forEach(mixedField => {
      if(mixedField.nestedFields) {
        mixedField.nestedFields.forEach(field => {
          if(typeof field.field === 'number') {
            let attr = this.findAttr(field);
            if(attr) groupedFields.push(attr);
          }
          else groupedFields.push(field);
        });
      }
    });
    console.log(groupedFields);
    this.fields = [...this.fields, ...groupedFields].filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.id === item.id
      ))
    );
    console.log(this.fields);
    // this.selectedFields = type.fields_css.map(item => item.id);
    // console.log(this.selectedFields);
    // if(this.selectedFields.length === 0) {
    //   this.fields = [];
    //   this.mixedFields = [];
    // }
    // if(type.info && type.info.trim() !== '') {
    //   this.info = type.info
    // } else {
    //   this.info = this.infoPlaceholder;
    // }
  }

  findAttr(fieldId) {
    let foundEl = null;
    this.globalRegistry.systemData.attributes.forEach(element => {
      if(element.id === fieldId) {
        console.log(element)
        foundEl = element;
      }
    });
    return foundEl;
  }

  validateInputType(type) {
    this.selectedAttribute.valueType = type;
    console.log(this.selectedAttribute)
    const inputTypes = this.inputTypes;
    if(type === 'string') {
      this.filteredInputTypes = inputTypes.filter(inputType => !(inputType === 'range'));
    }
    if(type === 'number') {
      this.filteredInputTypes = inputTypes.filter(inputType => !(inputType === 'label' || inputType === 'button'));
    }
    if(type === 'datetime') {
      this.filteredInputTypes = inputTypes.filter(inputType => !(inputType === 'multiselect' || inputType === 'label' || inputType === 'button'));
    }
    if(type === 'entity') {
      this.filteredInputTypes = inputTypes.filter(inputType => !(inputType === 'range' || inputType === 'choice' || inputType === 'label' || inputType === 'button'));
    }
    if(type === 'datetime') {
      this.filteredInputTypes = inputTypes.filter(inputType => !(inputType === 'choice' || inputType === 'multiselect' || inputType === 'label' || inputType === 'button'));
    }
    if(type === 'boolean') {
      this.filteredInputTypes = inputTypes.filter(inputType => !(inputType === 'range' || inputType === 'multiselect' || inputType === 'label' || inputType === 'button'));
    }
    if(type === 'image') {
      this.filteredInputTypes = inputTypes.filter(inputType => !(inputType === 'range' || inputType === 'multiselect' || inputType === 'choice' || inputType === 'label' || inputType === 'button'));
    }
    if(type === 'coords' || type === 'surface' || type === 'polyline') {
      this.filteredInputTypes = inputTypes.filter(inputType => !(inputType === 'range' || inputType === 'multiselect' || inputType === 'choice' || inputType === 'label' || inputType === 'button'));
    }
  }

  public initaddEditUniqueidForm() {
    this.addEditUniqueidForm = this.formBuilder.group({
      uniqueid: [this.uniqueid ? this.uniqueid : '']
    })
  }

  public initaddEditAttributeForm(): void {
    this.addEditAttributeForm = this.formBuilder.group({
        name_t: [this.selectedAttribute ? this.selectedAttribute.nameT : null, Validators.required],
        name: [this.selectedAttribute ? this.selectedAttribute.name : null],
        color: [this.selectedAttribute ? this.selectedAttribute.color : '#000000'],
        icon: [this.selectedAttribute ? this.selectedAttribute.icon ? this.selectedAttribute.icon.id : null : null, Validators.required],
        entity: [this.selectedAttribute ? this.selectedAttribute.entity ? this.selectedAttribute.entity.id : null : null, Validators.required],
        input_type: [this.selectedAttribute ? this.selectedAttribute.inputType : 'single', Validators.required],
        unit: [this.selectedAttribute ? this.selectedAttribute.unit : null],
        value_type: [this.selectedAttribute ? this.selectedAttribute.valueType : 'number', Validators.required],
        uniqueid: [this.selectedAttribute ? this.selectedAttribute.uniqueid : '', [Validators.required, noSpecialChars]],
        choices: [this.selectedAttribute ? this.selectedAttribute.choices : [], Validators.required],
    });
    if(this.selectedAttribute) {
      this.validateInputType(this.selectedAttribute.valueType);
      if(this.selectedAttribute.valueType === 'number' && this.selectedAttribute.unit) {
        this.unit = this.selectedAttribute.unit;
      }
    }
  }

  addEditAttribute(attribute?:any) {
    attribute = this.convertKeysToCamelCase(attribute);
    console.log(attribute);
    if(attribute.choices && attribute.choices.length) {
      this.choices = attribute.choices;
    }
    this.addEditAttributeForm.reset();
    this.addEditAttributeForm.clearValidators();
    // this.imgPath = '';
    this.icon = null;
    if(this.selectedAttribute) {
      // this.validateInputType(this.selectedAttribute.valueType);
      this.icon = this.selectedAttribute.icon
      this.imgPath = this.selectedAttribute.icon ? this.selectedAttribute.icon.file : '';
    }
    this.selectedAttribute = attribute;
    this.validateInputType(this.selectedAttribute.valueType);
    this.initaddEditAttributeForm();
    this.addEditAttributePopup.showModal();
  }

  sanitizeHTML(html): any {
    if(html && html !== '') {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
  }

  convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
        camelCaseObj[camelCaseKey] = obj[key];
      }
    }
    return camelCaseObj;
  }
  

  addEditTranslation(label) {
    this.label = label;
      const name = this.nameInput.nativeElement.value;
      const type: any = this.convertKeysToCamelCase(this.selectedAttribute);
      
      if (name === '' || type.nameT === null) {
          this.translation = null;
          this.addEditTranslationsModal.showModal();
          return;
      }

      const translationsMap = this.globalRegistry.systemData.translationsMap;
      const translationId = type?.nameT;

      if (translationId && translationsMap.has(translationId)) {
        const translation = translationsMap.get(translationId);
        this.translation = translation;
        console.log(this.translation);
        this.addEditTranslationsModal.showModal();
      } 
      // else {
      //   this.toastrService.error('Translation not available');
      // }
  
      if (Object.keys(type).length === 0 && name) {
        const newTranslation = this.addEditAttributeForm.controls['name'].value;
        console.log(name);
        console.log(type);
        console.log(newTranslation);
          for (const translation of this.globalRegistry.systemData.translations) {
              if (translation.id === newTranslation) {
                  this.translation = translation;
                  console.log(this.translation);
                  this.addEditTranslationsModal.showModal();
                  return;
              }
          }
      }
  }

  addEditFieldTranslation(label) {
    this.label = label;
    const name = this.nameInput1.nativeElement.value;
    console.log(name);
    const type: any = this.type;
    if(name === null || name === '' || !type[this.selectedEditField]) {
        this.fieldTranslation = null
        this.addEditFieldTranslationPopup.showModal();
    } else {
        this.globalRegistry.systemData.translations.forEach(translation => {
            if(translation.id === type[this.selectedEditField]['id']) {
                this.fieldTranslation = translation;
                console.log(this.fieldTranslation);
                this.addEditFieldTranslationPopup.showModal();
            }
        });
    }
  }

  onAddUpdateTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.addEditAttributeForm.controls['name_t'].setValue(translation.id);
    this.addEditAttributeForm.controls['name'].setValue(translation[this.langString]);
    this.nameT = translation;
    this.addEditTranslationsModal.hideModal();
    // this.selectedAttribute = this.addEditAttributeForm.value;
}

onAddUpdateFieldTranslation(translation) {
    console.log(translation);
    this.fieldTranslation = translation;
    if(this.selectedEditField === 'info') {
      this.type.info = translation;
      this.info = translation;
      this.showInfo = true;
      console.log(this.type);
    } else {
      this.type.name_t = translation;
      this.type.name = translation[this.langString];
      this.formName = translation[this.langString];
      console.log(this.type);
    }
    this.isSubmitEnabled = true;
    this.addEditFieldTranslationPopup.hideModal();
}

  submitAttribute() {
    if(!this.nameT) {
      this.translatedNames.filter(trans => {
        if(trans.id === this.selectedAttribute.nameT) {
          this.nameT = trans;
        }
      });
    }
    console.log(this.nameT);
    const body = {
      name_t: this.nameT.id,
      color: this.addEditAttributeForm.value.color,
      icon: this.addEditAttributeForm.value.icon,
      value_type: this.addEditAttributeForm.value.value_type,
      unit: this.addEditAttributeForm.value.unit,
      input_type: this.addEditAttributeForm.value.input_type,
      entity: this.addEditAttributeForm.value.entity,
      uniqueid: this.addEditAttributeForm.value.uniqueid,
      choices: this.choices
    }
    if(this.selectedAttribute && this.selectedAttribute.id) {
    console.log(this.selectedAttribute);
      this.typeService.updateAttribute(this.selectedAttribute.id, body).subscribe(data => {
        this.globalRegistry.reloadAttributes();
        this.toastrService.success(this.translateService.instant('attribute.formTypeUpdatedSuccessfully'))
        this.addEditAttributePopup.hideModal();
        this.nameT = null
        this.icon = null;
        this.selectedAttribute = null;
        this.selectedAttribute = data.body.results;
        let foundAttribute = this.selectedFields.find(field => field.id === this.selectedAttribute.id);
        if (foundAttribute) {
          Object.assign(foundAttribute, this.selectedAttribute);
        }
        this.addEditAttributeForm.reset();
        this.addEditAttributeForm.clearValidators();
        this.initaddEditAttributeForm();
        this.inputType = this.selectedAttribute['input_type'];
        this.valueType = this.selectedAttribute['value_type'];
        this.filterComponents();
      })
    } else {
      this.typeService.createAttribute(body).subscribe(data => {
        this.globalRegistry.reloadAttributes();
        this.toastrService.success(this.translateService.instant('attribute.fieldTypeCreatedSuccessfully'))
        this.addEditAttributePopup.hideModal();
        this.nameT = null;
        this.icon = null;
        this.selectedAttribute = null;
        this.selectedAttribute = data.body.results;
        // this.fields.push(data.body.results);
        this.newFormFieldTypes.push(data.body.results.id);
        this.selectedFields.push(data.body.results.id);
        this.addEditAttributeForm.reset();
        this.addEditAttributeForm.clearValidators();
        this.initaddEditAttributeForm();
        this.inputType = this.selectedAttribute['input_type'];
        this.valueType = this.selectedAttribute['value_type'];
        this.filterComponents();
      })   
    }
  }

  // onDragStart(event: DragEvent, index: number): void {
  //   // Store the index of the dragged item
  //   this.dragItemIndex = index;
  // }

  // onDragOver(event: DragEvent): void {
  //   event.preventDefault();
  // }

  onDragEnter(event: DragEvent): void {
    event.preventDefault();
  }

  calculateOrder(item: any): number {
    if (this.defaultSize === 'pc') {
      return item?.pc?.pos;
    } else if (this.defaultSize === 'tablet') {
      return item?.tablet?.pos;
    } else {
      return item?.mobile?.pos;
    }
  }

  public deleteFormType(formId) {
    console.log(formId);
    // return;
    this.typesService.deleteFormType(formId).subscribe(data => {
      console.log(data);
      this.toastrService.success(this.translateService.instant('attribute.formTypeDeletedSuccessfully'));
      this.type = null;
      this.selectedField = null;
      this.fields = [];
      this.mixedFields = [];
      this.getFormTypes();
    }, (error) => {
      console.log(error);
      this.toastrService.error(this.translateService.instant('attribute.formTypeDeleteError'));
    })
  }

  isEqual(obj1: any, obj2: any): boolean {
    const sortedString1 = JSON.stringify(obj1, Object.keys(obj1).sort());
    const sortedString2 = JSON.stringify(obj2, Object.keys(obj2).sort());
    return sortedString1 === sortedString2;
  }

  // onDrop(event: CdkDragDrop<any>, type: string): void {
  //   const oldFields = cloneDeep(this.fields);
  
  //   if (this.type.locked) {
  //     return;
  //   }
  
  //   if (event.previousContainer === event.container) {
  //     const prevIndex = event.previousIndex;
  //     const currentIndex = event.currentIndex;
  
  //     if (prevIndex !== currentIndex) {
  //       [this.fields[prevIndex], this.fields[currentIndex]] = [this.fields[currentIndex], this.fields[prevIndex]];
  
  //       this.fields.forEach((item, index) => {
  //         if (!item[type]) {
  //           item[type] = {};
  //         }
  //         item[type].pos = index + 1;
  //       });
  
  //       this.isSubmitEnabled = true;
  //     }
  //   }
  
  //   console.log('old fields copy', oldFields);
  //   console.log('new fields', this.fields);

  //   const changedFields = this.fields.filter((newField, index) => {
  //     const oldField = oldFields[index];
  //     return !this.isEqual(oldField, newField);
  //   });
  
  //   console.log('Changed fields:', changedFields);
  //   if(changedFields.length) {
  //     changedFields.forEach(changedField => {
  //       this.updateFieldCss(changedField);
  //     });
  //   }
  // }

  updateFieldCss(field) {
    if(field.field) {
    let body = {
      id: field.id,
      field: field.field.id,
      form: this.type.id,
      pc: field.pc,
      tablet: field.tablet,
      mobile: field.mobile,
      validation: field.validation,
      group: field.group
    }
    console.log(body)
    this.typesService.updateFormFieldType(field.id, body).subscribe(data => {
      console.log(data);
    })
  } else {
    console.log(field);
  }
  }
  
  editImage() {
    if(this.type.locked) {
      return
    }
    this.selectedEditField = 'image';
    this.image = this.type.image;
    if(this.bgImg) {
      this.imgPath = this.bgImg.file
    } else {
      this.imgPath = '';
    }
  }

  edit(field) {
    if(this.type.locked) {
      return
    }
    this.selectedEditField = field;
    if(field === 'name_t') {
      this.selectedEditFieldLabel = 'Name'
    } else {
      this.selectedEditFieldLabel = 'Info'
    }
    console.log(this.type[field]);
    this.editFieldModal.showModal();
  }

  editUniqueid(uniqueid) {
    console.log(uniqueid);
    this.uniqueid = uniqueid;
    this.addEditUniqueidForm.get('uniqueid').setValue(uniqueid);
    this.editUniqueidModal.showModal();
  }

  updateUniqueId(ev) {
    console.log(ev);
  }
  
  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  handleFileInput($event) {
    console.log($event)
  }

  addImage(ev) {
    console.log(ev);
    const imgObj = {
        id: ev.value.id,
        file: ev.value.file
    }
    this.showImg = true;
    this.image = imgObj;
    this.imgPath = imgObj.file;
    this.bgImg = this.image;
    // this.type.image = this.image;
    this.type.form_css[this.defaultSize] ? this.type.form_css[this.defaultSize].banner.image = this.image : '';
    this.isSubmitEnabled = true;
  }
  
  deleteNoteImage() {
    this.showImg = false;
    this.image = null;
    this.bgImg = null;
    this.type.image = null;
    this.isSubmitEnabled = true;
  }

    public selectUnit(unit?: UnitModel, parent?:  UnitModel, genre?: string): void {
      console.log(unit);
      // if(unit?.locked) {
      //   return;
      // }
      this.currentUnit = unit;
      this.parentUnit = parent ? parent : null;
      if(genre) {
        console.log(genre);
        this.units = this.globalRegistry.systemData.units.filter(unit => {
          return unit.genre == genre
        });
      }
      this.initunitForm();
      this.addEditUnitModal.showModal();
    }
    
    public onDeleteUnit(unit: UnitModel): void {
      if(unit?.locked) {
        return;
    }
     
      this.confirmModalService.openConfirmModal(
          this.translateService.instant('confirmDelete'),
          this.translateService.instant('units.deleteUnitConfirmMessage')
      );
      this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
          if (confirmed) {
              this.deleteUnit(unit.id);
          }
      });
    }
    
    public onUnitAction(unitId: number): void {
      this.selectedUnitId = unitId;
      this.addEditUnitModal.hideModal();
      this.unitsCombo.close();
    }

    public submit(): void {

      if(this.unitForm.dirty) {
          const unit: UnitInterface = {
              name: this.unitForm.controls['name'].value,
              shortName: this.unitForm.controls['shortName'].value,
              genre: this.unitForm.controls['genre'].value
          };
    
          if(typeof this.unitForm.controls['parent'].value !== 'string') {
              unit.parent = this.unitForm.controls['parent'].value === 'new' ?
                  null :
                  this.unitForm.controls['parent'].value;
          }
    
          if(this.currentUnit) {
              this.updateUnit({...{id: this.currentUnit.id}, ...unit});
              // this.globalRegistry.reloadUnits();
          }
          else {
              this.addUnit(unit);
              // this.globalRegistry.reloadUnits();
          }
          this.getUnits()
      }
    }
   
    public getUnits() {
      this.subscriptions.push(this.unitService.getUnits().subscribe(data => {
        this.units = data.model;
      }))
    }
    
    private initunitForm(): void {
      this.unitForm = this.formBuilder.group({
          name: [this.currentUnit ? this.currentUnit.name : '', Validators.compose([
                  Validators.required,
                  Validators.minLength(3),
                  Validators.maxLength(100)
              ])
          ],
          shortName: [this.currentUnit ? this.currentUnit.shortName : '', Validators.compose([
                  Validators.required,
                  Validators.minLength(1),
                  Validators.maxLength(100)
              ])
          ],
          parent: [this.currentUnit && this.parentUnit ? this.parentUnit.id : '', Validators.compose([
                  Validators.required
              ])
          ],
          genre: [this.currentUnit && this.currentUnit.genre ? this.currentUnit.genre : '', Validators.compose([
                  Validators.required
              ])
          ]
      });
    }
    
    private addUnit(unit: UnitInterface): void {
      this.unitService.addUnit(unit).subscribe((response: ResponseModel<UnitModel>): void => {
          this.toastrService.success(this.translateService.instant('units.unitAddSuccessfull'));
          this.globalRegistry.reloadUnits();
          this.onUnitAction(response.model.id);
          this.selectedUnit = response.model;
          this.addEditAttributeForm.get('unit').setValue(response.model.id)
      },
      (): void => {
          this.toastrService.error(this.translateService.instant('units.unitAddError'));
      });
    }
    
    private updateUnit(unit: UnitInterface): void {
      this.unitService.updateUnit(unit).subscribe((response: ResponseModel<UnitModel>): void => {
          this.toastrService.success(this.translateService.instant('units.unitUpdateSuccessfull'));
          this.globalRegistry.reloadUnits();
          this.onUnitAction(this.currentUnit.id);
          this.selectedUnit = response.model;
          this.addEditAttributeForm.get('unit').setValue(response.model.id)
      },
      (): void => {
          this.toastrService.error(this.translateService.instant('units.UnitUpdateError'));
      });
    }
    
    public deleteUnit(unitId: number): void {
      this.unitService.deleteUnit(unitId).subscribe((): void => {
          this.toastrService.success(this.translateService.instant('units.unitDeleteSuccessfull'));
          this.globalRegistry.reloadUnits();
      },
      (): void => {
          this.toastrService.error(this.translateService.instant('units.UnitDeleteError'));
      });
    }

    deleteAttribute(ev) {
      this.typeService.deleteAttribute(ev).subscribe(()=>{
        this.globalRegistry.reloadAttributes();
        this.addEditAttributePopup.hideModal();
        this.nameT = null;
        this.toastrService.success(this.translateService.instant('attribute.fieldTypeDeletedSuccessfully'))
      }, err => {
        this.toastrService.error(err.error.results.error);
      });
    }
    
    public ifAddEditUnitsFormControlHasError(controlName: string, validationType: string): boolean {
      const control: any = this.unitForm.controls[controlName];
      if (!control) {
          return false;
      }
    
      const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }

    updateSelectedTabIndex(): void {
      if (window.innerWidth < 768) {
        // Set the selected tab index to 1 for smaller screens (Mobile tab)
        this.selectedTabIndex = 1;
      } else {
        // Set the selected tab index to 0 for larger screens (PC tab)
        this.selectedTabIndex = 0;
      }
    }

    async removeFormFieldType(formFieldTypeId: number, field: any) {
      
        this.typeService.getFormFieldTypePresence(formFieldTypeId).subscribe(data => {
          if (data.body.results.forms > 0) {
            this.toastrService.warning('The Field "' +field.name+ '" has been already filled in '+data.body.results.forms+' forms');
            // this.confirmModalService.openConfirmModal(
            //   this.translateService.instant('confirmDelete'),
            //   'The Field "' +field.name+ '" contains '+data.body.results.forms+' instances of input data that will be removed ! Please confirm this critical operation! '
            //   // this.translateService.instant('forms.deleteFormFieldMessage')
            // );
            this.deleteFieldId = formFieldTypeId;
            this.deleteFieldName = field.name;
            this.criticalDeleteModal.showModal();
          } else {
            this.confirmRemoveFormFieldType(formFieldTypeId);
          }
        })
  } 
  
  public confirmRemoveFormFieldType(formFieldTypeId) {
    console.log(formFieldTypeId);
                this.selectedFields = this.selectedFields.filter(field => field.id !== formFieldTypeId);
                this.fields = this.fields.filter(field => field.id !== formFieldTypeId);
                console.log(this.type);
                console.log(this.fields);
                console.log(this.selectedFields);
                // this.fields = this.selectedFields;
            let removedFromTopLevel = false;
            this.mixedFields = this.mixedFields.filter(item => {
                if (item.id === formFieldTypeId) {
                    removedFromTopLevel = true;
                    return false;
                }
                return true;
            });
            if (!removedFromTopLevel) {
                this.mixedFields.forEach(group => {
                    if (group.nestedFields) {
                        group.nestedFields = group.nestedFields.filter(nestedItem => nestedItem.id !== formFieldTypeId);
                    }
                });
            }
            console.log(this.mixedFields);
            this.isSubmitEnabled = true;
            this.collectAttributes(this.selectedFields);
            let types_css = this.fields;
            this.type.fields_css = types_css;
            console.log(this.type);
            // this.updateFormType();
            this.cdr.detectChanges();
  }

    private deleteFormFieldType(field: any): Promise<void> {
      return new Promise<void>(() => {
        if(field.used === false) {
          // this.confirmDeleteFormFieldType(field.id);
          this.type.fields_css = this.type.fields_css.filter(formField => formField.id !== field.id);
          this.fields = this.type.fields_css;
          const updatedItems = this.removeFormFieldTypes.findIndex(rfield => rfield.id === field.id);
          console.log(updatedItems);
          if (updatedItems === 0) {
            this.isSubmitEnabled = false;
          }

          console.log(this.removeFormFieldTypes);
          return
        } else 
        this.typeService.getFormFieldTypePresence(field.id).subscribe(data => {
          console.log(data);
          if (data.body.results.forms > 0) {
            this.confirmModalService.openConfirmModal(
              this.translateService.instant('confirmDelete'),
              'The Field "' +field.field.name+ '" contains '+data.body.results.forms+' instances of input data that will be removed ! Please confirm this critical operation! '
              // this.translateService.instant('forms.deleteFormFieldMessage')
            );
    
            this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
              if (confirmed) {
                // this.updateFormType()
                //   .then(() => resolve())
                //   .catch(error => reject(error));
                this.fields = this.fields.filter(item => item.id !== field.id);
                this.type.fields_css = this.fields;
                this.removeFormFieldTypes = [];
                console.log(this.removeFormFieldTypes);
                if(this.removeFormFieldTypes.length === 0) {
                  this.isSubmitEnabled = false;
                }
                this.updateFormType();
              } else {
                let copiedSelectedFields = this.selectedFields.slice();
                this.selectedFields = [];
                this.selectedFields = copiedSelectedFields;
                console.log(this.selectedFields);
                // this.fields.push(field);
                this.selectedFields.push(field.field.id);
                this.selectedFields = [...new Set(this.selectedFields)];
                this.fields.push(...this.removeFormFieldTypes);
                this.removeFormFieldTypes = [];
                console.log(this.removeFormFieldTypes);
                if(this.removeFormFieldTypes.length === 0) {
                  this.isSubmitEnabled = false;
                }
                console.log(this.fields);
                return
              }
            });
          } else {
            // this.confirmDeleteFormFieldType(formFieldTypeId)
            //   .then(() => resolve())
            //   .catch(error => reject(error));
            this.removeFormFieldTypes = this.removeFormFieldTypes.filter(rfield => rfield.id !== field.id);
                console.log(this.removeFormFieldTypes);
                if(this.removeFormFieldTypes.length === 0) {
                  this.isSubmitEnabled = false;
                }
            this.fields = this.fields.filter(item => item.id !== field.id);
            this.type.fields_css = this.fields
            this.updateFormType();
          }
        });
      });
    }    
    
    public confirmDeleteFormFieldType(formFieldTypeId: number): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        this.typeService.deleteFormFieldType(formFieldTypeId).subscribe(
          data => {
            this.selectedFields = this.selectedFields.filter(item => item !== formFieldTypeId);
            this.isSubmitEnabled = true;
            this.removeFormFieldTypes = this.removeFormFieldTypes.filter(rfield => rfield.id !== formFieldTypeId);
                console.log(this.removeFormFieldTypes);
                if(this.removeFormFieldTypes.length === 0) {
                  this.isSubmitEnabled = false;
                }
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    }   
    
    public addNewImage(newImage) {
      this.images.unshift(newImage);
      console.log(newImage);
      // this.type.form_css[this.defaultSize].banner.image = newImage;
      // this.image = newImage;
    }

    public addIcon(event) {
      this.addEditAttributeForm.get('icon').setValue(event.value.id);
    }

    updateChoices(choices) {
      this.choices = choices;
    }

  ngOnDestroy() {
    this.mapService.showMap();
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
