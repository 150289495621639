<div class="dashboard-card dash_cont">
    <div class="d-flex justify-content-between">
        <div class="col-md-4 pl-0">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-6 col_lot_name">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em
      > -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>Settings</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="w-100">
        <fap-attributes
            [entityRelation]="''"
            [entityId]="formType?.id"
            [entityInfo]="formType"
            [objectId]="sensor?.id"
            [getByType]="true"
            [formTypeId]="formType?.id"
            [showHeader]="false"
            (formTypeEmit)="form = $event"
            (formFieldsEmit)="formFields = $event"
            >
        </fap-attributes>
    </div>
</div>
