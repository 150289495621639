<fap-header>
    <fap-header-actions [title]="'leftMenu.notes' | translate" [isDeleteEnabled]="noteId ? true : false"></fap-header-actions>
</fap-header>
<div class="container-fluid nc" style="padding: 10px;">
    <fap-edit-notes
             [lots]="lots"
             [farms]="farms"
             [types]="types"
             [note]="note"
             [noteId]="noteId"
             [notes]="notes"
             [planTypes]="planTypes"
             (submitNote)="submitNote($event)"
             (updateNote)="updateNote($event)"
             (deleteNote)="deleteNote($event)"
             (scrolledDownNotes)="scrolledDownNotes()"
             (filterNotes)="filterNotes($event)"
             >
    </fap-edit-notes>
</div>
