<div class="generic_modal">
    <div class="modal_wrapper">
        <div class="search_filters">
            <form class="generic_search" [formGroup]="filterForm" [ngClass]="showFilters ? 'show' : ''">
                <div class="form-group m-0">
                    <div class="row align-items-center">
                        <div class="col-10 p-0">
                            <div class="row search_form">
                                <div class="col-6">
                                    <div class="input-group align-items-baseline w-100">
                                    </div>
                                    <mat-form-field class="w-100">
                                      <mat-label class="col-form-label">{{ 'stock.search' | translate }}</mat-label>
                                      <input
                                          type="text"
                                          matInput
                                          formControlName="search"
                                          (keyup)="emitSearch($event)"
                                          (inputCleared)="emitSearch('')"
                                          #searchInput
                                      >
                                  </mat-form-field>
                                </div>
                            </div>
                            <ng-content></ng-content>
                        </div>
                        <div class="col-2 p-0 mt-3" *ngIf="addEnabled">
                            <div class="text-center w-100 d-flex justify-content-end add_btn">
                                <fap-round-button (click)="outputOnCreate.emit()"></fap-round-button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        
    <div class="ov_auto" [ngClass]="setCustomHeight ? 'custom_height' : ''">
            <div class="custom_table table-responsive" [ngClass]="count > pageSize ? 'with_pagination' : ''" [ngStyle]="{ 'height': tableHeight }">
                <table class="w-100">
                    <thead>
                        <tr class="rel_box">
                            <th *ngIf="multiple">
                                <div>
                                    <input type="checkbox" [(ngModel)]="selectAll" (change)="toggleSelectAll()" />
                                    <span></span>
                                </div>
                              </th>
                            <th *ngFor="let field of headerFields">
                                <span *ngIf="field === 'id'">ID</span>
                                <span *ngIf="field !== 'id'" [ngClass]="{ 'is_id': field === 'id' }">{{ field | titlecase }}</span>
                            </th>
                            <th *ngIf="enableActions" class="actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of items; let i = index" class="rel_box">
                            <td *ngIf="multiple">
                                <div>
                                    <input 
                                    type="checkbox" 
                                    [checked]="isItemSelected(item)" 
                                    (change)="toggleSelection(item)"   
                                    />
                                    <span></span>
                                </div>
                              </td>
                          <td *ngFor="let field of displayFields; let i = index" [ngClass]="{ 'is_id': field === 'id' }">
                            <ng-container *ngIf="field === 'id'; else noId">
                              {{ item?.id }}
                            </ng-container>
                            <ng-template #noId>
                                <ng-container *ngIf="item?.label; else noLabel">
                                  <fap-product-tag (click)="selectItem(item); $event.stopPropagation();"
                                    [productName]="item?.label?.name" 
                                    [productType]="item?.label?.type">
                                  </fap-product-tag>
                                </ng-container>
                                
                                <ng-template #noLabel>
                                  
                                  <!-- Handle the case when both selectField and pipeUsedBy are the same -->
                                  <ng-container *ngIf="field === pipeUsedBy && field === selectField; else singleCondition">
                                    <span class="cursor-pointer" (click)="selectItem(item); $event.stopPropagation();">
                                      {{ item?.[field] | dynamicPipe : pipe : pipeArgs }}
                                    </span>
                                  </ng-container>
                                  
                                  <!-- When only one of the conditions applies -->
                                  <ng-template #singleCondition>
                                    
                                    <!-- If only pipeUsedBy applies -->
                                    <ng-container *ngIf="field === pipeUsedBy; else normalField">
                                      {{ item?.[field] | dynamicPipe : pipe : pipeArgs }}
                                    </ng-container>
                                    
                                    <!-- If only selectField applies -->
                                    <ng-template #normalField>
                                      <ng-container *ngIf="field === selectField; else otherField">
                                        <span class="cursor-pointer" (click)="selectItem(item)">
                                          {{ item?.[field] }}
                                        </span>
                                      </ng-container>
                                    </ng-template>
                                    
                                    <!-- Default handling for fields that don't match selectField or pipeUsedBy -->
                                    <ng-template #otherField>
                                        {{ getNestedValue(item, field) }}
                                    </ng-template>
                          
                                  </ng-template>
                                </ng-template>
                            </ng-template>
                            
                          </td>
                          
                          <td *ngIf="enableActions">
                            <action-buttons 
                                [locked]="item?.locked" 
                                [isEditable]="isEditable" 
                                [isDeleteable]="isDeleteable"
                                (editEmit)="outputOnEdit.emit(item)" 
                                (deleteEmit)="outputOnDelete.emit(item)">
                            </action-buttons>
                          </td>
                          
                        </tr>
                      </tbody>
                      
                      
                </table>
            </div>
            <fap-pagination *ngIf="count > pageSize"
                [count]="count"
                [pageSize]="pageSize"
                (emitNextPrev)="handlePagination.emit($event)"
            ></fap-pagination>
        </div>
    </div>
</div>