import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { ObjectModel } from '../../../core/services/api/objects/object.service';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';

@Component({
  selector: 'fap-sensor-formdata-widget',
  templateUrl: './fap-sensor-formdata-widget.component.html',
  styleUrls: ['./fap-sensor-formdata-widget.component.scss']
})
export class FapSensorFormdataWidgetComponent {

  @Input() public langString: string;
  @Input() public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() sensors: SensorModel[] = [];
  @Input() formTypes: any[] = [];

  @Output()
  public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public updateSensor: EventEmitter<any> = new EventEmitter<any>();

  public selectedWidget;
  public sensor = null;
  public showMenu = true;
  public toggleMain = false;
  public formType = null;
  public entityInfo;
  public form;
  public formFields;

  constructor(public globalRegistry: GlobalRegistryService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.prototype.hasOwnProperty.call(changes, 'widget') && this.widget) {
      this.selectedWidget = this.widget;
      console.log(this.widget);
      if(this.sensors.length > 0) {
        this.sensor = this.sensors.find((s) => s.id === this.widget.settings['sensors'][0].id);
        console.log(this.sensor);
        this.getFormType(this.sensor.type.entity_form);
      }
    }

    if(Object.hasOwnProperty('sensors') && this.sensors.length > 0) {
      console.log(this.sensors);
    }
  }

  getFormType(formTypeId: number) {
    this.formType = this.formTypes.find((f) => f.id === formTypeId);
    console.log(this.formType);
  }

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(
        (trans) => {
            return trans.id === translation;
        }
    );
    if (t[0]) {
        if (
            t[0][this.langString] === null ||
            t[0][this.langString] === ''
        ) {
            return translation;
        } else {
            return t[0][this.langString];
        }
    } else {
        return translation;
    }
  }

  hideMain() {
    this.toggleMain = false;
  }

  toggleMenu() {
    this.toggleMain = !this.toggleMain;
  }

  public toggleImageMenu() {
    this.showMenu = !this.showMenu;
  }

  public getLotName(lotId: number = this.widget.objectId): string {
    const lot = this.globalRegistry.systemData.lots.find(
        (lot) => lot.id === lotId
    );
    return lot ? lot.name : '';
  }

}
