import { EquipmentTypeInterface } from './../../../interfaces/equipment/equipment-type.interface';
import { IngredientTypeModel } from '../../../models/type/ingredient-type.model';
import { FapRestBaseService } from './../../../base/fap-rest-base.service';
import { NoteTypeModel } from './../../../models/type/note-type.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { FapAPIRequestOptions } from '../fap-api-request.options';
import { ResponseModel } from '../../../models/response.model';
import { CropTypeModel } from '../../../models/type/crop-type.model';
import { CropTypeInterface } from './data/crop-type.interface';
import { EquipmentTypeModel } from '../../../models/type/equipment-type.model';
import { ActivityTypeModel } from '../../../models/activity/activity-type.model';
import { take } from 'rxjs/operators';
import { ResourceTypeModel } from '../../../models/type/resource-type.model';
import { ResourceTypeInterface } from '../../../interfaces/type/resource-type.interface';
import { FapBaseModel } from '../../../base/fap-base-model';
import { TranslationModel } from '../../../models/type/translation-type.model';
import { BaseTypeModel } from '../../../models/base/base-type.model';
import { AttributeModel } from '../../../models/attribute/attribute.model';
import { WidgetTypeModel } from '../../../models/type/widget-type.model';
import { PlanTypeModel } from '../../../models/company/plan.type.model';
import { ProductUsageTypeModel } from '../../../models/stock/product_usage_type.model';

export class DeviceTypeModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'color',
        'name',
        'icon',
        'name_t',
        'locked',
        'data'
    ];
    public static requiredFields: Array<string> = [
        'id',
    ];
    public static extendedFields: {} = { };
    public id: number;
    public color: string;
    public name: string;
    public icon: any;
    public name_t: string;
    public locked: boolean;
    public data: any;
  }
@Injectable()
export class TypesService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/type/');
    }

    private formTypes = new BehaviorSubject([]);
    getHeaderFormTypes = this.formTypes.asObservable();
    
    private workflowTypes = new BehaviorSubject([]);
    getHeaderWorkflowTypes = this.workflowTypes.asObservable();

    private workflowTypeId = new BehaviorSubject(0);
    getHeaderWorkflowTypeId = this.workflowTypeId.asObservable();

    private cropTypes = new BehaviorSubject([]);
    getHeaderCropTypes = this.cropTypes.asObservable();

    private cropTypeId = new BehaviorSubject(0);
    getHeaderCropTypeId = this.cropTypeId.asObservable();

    public setFormTypes(data) {
        this.formTypes.next(data);
    }

    public setWorkflowTypes(data) {
        this.workflowTypes.next(data);
    }

    public setWorkflowTypeId(data) {
        this.workflowTypeId.next(data);
    }

    public setCropTypes(data) {
        this.cropTypes.next(data);
    }

    public setCropTypeId(data) {
        this.cropTypeId.next(data);
    }

    getUrl(slug) {
        return this.url+slug
    }

    public getAttributes(): Observable<ResponseModel<AttributeModel[]>> {
        return this.mapRequest<AttributeModel[]>(
            this.http.get(this.url + 'field/', FapAPIRequestOptions.withTokenRequestOptions),
            AttributeModel,
            true
        ).pipe(take(1));
    }

    public deleteAttribute(attributeId: number): Observable<{}> {
        return this.mapRequest(
            this.http.delete(this.url + 'field/' + attributeId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)).pipe(take(1));
    }

    public updateAttribute(attributeId: number, attribute: any): Observable<ResponseModel<AttributeModel>> {
        return this.mapRequest<AttributeModel>(
            this.http.patch(this.url + 'field/' + attributeId + '/',
                this.camelCaseModelToSnakeCaseJson(attribute),
                FapAPIRequestOptions.withTokenRequestOptions),
            AttributeModel,
            false
        ).pipe(take(1));
    }

    public createAttribute(attribute: any): Observable<ResponseModel<AttributeModel>> {
        return this.mapRequest<AttributeModel>(
            this.http.post(this.url + 'field/',
                this.camelCaseModelToSnakeCaseJson(attribute),
                FapAPIRequestOptions.withTokenRequestOptions),
            AttributeModel,
            false
        ).pipe(take(1));
    }

    public getTranslations(): Observable<ResponseModel<TranslationModel[]>> {
        return this.mapRequest<TranslationModel[]>(
            this._http.get(this.url + 'translate/',
                FapAPIRequestOptions.withTokenRequestOptions),
                TranslationModel,
            true
        ).pipe(take(1));
    }

    public getTranslationByKey(id: string): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this._http.get(this.url + 'translate/' + id + '/',
                FapAPIRequestOptions.withTokenRequestOptions),
            false
        ).pipe(take(1));
    }

    public postTranslations(data): Observable<ResponseModel<TranslationModel>> {
        return this.mapRequest<TranslationModel>(
            this._http.post(this.url + 'translate/', data,
                FapAPIRequestOptions.withTokenRequestOptions),
                TranslationModel,
            false
        ).pipe(take(1));
    }

    public updateTranslations(id, data): Observable<ResponseModel<TranslationModel>> {
        return this.mapRequest<TranslationModel>(
            this._http.patch(this.url + 'translate/' + id + '/', data,
                FapAPIRequestOptions.withTokenRequestOptions),
                TranslationModel,
            false
        ).pipe(take(1));
    }

    public deleteTranslations(translationId: string): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.delete(this.url + 'translate/' + translationId + '/',
                FapAPIRequestOptions.withTokenRequestOptions),
        ).pipe(take(1));
    }

    public getCropTypes(): Observable<ResponseModel<CropTypeModel[]>> {
        return this.mapRequest<CropTypeModel[]>(
            this._http.get(this.url + 'crop/',
                FapAPIRequestOptions.withTokenRequestOptions),
                CropTypeModel,
            true
        ).pipe(take(1));
    }

    public getIngredientTypes(): Observable<ResponseModel<IngredientTypeModel[]>> {
        return this.mapRequest<IngredientTypeModel[]>(
            this._http.get(this.url + 'ingredient/',
                FapAPIRequestOptions.withTokenRequestOptions),
                IngredientTypeModel,
            true
        ).pipe(take(1));
    }

    public getProductUsageTypes(): Observable<ResponseModel<ProductUsageTypeModel[]>> {
        return this.mapRequest<ProductUsageTypeModel[]>(
            this._http.get(this.url + 'product_usage/',
                FapAPIRequestOptions.withTokenRequestOptions),
                ProductUsageTypeModel,
            true
        ).pipe(take(1));
    }

    public getInfoSections(): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'info_section/',
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                false
        ).pipe(take(1));
    }

    public getInfoSectionById(id: number): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this.http.get(this.url + 'info_section/' + id + '/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
            }),
            false,
            false
        ).pipe(take(1));
    }

    public createInfoSection(params?:any): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
                this._http.post(this.url + 'info_section/', params,
                    FapAPIRequestOptions.onlyTokenRequestOptions
                ),
            false
        ).pipe(take(1));
    }

    public updateInfoSection(infoSectionId: number, infoSection:any): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this._http.patch(this.url + 'info_section/' + infoSectionId + '/',
                this.camelCaseModelToSnakeCaseJson(infoSection),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            false,
            false
        ).pipe(take(1));
    }

    public deleteInfoSection(infoSectionId: number): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this.http.delete(this.url + 'info_section/' + infoSectionId + '/',
                FapAPIRequestOptions.withTokenRequestOptions
            )).pipe(take(1));
    }

    public deleteCropType(cropTypeId: number): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this.http.delete(this.url + 'crop/' + cropTypeId + '/',
                FapAPIRequestOptions.withTokenRequestOptions
            )).pipe(take(1));
    }

    public deleteIngredientType(ingredientTypeId: number): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this.http.delete(this.url + 'ingredient/' + ingredientTypeId + '/',
                FapAPIRequestOptions.withTokenRequestOptions
            )).pipe(take(1));
    }

    public deleteEquipmentType(equipmentTypeId: number): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this.http.delete(this.url + 'equipment/' + equipmentTypeId + '/',
                FapAPIRequestOptions.withTokenRequestOptions
            )).pipe(take(1));
    }

    public addCropType(cropType: CropTypeInterface): Observable<ResponseModel<CropTypeModel>> {
        return this.mapRequest<CropTypeModel>(
            this._http.post(this.url + 'crop/',
                this.camelCaseModelToSnakeCaseJson(cropType),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            CropTypeModel,
            false
        ).pipe(take(1));
    }

    public addIngredientType(ingredientType: FormData): Observable<{}> {
        return this.mapRequest(
            this._http.post(this.url + 'ingredient/', ingredientType,
                FapAPIRequestOptions.onlyTokenRequestOptions
            )
        ).pipe(take(1));
    }

    public updateCropType(cropType: CropTypeInterface): Observable<ResponseModel<CropTypeModel>> {
        return this.mapRequest<CropTypeModel>(
            this._http.patch(this.url + 'crop/' + cropType.id + '/',
                this.camelCaseModelToSnakeCaseJson(cropType),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            CropTypeModel,
            false
        ).pipe(take(1));
    }

    public updatengredientType(ingredientType: FormData, ingredientTypeId: number): Observable<{}> {
        return this.mapRequest(
            this._http.put(this.url + 'ingredient/' + ingredientTypeId + '/', ingredientType,
                FapAPIRequestOptions.onlyTokenRequestOptions
            )
        ).pipe(take(1));
    }

    public getEquipmentTypes(params?: {}): Observable<ResponseModel<EquipmentTypeModel[]>> {
        return this.mapRequest<EquipmentTypeModel[]>(
            this._http.get(this.url + 'equipment/',
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
            EquipmentTypeModel,
            true
        ).pipe(take(1));
    }

    public updateEquipmentType(equipmentTypeId: number, equipmentType: EquipmentTypeInterface): Observable<ResponseModel<EquipmentTypeModel>> {
        return this.mapRequest<EquipmentTypeModel>(
            this._http.patch(this.url + 'equipment/' + equipmentTypeId + '/',
                this.camelCaseModelToSnakeCaseJson(equipmentType),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            EquipmentTypeModel
        ).pipe(take(1));
    }

    public createEquipmentType(equipmentType: EquipmentTypeInterface): Observable<ResponseModel<EquipmentTypeModel>> {
        return this.mapRequest<EquipmentTypeModel>(
            this._http.post(this.url + 'equipment/',
                this.camelCaseModelToSnakeCaseJson(equipmentType),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            EquipmentTypeModel
        ).pipe(take(1));
    }

    public getActivityTypes(params?: {}): Observable<ResponseModel<ActivityTypeModel[]>> {
        return this.mapRequest<ActivityTypeModel[]>(
            this._http.get(this.url + 'activity/',
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
                ActivityTypeModel,
            true
        ).pipe(take(1));
    }

    public getNoteTypes(params?: {}): Observable<ResponseModel<NoteTypeModel[]>> {
        return this.mapRequest<NoteTypeModel[]>(
            this._http.get(this.url + 'note/',
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
                NoteTypeModel,
            true
        ).pipe(take(1));
    }

    public getPlanTypes(params?: {}): Observable<ResponseModel<PlanTypeModel[]>> {
        return this.mapRequest<PlanTypeModel[]>(
            this._http.get(this.url + 'plan/',
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
                PlanTypeModel,
            true
        ).pipe(take(1));
    }

    public addActivityType(activityType: FormData): Observable<ResponseModel<ActivityTypeModel>> {
        return this.mapRequest<ActivityTypeModel>(
            this._http.post(this.url + 'activity/',
            activityType,
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            ActivityTypeModel
        ).pipe(take(1));
    }

    public addNoteType(noteType: FormData): Observable<ResponseModel<NoteTypeModel>> {
        return this.mapRequest<NoteTypeModel>(
            this._http.post(this.url + 'note/',
            noteType,
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            NoteTypeModel
        ).pipe(take(1));
    }

    public addProductUsageType(productUsageType: FormData): Observable<ResponseModel<ProductUsageTypeModel>> {
        return this.mapRequest<ProductUsageTypeModel>(
            this._http.post(this.url + 'product_usage/',
                productUsageType,
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            ProductUsageTypeModel
        ).pipe(take(1));
    }

    public addplanType(planType: FormData): Observable<ResponseModel<PlanTypeModel>> {
        return this.mapRequest<PlanTypeModel>(
            this._http.post(this.url + 'plan/',
            planType,
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            PlanTypeModel
        ).pipe(take(1));
    }

    public updateActivityType(activityType: FormData, activityTypeId: number): Observable<ResponseModel<ActivityTypeModel>> {
        return this.mapRequest<ActivityTypeModel>(
            this._http.put(this.url + 'activity/' + activityTypeId + '/',
            activityType,
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            ActivityTypeModel
        ).pipe(take(1));
    }

    public updateNoteType(noteType: FormData, noteTypeId: number): Observable<ResponseModel<NoteTypeModel>> {
        return this.mapRequest<NoteTypeModel>(
            this._http.put(this.url + 'note/' + noteTypeId + '/',
                noteType,
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            NoteTypeModel
        ).pipe(take(1));
    }

    public updateProductUsageType(productUsageType: FormData, productUsageTypeId: number): Observable<ResponseModel<ProductUsageTypeModel>> {
        return this.mapRequest<ProductUsageTypeModel>(
            this._http.put(this.url + 'product_usage/' + productUsageTypeId + '/',
                productUsageType,
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            ProductUsageTypeModel
        ).pipe(take(1));
    }

    public updatePlanType(PlanType: FormData, planTypeId: number): Observable<ResponseModel<PlanTypeModel>> {
        return this.mapRequest<PlanTypeModel>(
            this._http.put(this.url + 'plan/' + planTypeId + '/',
                PlanType,
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            PlanTypeModel
        ).pipe(take(1));
    }

    public deleteActivityType(activityTypeId: number): Observable<ResponseModel<ActivityTypeModel>> {
        return this.mapRequest<ActivityTypeModel>(
            this.http.delete(this.url + 'activity/' + activityTypeId + '/',
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            ActivityTypeModel
            ).pipe(take(1));
    }

    public deleteNoteType(noteTypeId: number): Observable<ResponseModel<NoteTypeModel>> {
        return this.mapRequest<NoteTypeModel>(
            this.http.delete(this.url + 'note/' + noteTypeId + '/',
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            false
            ).pipe(take(1));
    }

    public deleteProductUsageType(productUsageTypeId: number): Observable<ResponseModel<ProductUsageTypeModel>> {
        return this.mapRequest<ProductUsageTypeModel>(
            this.http.delete(this.url + 'product_usage/' + productUsageTypeId + '/',
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            false
            ).pipe(take(1));
    }

    public deletePlanType(planTypeId: number): Observable<ResponseModel<PlanTypeModel>> {
        return this.mapRequest<PlanTypeModel>(
            this.http.delete(this.url + 'plan/' + planTypeId + '/',
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            PlanTypeModel
            ).pipe(take(1));
    }

    public getResourceTypes(params?: {}): Observable<ResponseModel<ResourceTypeModel[]>> {
        return this.mapRequest<ResourceTypeModel[]>(
            this._http.get(this.url + 'resource/',
            {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            false,
            false
        ).pipe(take(1));
    }

    public deleteResourceType(resTypeId: number): Observable<ResponseModel<ResourceTypeModel>> {
        return this.mapRequest<ResourceTypeModel>(
            this.http.delete(this.url + 'resource/' + resTypeId + '/',
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            ).pipe(take(1));
    }

    public addResourceType(resourceType: ResourceTypeInterface): Observable<ResponseModel<ResourceTypeModel>> {
        return this.mapRequest<any>(
            this._http.post(this.url + 'resource/',
                this.camelCaseModelToSnakeCaseJson(resourceType),
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
        ).pipe(take(1));
    }

    public updateResourceType(resourceType: ResourceTypeInterface): Observable<any> {
        return this.mapRequest(
            this._http.put(this.url + 'resource/' + resourceType.id + '/',
                this.camelCaseModelToSnakeCaseJson(resourceType),
                FapAPIRequestOptions.withTokenRequestOptions
            )
        ).pipe(take(1));
    }

    public getDeviceTypes(): Observable<ResponseModel<DeviceTypeModel[]>> {
        return this.mapRequest<DeviceTypeModel[]>(
            this._http.get(this.url + 'device/',
                FapAPIRequestOptions.withTokenRequestOptions),
                DeviceTypeModel,
            true
        ).pipe(take(1));
    }

    public getGenericTypes(): Observable<ResponseModel<BaseTypeModel[]>> {
        return this.mapRequest<BaseTypeModel[]>(
            this._http.get(this.url + 'generic/',
                FapAPIRequestOptions.withTokenRequestOptions),
                BaseTypeModel,
            true
        ).pipe(take(1));
    }

    public getFormTypes(params?:{}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'form/',
            {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            false,
            false
        ).pipe(take(1));
    }

    public createFormType(field?:{}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any>(
            this.http.post(this.url + 'form/',
                this.camelCaseModelToSnakeCaseJson(field),
                FapAPIRequestOptions.withTokenRequestOptions),
            false,
            false
        ).pipe(take(1));
    }

    public patchFormTypes(fieldId: number, field?:{}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any>(
            this.http.patch(this.url + 'form/' + fieldId + '/',
                this.camelCaseModelToSnakeCaseJson(field),
                FapAPIRequestOptions.withTokenRequestOptions),
            false,
            false
        ).pipe(take(1));
    }

    public deleteFormType(formTypeId: number): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this.http.delete(this.url + 'form/' + formTypeId + '/',
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            false
            ).pipe(take(1));
    }

    public getModels(): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'model/',
                FapAPIRequestOptions.withTokenRequestOptions),
              false,
            false
        ).pipe(take(1));
    }

    public getEntityTypes(): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'entity/',
                FapAPIRequestOptions.withTokenRequestOptions),
              false,
            false
        ).pipe(take(1));
    }

    public getFormTypeById(id: number): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this.http.get(this.url + 'form/' + id + '/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
            }),
            false,
            false
        ).pipe(take(1));
    }

    public getWidgetTypes(): Observable<ResponseModel<WidgetTypeModel[]>> {
        return this.mapRequest<WidgetTypeModel[]>(
            this._http.get(this.url + 'widget/',
                FapAPIRequestOptions.withTokenRequestOptions),
                WidgetTypeModel,
            true
        ).pipe(take(1));
    }

    public getSensorGroupTypes(params?:{}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'sensor_group/',
            {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            false,
            false
        ).pipe(take(1));
    }

    public deleteSensorGroupType(sensorGroupId: number): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this.http.delete(this.url + 'sensor_group/' + sensorGroupId + '/',
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            false
            ).pipe(take(1));
    }

    public updateSensorGroupTypes(sensorGroupId: number, sensorGroup?:{}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any>(
            this.http.patch(this.url + 'sensor_group/' + sensorGroupId + '/',
                this.camelCaseModelToSnakeCaseJson(sensorGroup),
                FapAPIRequestOptions.withTokenRequestOptions),
            false,
            false
        ).pipe(take(1));
    }

    public addSensorGroupType(data): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this._http.post(this.url + 'sensor_group/', data,
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
            false
        ).pipe(take(1));
    }

    public getFormFieldTypes(params?:{}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'formfield/',
            {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            false,
            false
        ).pipe(take(1));
    }

    public updateFormFieldType(FormFieldId: number, FormField?:{}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any>(
            this.http.patch(this.url + 'formfield/' + FormFieldId + '/',
                this.camelCaseModelToSnakeCaseJson(FormField),
                FapAPIRequestOptions.withTokenRequestOptions),
            false,
            false
        ).pipe(take(1));
    }

    public addFormFieldType(data): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this._http.post(this.url + 'formfield/', data,
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
            false
        ).pipe(take(1));
    }

    public getFormFieldTypePresence(formFieldTypeId: number): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'formfield/'+formFieldTypeId+'/?check=1',
            {
                headers: FapAPIRequestOptions.withTokenHeaders,
            }),
            false,
            false
        ).pipe(take(1));
    }

    public deleteFormFieldType(formFieldId: number): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this.http.delete(this.url + 'formfield/' + formFieldId + '/',
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            false
            ).pipe(take(1));
    }

    public getWorkflow(params?: {}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this.http.get(this.url + 'workflow/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            false,
            false
        ).pipe(take(1));
    }

    public getWorkflows(params?: {}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this.http.get(this.url + 'workflow/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            false,
            false
        ).pipe(take(1));
    }

      public updateEntityType(entityTypeId: number,entityType: FormData): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this._http.put(this.url + 'entity/' + entityTypeId + '/',
              entityType,
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            false
        ).pipe(take(1));
      }
    
      public addEntityType(entityType: FormData): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this._http.post(this.url + 'entity/',
            entityType,
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            false,
        ).pipe(take(1));
      }
    
      public deleteEntityType(entityTypeId: number): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this.http.delete(this.url + 'entity/' + entityTypeId + '/',
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            false
            ).pipe(take(1));
    }
}
