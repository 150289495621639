<ng-container *ngIf="isLoading">
    <span class="loader">
        <i></i>
    </span>
</ng-container>
<div class="trigger_page" *ngIf="!isLoading">
    <h6>{{ 'Settings' }}</h6>
    <div class="row light_bg">
        <!-- {{entityInfo | json}} -->
        <fap-attributes style="width: 100%;"
            [entityId]="entityInfo?.id"
            [formTypeId]="formTypeId"
            [entityInfo]="entityInfo"
            [showHeader]="false"
            [objectId]="sensorId"
            (formTypeEmit)="formType = $event"
            (formFieldsEmit)="formFields = $event"
        ></fap-attributes>
        <div class="d-flex justify-content-center w-100">
            <button class="btn" (click)="updateForm()">{{ 'Set' }}</button>
        </div>
    </div>
    <div class="schedulers">
        <div class="scheduler_action_bar d-flex justify-content-between align-items-center" [ngClass]="collapsed ? '' : 'has-pad-border'">
            <div class="d-flex align-items-center justify-content-center">{{ 'Automatic run' }} <span class="rounded-btn ml-2" (click)="collapsed = !collapsed"><i [ngClass]="collapsed ? 'fa fa-caret-down' : 'fa fa-caret-up'"></i></span></div>
            <fap-round-button (clicked)="createNewTrigger()" *ngIf="!collapsed"></fap-round-button>
        </div>
        <div class="triggers" *ngIf="!collapsed">
            <ng-container *ngFor="let trigger of triggers">
                <div class="scheduler_row d-flex w-100 align-items-center" *ngIf="trigger">
                    <div class="w-80">
                        <fap-scheduler-row 
                            [trigger]="trigger"
                            [triggerId]="triggerId"
                            [currentFormType]="formType"
                            [scheduler]="getMatchingScheduler(trigger)"
                            [entityInfo]="entityInfo"
                        ></fap-scheduler-row>
                    </div>
                    <div class="w-20">
                        <div class="actions">
                            <action-buttons
                                (editEmit)="addEditTrigger(trigger)" 
                                (deleteEmit)="deleteTrigger(trigger?.id)"
                            ></action-buttons>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<fap-modal #triggerModal [inputTitle]="'scheduler.automatic' | translate" [showFooter]="false" [inputIsLarge]="true">
    <fap-scheduler-automation 
        [trigger]="trigger"
        [triggerId]="triggerId" 
        [schedulers]="schedulers" 
        [currentFormType]="formTypeId"
        (addTrigger)="addTrigger($event)" 
        (updateTrigger)="updateTrigger($event)"
        (emitSchedulers)="schedulers = $event"
        (cancel)="triggerModal.hideModal()"
    ></fap-scheduler-automation>
</fap-modal>