import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { catchError, retry } from 'rxjs/operators';
import { of } from 'rxjs';
import { FapConfig } from '../../config/fap.config';

@Injectable({
  providedIn: 'root',
})
export class GenericWebSocketService {
  private socket$: WebSocketSubject<any>;
  private terseyaConfig: FapConfig;

  constructor() {}

  connect(): void {
    this.terseyaConfig = FapConfig.getInstance();

    // Ensure there is an auth token before connecting
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      console.error('WebSocket: Auth token not found, connection aborted.');
      return;
    }

    this.socket$ = webSocket({
      url: this.terseyaConfig.WS_URL + 'ws/bridge',
      openObserver: {
        next: () => console.log('WebSocket: Connection established'),
      },
      closeObserver: {
        next: () => console.log('WebSocket: Connection closed'),
      },
    });
    console.log(authToken);

    // Add error handling for unexpected socket errors
    // this.socket$.pipe(
    //   retry(3), // Retry connection up to 3 times
    //   catchError((error) => {
    //     console.error('WebSocket: Error occurred', error);
    //     return of(null); // Return an observable to handle errors gracefully
    //   })
    // ).subscribe();
    let message = {id: Math.floor(Date.now() / 1000), type: "TOKEN", method: "REQ", params: {Token: localStorage.getItem('auth_token')}}
    this.sendMessage(message);
  }

  sendMessage(message: any): void {
    if (!message && !this.socket$ || this.socket$.closed) {
      console.error('WebSocket: Unable to send message, connection is closed.');
      return;
    }

    try {
      this.socket$.next(message);
      console.log('WebSocket: Message sent', message);
    } catch (error) {
      console.error('WebSocket: Error while sending message', error);
    }
  }

  onMessage() {
    return this.socket$.asObservable();
  }

  closeConnection(): void {
    if (this.socket$) {
      this.socket$.complete();
      console.log('WebSocket: Connection manually closed');
    }
  }
}
