import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { FapConfig } from '../../config/fap.config';
import { FapAPIRequestOptions } from '../api/fap-api-request.options';
import { CacheResolverService } from '../api/cache/cache-resolver.service';

export interface Message {
    id 
}

@Injectable({
    providedIn: 'root'
})
export class DataService {

    websocket: WebSocket;
    messages: Message;
    private terseyaConfig: FapConfig;

    private triggerEye = new BehaviorSubject(false);
    private eyeData = new BehaviorSubject({});

    getTrigger = this.triggerEye.asObservable();
    getEyeData = this.eyeData.asObservable();

    constructor(private _http: HttpClient, private cacheService: CacheResolverService) {}

    public setTrigger(data) {
        this.triggerEye.next(data);
    }

    public setEyeData(data) {
        this.eyeData.next(data);
    }

    public openWebSocket() {
        this.terseyaConfig = FapConfig.getInstance();
        
        if(localStorage.getItem('auth_token') == null) {
            return
        }
        return
        this.websocket = new WebSocket(this.terseyaConfig.WS_URL + 'ws/bridge');
        this.websocket.onopen = (event) => {
            // console.log('Open: ', event);
            this.sendMsg({Token: localStorage.getItem('auth_token')});
        }

        this.websocket.onmessage = (event) => {
            const msg = JSON.parse(event.data);
            this.messages = msg;
            // console.log(this.messages);
            setTimeout(()=> {
                this.setEyeData(this.messages);
                this.setTrigger(true);
            }, 1000);
        }

        this.websocket.onclose = (event) => {
            // console.log('Close: ', event);
            this.websocket = null;
        }
    }

    public sendMsg(message) {
        this.websocket.send(JSON.stringify(message));
        console.log(message);
    }

    public closeWs() {
        this.websocket.close();
        // this.openWebSocket();
    }

    getUrl(slug) {
        return this.terseyaConfig.API_URL+slug;
    }

    public getLastData(params?: {}): Observable<any> {
        // console.log(this.terseyaConfig.API_URL + '/sensor/last_data/?sensors='+ params);
        this.cacheService.delete(this.terseyaConfig.API_URL + '/sensor/last_data/?sensors='+ params);
        return this._http
            .get(this.terseyaConfig.API_URL + '/sensor/last_data/?sensors='+ params, {
                headers: FapAPIRequestOptions.withTokenHeaders,
            })
            .pipe(take(1));
    }
    
}
