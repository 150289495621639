import {
    Component,
    OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityGroupModel } from '../../../../../core/models/activity/activity-group.model';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { map } from 'rxjs/operators';
import { ActivityService } from '../../../../../core/services/api/activity/activity.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';

@Component({
    templateUrl: './activity-groups-container.component.html',
    styleUrls: ['./activity-groups-container.component.scss']
})
export class ActivityGroupsContainerComponent implements OnInit {

    public activityGroups$: Observable<Array<ActivityGroupModel>>;
    public activities$: Observable<Array<ActivityModel>>;

    constructor(public activityService: ActivityService,
                public toastr: ToastrService,
                public translateService: TranslateService,
                public mapService: MapService) {
    }

    public ngOnInit(): void {
        // this.mapService.hideMap();
        this.initActivityGroups();
    }

    public deleteActivityGroup(activityGroupId: number): void {
        this.activityService.deleteActivityGroup(activityGroupId).subscribe(() => {
            this.initActivityGroups();
            this.toastr.success(this.translateService.instant('activity.activityGroupDeletedSuccessfully'));
        });
    }

    private initActivityGroups(): void {
        this.activities$ = this.activityService.getActivities({
            limit: 1000,
            offset: 0
        }).pipe(map((response: ResponseModel<ActivityModel[]>) => {
            return response.body.results;
        }));
        this.activityGroups$ = this.activityService.getActivityGroups({
            limit: 1000,
            offset: 0
        }).pipe(map((response: ResponseModel<ActivityGroupModel[]>) => {
            return response.model;
        }));
    }
}
