import { TypesService } from './../../../../../core/services/api/types/types.service';
import { UtilsHelper } from './../../../../../core/heplers/utils.helper';
import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { EquipmentService } from '../../../../../core/services/api/equipment/equipment.service';
import {
    ActivatedRoute,
    Params,
    Router
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {
    Subscription,
    combineLatest
} from 'rxjs';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { FapStaticDataService } from '../../../../../core/static-data/fap-static-data.service';
import { NameValueInterface } from '../../../../../core/interfaces/general/name-value.interface';
import { MapPolygonInterface } from '../../../../../shared/layout/fap_main-map/data/map-polygon.interface';
import { MapPointInterface } from '../../../../../shared/layout/fap_main-map/data/map-point.interface';
import {
    take,
    tap
} from 'rxjs/operators';
import { PartyService } from '../../../../../core/services/api/company/party.service';
import { EquipmentInterface } from '../../../../../core/interfaces/equipment/equipment.interface';
import { NavService } from '../../../../../shared/services/nav.service';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { ResourceTypeModel } from '../../../../../core/models/type/resource-type.model';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { Location } from '@angular/common';

@Component({
    templateUrl: './equipment-add-edit-container.component.html'
})
export class EquipmentAddEditContainerComponent implements OnDestroy, OnInit, OnDestroy {

    public isEditMode = false;
    public isPreviewMode = false;
    public farms: Array<FarmModel> = [];
    public farmId: number = null;
    public equipmentId: number = null;
    public equipment: EquipmentModel = null;
    public equipmentPoweredTypes: Array<NameValueInterface> = FapStaticDataService.equipmentPoweredTypesStaticData();
    public parkingPlace: MapPolygonInterface = null;
    public initialParkingPlace: MapPolygonInterface = null;
    public products = [];
    public resourceTypes: Array<ResourceTypeModel> = [];
    private subscriptions: Array<Subscription> = [];
    public getMore = true;
    public nextToken: { offset: number; limit: number; group: string; search: string } = null;
    public limit = 20;

    constructor(public equipmentService: EquipmentService,
                public navService: NavService,
                public activatedRoute: ActivatedRoute,
                public router: Router,
                public toastr: ToastrService,
                public translateService: TranslateService,
                public globalRegistry: GlobalRegistryService,
                public farmService: FarmService,
                public partyService: PartyService,
                public mapService: MapService,
                public typesService: TypesService,
                public stockService: StockService,
                public cacheService: CacheResolverService,
                private location: Location
                ) {
        // this.mapService.mapPolygons = [];
        this.subscriptions.push(combineLatest([activatedRoute.queryParams, this.activatedRoute.params])
            .subscribe(([queryParams, params]: [Params, Params]): void => {
                if(params['equipmentId'] !== undefined) {
                    // we have an equipment ID, this means we try to edit an existing equipment
                    this.equipmentId = Number(params['equipmentId']);
                    this.navService.createMod.next(false);
                    this.navService.editMod.next(true);
                    this.isEditMode = true;
                    this.loadEquipment();
                    this.isPreviewMode = queryParams['preview'] ? true : false;
                    this.mapService.isEditMode = !this.isPreviewMode;
                    this.enableEdit(this.mapService.isEditMode);
                }
                else if (queryParams['farm'] !== undefined) {
                    // we have farm in query params, this means the equipment will have a farm assigned
                    this.farmId = Number(queryParams['farm']);
                    this.navService.createMod.next(true);
                    this.initialParkingPlace = null;
                    this.enableEdit(true);
                    this.loadFarm();
                }
            }));
        this.subscriptions.push(this.mapService.onLastPolyPathChange.subscribe((polygon: MapPolygonInterface): void => {
            if (polygon.data && polygon.data['isFarm']) {
                return;
            }
            this.parkingPlace = polygon;
            this.parkingPlace.strokeColor = '#3366ff';
            this.parkingPlace.fillColor = '#3366ff';
            this.parkingPlace.isEditable = true;
        }));
        this.subscriptions.push(this.mapService.clickedRevertBtn.subscribe((): void => {
            this.mapService.clearEditablePolygons();
            if(this.initialParkingPlace) {
                this.parkingPlace = UtilsHelper.getDeepCopy(this.initialParkingPlace);
                this.mapService.mapPolygons.push(this.parkingPlace);
            } else {
                this.parkingPlace = null;
            }
        }));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
        this.mapService.isEditMode = true;
        this.mapService.mapMenuOptions = [];
        this.mapService.mapPolygons = [];
        this.mapService.resetMap();
        this.navService.editFarm.next(false);
    }

    public getProducts() {
        const url = this.stockService.getUrl('products/');
        this.subscriptions.push(this.stockService.getProducts({ limit: this.limit, group: 'EQUIPMENT' }).subscribe(data => {
            this.cacheService.delete(url+'limit='+this.limit+'&group=EQUIPMENT');
            this.products = data.body.results;
            this.nextToken = data.body.next
                  ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                  : null;
                  if(this.nextToken) this.getMore = true
          }))
    }

    onScroll(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          console.log("End");
          this.scrolledDown();
        }
    }
    
      paramsToObject(entries) {
        const result = {}
        for(const [key, value] of entries) {
          result[key] = value;
        }
        console.log(result);
        return result;
      }
    
      scrolledDown() {
        const url = this.stockService.getUrl('products/');
        console.log('scrolled');
        if(this.getMore) {
        this.nextToken &&
          this.subscriptions.push(this.stockService.getProducts(this.nextToken).subscribe((data) => {
            this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search+'&group=EQUIPMENT');
            console.log(data);
            if(this.products) {
              this.products = [...this.products, ...data.body.results];
            } else {
              this.products = data.body.results;
            }
            if(data.body.next == null) {
              this.getMore = false;
              return
            } else {
              const url = data.body.next.split('?')
              const urlParams = new URLSearchParams(url[1]);
              const entries = urlParams.entries();
              const params = this.paramsToObject(entries);
              console.log(params);
              if(this.nextToken.offset != params['offset']) {
              this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search'], group: 'EQUIPMENT'};
              } else {
                return
              }
            }
          }));
        } else {
          return
        }
      }

      filterProduct(value) {
        const url = this.stockService.getUrl('products/');
        this.subscriptions.push(this.stockService.getProducts({group: 'EQUIPMENT', 'search': value}).subscribe(data => {
            if(this.nextToken) {
                this.cacheService.delete(url+'limit='+this.nextToken.limit+'&group=EQUIPMENT');
            }
            console.log(data);
            this.nextToken = data.body.next
                  ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                  : null;
            this.products = data.body.results;
            if(this.nextToken) this.getMore = true;
          }))
      }

    public getResourceTypes() {
        this.typesService.getResourceTypes({group: 'EQUIPMENT'}).subscribe(data => {
            this.resourceTypes = data.model
            console.log(this.resourceTypes);
        })
    }

    public ngOnInit(): void{
        this.navService.editFarm.next(true);
        // this.getProducts();
        // this.getResourceTypes();
        this.navService.emitCancel.subscribe((value: boolean) => {
            if (value) {
                this.location.back();
                this.navService.emitCancel.next(false);
            }
        });
    }

    public submitEquipment(equipmentObject: {}): void {
        const equipment: EquipmentInterface = equipmentObject['equipment'];
        if(this.parkingPlace == null) {
            this.toastr.error(this.translateService.instant('equipment.pleaseDrawEquipmentParkingPlace'));
        }
        equipment.garageArea = {
            type: 'Polygon',
            coordinates: [MapHelper.convertToAPIPolygon(this.parkingPlace.points)]
        };

        if (this.farmId !== null && this.farmId !== undefined) {
            equipment.farm = this.farmId;
        }
        if (this.isEditMode) {
            this.equipmentService.updateEquipment(this.equipmentId, equipment).pipe(take(1)).subscribe((): void => {
                // this.equipmentService.updateImage(picture, this.equipmentId).subscribe();
                this.globalRegistry.addLocalRelationsToService.next(this.equipmentId);
                this.toastr.success(this.translateService.instant('equipment.equipmentUpdatedSuccessfully'));
                this.router.navigate(['/pages/equipments']);
            });
        } else {
            this.equipmentService.createEquipment(equipment).pipe(take(1)).subscribe((response:ResponseModel<EquipmentModel>): void => {
                const {id} = response.model;
                this.equipmentId = id;
                this.globalRegistry.addLocalRelationsToService.next(id);
                // this.equipmentService.updateImage(picture, id).subscribe();
                this.toastr.success(this.translateService.instant('equipment.equipmentCreatedSuccessfully'));
                this.router.navigate(['/pages/equipments']);
            });
        }
    }

    public deleteEquipment(id): void {
        console.log(id);
        this.equipmentService
          .deleteEquipment(Number(id))
          .pipe(
            tap(
              () => {
                this.globalRegistry.addLocalRelationsToService.next(this.equipmentId);
                this.toastr.success(this.translateService.instant('equipment.equipmentDeletedSuccessfully'));
              },
              () =>
                this.toastr.error(
                  this.translateService.instant("Failed to delete equipment")
                )
            )
          )
          .subscribe();
      }

    public enableEdit(enabled: boolean): void {
        this.mapService.isEditMode = enabled;
        this.mapService.mapPolylines = [];
        const mapServiceContext: MapService = this.mapService;
        const equipmentContainerContext: EquipmentAddEditContainerComponent = this;
        this.mapService.mapMenuOptions = [
            {
                header: this.translateService.instant('equipment.editEquipmentPage.addParkingSpace')
            },
            {
                text: this.translateService.instant('map.actions.drawPolygon'),
                clickFunction(): void {
                    if (mapServiceContext.mapPolygons.length === 1 ) {
                        mapServiceContext.drawNewPolygon();
                    }
                }
            },
            {
                text: this.translateService.instant('map.actions.clearPolygons'),
                clickFunction(): void {
                    mapServiceContext.mapPolygons = mapServiceContext.mapPolygons
                        .filter((polygon: MapPolygonInterface): boolean => polygon.data && polygon.data['isFarm']);
                    equipmentContainerContext.parkingPlace = null;
                }
            },
            {
                text: this.translateService.instant('revertChanges'),
                clickFunction(): void {
                    mapServiceContext.clickedRevertBtn.emit();
                }
            }
        ];
        this.mapService.mapHasContextMenu = true;
    }

    public deleteParty(partyId: number): void {
        this.partyService.deleteParty(partyId).pipe(tap(
            (): void => {
                this.globalRegistry.reloadParties();
                this.toastr.success(this.translateService.instant('people.party.partyDeletedSuccessfully'));
            }, (): void => {
                this.toastr.error(this.translateService.instant('people.party.errorDeletingParty'));
            }),
            take(1)).subscribe();
    }

    public deleteEquipmentType(equipmentTypeId: number): void {
        this.typesService.deleteEquipmentType(equipmentTypeId).subscribe(
            (): void => {
                this.globalRegistry.reloadEquipmentTypes();
                this.toastr.success(this.translateService.instant('equipment.equipmentTypeDeletedSuccessfully'));
            }, (): void => {
                this.toastr.error(this.translateService.instant('people.party.errorDeletingEquipmentType'));
            }
        );
    }

    // currently only dummy data
    private loadEquipmentTracking(): void {
        if(this.parkingPlace && !(this.isEditMode && !this.isPreviewMode)){
            const startingPointLat: number = this.parkingPlace.points[0].lat;
            const startingPointLng: number = this.parkingPlace.points[0].lng;

            const deltaCoords: Array<MapPointInterface> = [{ lat: 0, lng: 0 },
            { lat: 0.0020055303803943048, lng: 0.0048065185546875 },
            { lat: 0.0032177936212391955, lng: 0.007853507995605469 },
            { lat: 0.005004337242702661, lng: 0.006077885627746582 },
            { lat: 0.00660095370864866, lng: 0.004374682903289795 },
            { lat: 0.005625755719876224, lng: 0.001812167465686798 },
            { lat: 0.0063397353707088655, lng: 0.0011500809341669083 },
            { lat: 0.0069938457644411756, lng: 0.0006596557796001434 },
            { lat: 0.00779977439405144, lng: 0.002474379725754261 },
            { lat: 0.008545832925847208, lng: 0.001800013706088066 },
            { lat: 0.00782317153517198, lng: 0.00002212822437286377 },
            { lat: 0.007369314933775684, lng: -0.0008113682270050049 },
            { lat: 0.005084777127244422, lng: 0.0011265277862548828 },
            { lat: 0.003650872016635276, lng: 0.0025776028633117676 },
            { lat: 0.0022767952769839894, lng: 0.00359952449798584 },
            { lat: 0.0007979178807744347, lng: 0.00011533498764038086 },
            { lat: 0.0014608919616989624, lng: -0.0007020682096481323 },
            { lat: 0.0028123057249942462, lng: 0.0025574862957000732 },
            { lat: 0.003400425903301141, lng: 0.001976117491722107 },
            { lat: 0.0021781549777628584, lng: -0.0008684908971190453 },
            { lat: 0.00290148876133145, lng: -0.0014385860413312912 },
            { lat: 0.004108675718548227, lng: -0.002578776329755783 },
            { lat: 0.0037693132275080643, lng: -0.003657527267932892 },
            { lat: 0.002432070451526158, lng: -0.002295970916748047 }];
            this.mapService.mapPolylines = [{
                points: deltaCoords.map((point: MapPointInterface): MapPointInterface => ({
                    lat: point.lat + startingPointLat,
                    lng: point.lng + startingPointLng
                })),
                color: '#ff0000'
            }];
        }
    }

    private loadEquipment(): void {
        this.mapService.resetMap();
        this.equipmentService.getEquipment(this.equipmentId).pipe(take(1))
        .subscribe((equipment: ResponseModel<EquipmentModel>): void => {
            this.equipment = equipment.model;
            if (equipment.model.garageArea) {
                this.parkingPlace = {
                    points: MapHelper.convertToAGMPolygon(equipment.model.garageArea['coordinates'][0]),
                    strokeColor: '#3366ff',
                    fillColor: '#3366ff',
                    isEditable: true
                };
                this.mapService.mapPolygons.push(this.parkingPlace);
            }
            this.initialParkingPlace = UtilsHelper.getDeepCopy(this.parkingPlace);
            this.farmId = equipment.model.farm;
            this.loadFarm();
        });
    }

    private loadFarm(): void {
        this.farmService.getFarm(this.farmId).pipe(take(1)).subscribe((farm: ResponseModel<FarmModel>): void => {
            this.mapService.mapPolygons.push({
                points: MapHelper.convertToAGMPolygon(farm.model.coords.coordinates[0]),
                strokeColor: '#a31f1f',
                fillColor: '#de3333',
                isEditable: false,
                data: { isFarm: true }
            });
            this.mapService.centerMapOnPolygons();
            this.mapService.showMap();
        });
    }

}
