import { TranslateService } from '@ngx-translate/core';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';
import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { Router } from '@angular/router';

@Component({
    selector: 'fap-crops-layout',
    templateUrl: './fap_crops-layout.component.html',
    styleUrls: ['./fap_crops-layout.component.scss']
})
export class FapCropsLayoutComponent {

    @Input()
    public farms: Array<FarmModel> = [];

    @Input()
    public lots: Array<LotModel> = [];

    @Input()
    public key: string;

    @Input()
    public farmForecastMapping: { [key: number]: WeatherModel } = {};

    @Output()
    public clickedLot: EventEmitter<LotModel> = new EventEmitter();

    @Output()
    public deleteLot: EventEmitter<number> = new EventEmitter();

    constructor(private confirmModalService: ConfirmModalService,
                private router: Router,
                private mapService: MapService,
                private translateService: TranslateService) {
                    this.mapService.centerMapOnPolygons();
                    this.mapService.mapZoom.next(16);
    }

    public onDeleteLot(lotId: number): void {
        this.confirmModalService.openConfirmModal(
            this.translateService.instant('confirmDelete'),
            this.translateService.instant('farm.lot.deleteLotConfirmMessage')
        );
        this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
            if (confirmed) {
                this.deleteLot.emit(lotId);
            }
        });
    }

    hasLots(farmId: number): boolean {
        return this.lots.some(lot => lot.farm === farmId);
    }

    public getLotImage(lot: LotModel): string {
        const previewImage: string = lot.staticMapImage;        
        return previewImage;
    }

    public editFarm(farmId: number) {
        this.router.navigate(['/pages/farm/edit/' + farmId]);
    }

    public newLot(farmId: number) {
        this.router.navigate(['/pages/lot/add/'], { queryParams: { farm: farmId } });
    }

}
