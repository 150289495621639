import { NoteModel } from "./../../../../../core/models/notes/note.model";
import { NoteTypeModel } from "./../../../../../core/models/type/note-type.model";
import { TypesService } from "./../../../../../core/services/api/types/types.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NotesService } from "./../../../../../core/services/api/farm/notes.service";
import { LotModel } from "./../../../../../core/models/lot/lot.model";
import { LotService } from "./../../../../../core/services/api/farm/lot.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { tap } from "rxjs/operators";
import { ResponseModel } from "../../../../../core/models/response.model";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Subscription } from "rxjs";
import { NavService } from "../../../../../shared/services/nav.service";
import { GlobalRegistryService } from "../../../../../core/global-registry/global-registry.service";
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { FarmModel } from "../../../../../core/models/farm/farm.model";
import { CacheResolverService } from "../../../../../core/services/api/cache/cache-resolver.service";
import { PlanTypeModel } from "../../../../../core/models/company/plan.type.model";

@Component({
  templateUrl: "./notes-edit-container.component.html",
})
export class NotesEditContainerComponent implements OnInit, OnDestroy {
  public lots: Array<LotModel>;
  public types: Array<NoteTypeModel>;
  public farms: Array<FarmModel>;
  public notes: Array<NoteModel>;
  public noteId: number;
  public note: NoteModel | any;
  public farmId: number;
  public subscriptions = new Subscription();
  public limit: number = 20;
  public nextNoteToken: { limit: number; offset: number; search: string } = null;
  public getMore = true;
  public planTypes: Array<PlanTypeModel>;

  constructor(
    public lotService: LotService,
    public noteTypeService: TypesService,
    public noteService: NotesService,
    public activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public navService: NavService,
    public router: Router,
    public globalRegistry: GlobalRegistryService,
    private toastyService: ToastrService,
    private mapService:MapService,
    private cacheService: CacheResolverService,
    private toastr: ToastrService,
    private typeService: TypesService
  ) {
    this.getTypes();
    this.subscriptions.add(
      combineLatest([
        activatedRoute.params,
      ]).subscribe(([params]: [Params]) => {
        this.lots = this.globalRegistry.systemData.lots;
        this.farms = this.globalRegistry.systemData.farms;
        this.navService.createMod.next(true);
        if (params["noteId"] !== undefined) {
          this.noteId = Number(params["noteId"]);
          this.navService.createMod.next(false);
          this.navService.editMod.next(true);
          this.getNote(this.noteId);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.navService.editFarm.next(false);
  }

  ngOnInit() {
    if (window.innerWidth >= 767) {
      this.mapService.showMap();
  }
  this.typeService.getPlanTypes().subscribe((types:ResponseModel<PlanTypeModel[]>) => {
    this.planTypes = types.model;
  })
  this.mapService.resetMap();
    this.navService.editFarm.next(true);
    this.navService.submitFarm.next(true);
    this.navService.invalidForm.next(true);
    this.subscriptions.add(
      this.noteService.getNotesList.subscribe(() => {
        this.getTypes();
      })
    );

    this.subscriptions.add(
      this.navService.emitCancel.subscribe((value: boolean) => {
        if (value) {
          this.router.navigate(["/pages/notes"]);
          this.navService.emitCancel.next(false);
          this.noteService
        }
      })
    );
    this.getNotes();
  }

  public submitNote(note: FormData): void {
    this.noteService.createNote(note).subscribe(
      () => {
        // this.noteService.getNotesList.next();
        this.globalRegistry.addLocalRelationsToService.next(this.noteId);
        this.toastyService.success(
          this.translate.instant("notes.messages.createSuccess")
        );
        // this.getNotes();
      },
      () => {
        this.toastyService.error(
          this.translate.instant("notes.messages.failCreate")
        );
      }
    );
  }

  scrolledDownNotes() {
    const url = this.noteService.getUrl('');
    if(this.getMore) {
    this.nextNoteToken &&
      this.noteService.getNotes(this.nextNoteToken).subscribe((data) => {
        this.cacheService.delete(url+'limit='+this.nextNoteToken.limit+'&offset='+this.nextNoteToken.offset+'&search='+this.nextNoteToken.search);
        console.log(data);
        if(this.notes) {
          this.notes = [...this.notes, ...data["results"]];
        } else {
          this.notes = data["results"];
        }
        if(data["next"] == null) {
          this.getMore = false;
          return
        } else {
          const url = data['next'].split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          if(this.nextNoteToken.offset != params['offset']) {
          this.nextNoteToken = {limit: params['limit'], offset: params['offset'], search : params['search']};
          } else {
            return
          }
        }
      });
    } else {
      return
    }
  }

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

  public updateNote(note): void {
    console.log(note);
    this.noteService
      .updateNote(Number(note.id), note)
      .pipe(
        tap(
          () => {
            // this.getNotes();
            // this.noteService.getNotesList.next();
            this.globalRegistry.addLocalRelationsToService.next(this.noteId);
            this.toastyService.success(
              this.translate.instant("notes.messages.updateSuccess")
            );
          },
          () =>
            this.toastyService.error(
              this.translate.instant("notes.messages.failUpdate")
            )
        )
      )
      .subscribe();
  }

  public deleteNote(id): void {
    console.log(id);
    this.noteService
      .deleteNote(Number(id))
      .pipe(
        tap(
          () => {
            // this.getNotes();
            // this.noteService.getNotesList.next();
            this.globalRegistry.addLocalRelationsToService.next(this.noteId);
            this.toastyService.success(this.translate.instant("notes.messages.deleteSuccess"));
          },
          () =>
            this.toastyService.error(
              this.translate.instant("notes.messages.failUpdate")
            )
        )
      )
      .subscribe();
  }

  // private getNotes(): void {
  //     this.noteService.getNotes().subscribe((response: ResponseModel<NoteModel[]>) => {
  //         this.notes = response.model;
  //     });
  // }

  filterNotes(value) {
    const url = this.noteService.getUrl('notes/');
    const params = { search: value }
    this.subscriptions.add(this.noteService.getNotes(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextNoteToken = data['next']
            ? this.globalRegistry.getQueryStringParams(data['next'].split("?")[1])
            : null;
      this.notes = data['results'];
      if(this.nextNoteToken) this.getMore = true
    }))
}

  public getNotes(): void {
  const subscription = this.noteService.getNotes({ limit: this.limit }).subscribe(
    (notes: ResponseModel<NoteModel[]>) => {
      this.notes = notes['results'];
      this.nextNoteToken = notes['next'] ? this.globalRegistry.getQueryStringParams(notes['next'].split('?')[1]) : null;
      const urlWithLimit = this.noteService.getUrl('notes/') + 'limit=' + this.limit;
      this.cacheService.delete(urlWithLimit);
    },
    (error) => {
      console.error('Error fetching notes:', error);
      this.toastr.error(error.error.results.error);
    }
  );

  this.subscriptions.add(subscription);
}

  private getTypes(): void {
    this.noteTypeService
      .getNoteTypes()
      .subscribe((response: ResponseModel<NoteTypeModel[]>) => {
        this.types = response.model;
      });
  }

  convertKeysToCamelCase(obj: any): any {
    const camelCaseObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
        camelCaseObj[camelCaseKey] =
          obj[key] instanceof Object && !Array.isArray(obj[key])
            ? this.convertKeysToCamelCase(obj[key]) // Recursively convert nested objects
            : obj[key]; // Keep primitive values or arrays as-is
      }
    }
    return camelCaseObj;
  }
  

  private getNote(id: number): void {
    this.noteService
      .getNote(id)
      .subscribe((response: ResponseModel<NoteModel>) => {
        this.note = this.convertKeysToCamelCase(response.body.results);
        console.log(this.note);
      });
  }
}
